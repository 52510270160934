import * as c from '../constants/groups';
import { RESET_ALL_STORE } from '../constants/user';

const initialState = {
  publicGroupId: null /* 一般グループのID */,
  postGroups: null /* 投稿可能なグループオブジェクトの配列 */,
  deleteGroupIds: null /* 削除可能なグループID文字列の配列 */,
  menuGroups: null /* メニューに表示するグループオブジェクト配列 */,
  groupData: null /* 参加リクエスト画面に表示するグループ情報 */,
  notifyGeneral: null /* 一般参加通知オブジェクト */,
  isRequestingJoinGroup: false /* グループ参加リクエスト処理中フラグ */,

  /* グループ管理者向け */

  groupUsers: null /* グループ参加者一覧オブジェクト配列 */,
  groupUsersCount: 0 /* グループ参加者一覧の合計数 */,
  requestGetGroupUsers: false /* グループ参加者一覧取得中フラグ */,
  isDeletingGroupUser: false /* グループ参加者削除中フラグ */,
  groupRequests: null /* グループ申請者一覧オブジェクト配列 */,
  groupRequestsCount: 0 /* グループ申請者一覧の合計数 */,
  requestGetGroupRequests: false /* グループ申請者一覧取得中フラグ */,
  isConfirmingGroupRequest: false /* グループ申請者確認登録中フラグ */,
  error: null /* エラーメッセージ文字列 */,
};

export default function groups(state = initialState, action) {
  switch (action.type) {
    case c.GET_GROUPS:
      return {
        ...state,
        publicGroupId: action.payload.publicGroupId,
        postGroups: action.payload.postGroups,
        deleteGroupIds: action.payload.deleteGroupIds,
        menuGroups: action.payload.menuGroups,
      };

    case c.GET_GROUP_DATA:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
        };
      }
      return {
        ...state,
        groupData: action.payload.groupData,
        error: null,
      };

    // グループ参加申請登録の開始
    case c.REQUEST_POST_JOIN_GROUP:
      return {
        ...state,
        isRequestingJoinGroup: true,
      };

    // グループ参加申請登録の完了
    case c.POST_JOIN_GROUP:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
          isRequestingJoinGroup: false,
        };
      }
      return {
        ...state,
        isRequestingJoinGroup: false,
      };

    // [管理者]グループユーザー一覧
    case c.REQUEST_GET_GROUP_USERS:
      return {
        ...state,
        groupUsers: null,
        groupUsersCount: 0,
        requestGetGroupUsers: true,
      };

    case c.REQUEST_GET_GROUP_USERS_WITH_CURSOR:
      return {
        ...state,
        requestGetGroupUsers: true,
      };

    case c.GET_GROUP_USERS:
    case c.GET_GROUP_USERS_WITH_CURSOR:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
          requestGetGroupUsers: false,
        };
      }
      return {
        ...state,
        groupUsers: [...(state.groupUsers || []), ...action.payload.groupUsers],
        groupUsersCount: action.payload.groupUsersCount,
        requestGetGroupUsers: false,
      };

    // [管理者]グループユーザーの削除中
    case c.REQUEST_DELETE_GROUP_USER:
      return {
        ...state,
        isDeletingGroupUser: true,
      };

    // [管理者]グループユーザーの削除完了
    case c.DELETE_GROUP_USER:
      return {
        ...state,
        isDeletingGroupUser: false,
      };

    // [管理者]グループ申請一覧
    case c.REQUEST_GET_GROUP_REQUESTS:
      return {
        ...state,
        groupRequests: null,
        groupRequestsCount: 0,
        requestGetGroupRequests: true,
      };

    case c.REQUEST_GET_GROUP_REQUESTS_WITH_CURSOR:
      return {
        ...state,
        requestGetGroupRequests: true,
      };

    case c.GET_GROUP_REQUESTS:
    case c.GET_GROUP_REQUESTS_WITH_CURSOR:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
          requestGetGroupRequests: false,
        };
      }
      return {
        ...state,
        groupRequests: [
          ...(state.groupRequests || []),
          ...action.payload.groupRequests,
        ],
        groupRequestsCount: action.payload.groupRequestsCount,
        requestGetGroupRequests: false,
      };

    // [管理者]グループ申請確認のバリデーション
    case c.VALIDATE_CONFIRM_GROUP_REQUEST:
      if (action.payload.errors.length > 0) {
        return {
          ...state,
          groupRequests: addErrorRequests(state.groupRequests, action.payload),
        };
      }
      return {
        ...state,
      };

    // [管理者]グループ申請確認のリクエスト中
    case c.REQUEST_CONFIRM_GROUP_REQUEST:
      return {
        ...state,
        isConfirmingGroupRequest: true,
      };

    // [管理者]グループ申請確認
    case c.CONFIRM_GROUP_REQUEST:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
          isConfirmingGroupRequest: false,
        };
      }
      return {
        ...state,
        isConfirmingGroupRequest: false,
      };

    // グループ通知オブジェクトの設定
    case c.SET_NOTIFY_GENERAL:
      return {
        ...state,
        notifyGeneral: action.payload.notifyGeneral,
      };

    // [管理者]グループ通知オブジェクト配列の設定
    case c.SET_NOTIFIES_MANAGER:
      return {
        ...state,
        notifiesManager: action.payload.notifiesManager,
      };

    // グループ通知のリセット
    case c.RESET_NOTIFIES:
      return {
        ...state,
        notifyGeneral: null,
        notifiesManager: null,
      };

    case c.RESET_GROUPS:
    case RESET_ALL_STORE:
      return initialState;

    default:
      return state;
  }
}

/**
 * 申請にエラーを追加
 * @param {array} requests - 申請オブジェクト配列
 * @param {object} payload - ペイロードオブジェクト
 * @returns {*[]|*}
 */
const addErrorRequests = (requests, payload) => {
  if (requests === null || requests.length === 0) {
    return requests;
  }
  const update = requests.map((request) => {
    return request.requestId === payload.requestId
      ? {
          ...request,
          errors: payload.errors || [],
        }
      : request;
  });

  return [...update];
};

import firebase from 'firebase/app';
import 'firebase/auth';

if (!firebase.apps.length) {
  let credential = null;
  if (process.env.REACT_APP_SCHEMA_ENV === 'release') {
    credential = require('./credentials/release');
  } else if (process.env.REACT_APP_SCHEMA_ENV === 'adhoc') {
    credential = require('./credentials/adhoc');
  } else {
    credential = require('./credentials/debug');
  }
  credential && firebase.initializeApp(credential);
}
const schema = process.env.REACT_APP_SCHEMA_ENV;

export { firebase, schema };

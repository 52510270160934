import * as c from '../constants/user';

export function requestGetUser() {
  return {
    type: c.REQUEST_GET_USER,
  };
}

export function failureGetUser(message) {
  return {
    type: c.GET_USER,
    payload: {
      message,
    },
    error: true,
  };
}

export function successGetUser(user) {
  return {
    type: c.GET_USER,
    payload: {
      user,
    },
  };
}

/**
 * 編集フォームの入力項目をStoreに保存する
 *
 * @param {string} key - 保存したいプロパティ名
 * @param {string} value - 保存したい値
 * @return {object} Actionオブジェクト
 */
export function inputEditForm(key, value) {
  return {
    type: c.INPUT_EDIT_FORM,
    payload: {
      key,
      value,
    },
  };
}

/**
 * ファイルを入力する
 *
 * @param {array} files - ファイル配列
 */
export function inputFiles(files) {
  return {
    type: c.INPUT_FILES,
    payload: {
      files,
    },
  };
}

/**
 * ユーザー編集リクエストをReducerに通知する
 *
 * @return {object} Actionオブジェクト
 */
export function requestPutUser() {
  return {
    type: c.REQUEST_PUT_USER,
  };
}

/**
 * ユーザー編集の失敗をReducerに通知する
 *
 * @param {object} error エラーオブジェクト
 * @return {object} Actionオブジェクト
 */
export function failurePutUser(error) {
  return {
    type: c.PUT_USER,
    payload: {
      error,
    },
    error: true,
  };
}

/**
 * ユーザー編集の成功をReducerに通知する
 *
 * @return {object} Actionオブジェクト
 */
export function successPutUser() {
  return {
    type: c.PUT_USER,
  };
}

/**
 * ユーザー編集のバリデーションエラーをReducerに通知する
 * @param errors
 */
export function validateErrorPutUer(errors) {
  return {
    type: c.PUT_USER,
    payload: {
      errors,
    },
    error: true,
  };
}

/**
 * Storeのユーザー情報をリセットする
 *
 * @return {object} Actionオブジェクト
 */
export function resetUser() {
  return {
    type: c.RESET_USER,
  };
}

/**
 * ユーザーのフォローを行う
 *
 * @return {object} Actionオブジェクト
 */
export function followUser() {
  return {
    type: c.FOLLOW_USER,
  };
}

/**
 * ユーザーのフォロー解除を行う
 *
 * @return {object} Actionオブジェクト
 */
export function unfollowUser() {
  return {
    type: c.UNFOLLOW_USER,
  };
}

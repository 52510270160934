import * as React from 'react';
import styles from './signin.module.css';
import { getMessage } from '../../utils/feedback';
import { firebase } from '../../firebase';
import { sendAnalyticsEvent } from '../../utils/analytics';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

/**
 * ログインコンポーネント
 */
class Signin extends React.Component {
  state = {
    isSigning: false,
    email: '',
    password: '',
    errorMessage: '',
  };

  componentDidMount() {
    sendAnalyticsEvent('auth', 'show_screen', 'signin');
    this.displaySocialLoginError();
    if (this.props.location?.search) {
      const queryParams = new URLSearchParams(this.props.location.search);
      this.setState({
        email: queryParams.get('mailAddr') ?? undefined,
      });
    }
  }

  componentDidUpdate() {
    this.displaySocialLoginError();
  }

  displaySocialLoginError() {
    if (this.props.auth && this.props.auth.socialLoginError) {
      this.setState({
        errorMessage: getMessage(this.props.auth.socialLoginError.code),
      });
      this.props.setSocialLoginError(null);
    }
  }

  login = () => {
    if (this.state.isSigning) return;

    const { email, password } = this.state;
    this.setState({ isSigning: true });
    sendAnalyticsEvent('auth', 'login', 'email');
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(async (res) => {
        // ID基盤ログイン
        await this.props.idLogin();

        this.setState({ isSigning: false });
      })
      .catch((error) => {
        this.setState({
          errorMessage: getMessage(error.code),
          isSigning: false,
        });
      });
  };

  // Facebookでログイン
  // 新規登録されていない場合は新規登録がされる
  signinWithFacebook() {
    const provider = new firebase.auth.FacebookAuthProvider();
    this.setState({ isSigning: true });
    sendAnalyticsEvent('auth', 'login', 'facebook');
    this.signinWithSocialProvider(provider);
  }

  // Appleでログイン
  // 新規登録されていない場合は新規登録がされる
  signinWithApple() {
    const provider = new firebase.auth.OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    provider.setCustomParameters({
      locale: 'ja_JP',
    });
    sendAnalyticsEvent('auth', 'login', 'apple');
    this.signinWithSocialProvider(provider);
  }

  /**
   * SNSプロバイダーでログイン
   *
   * 新規登録されていない場合は新規登録がされる。
   *
   * @param {firebase.auth.OAuthProvider} provider
   * @todo popupできない場合にsignInWithRedirectにfallbackする
   */
  async signinWithSocialProvider(provider) {
    this.setState({ isSigning: true });

    // 2023.3.2 暫定無効化。popupログインに切り替えた。
    // firebase.auth().signInWithRedirect(provider);

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (result) => {
        console.log('firebase.auth().signInWithPopup result', result);
        if (result.credential && result.additionalUserInfo.isNewUser) {
          // 仮ユーザー作成
          await this.props.setPreRegister();
          console.log('setPreRegister called');
        }
        // ID基盤ログイン
        await this.props.idLogin();
      })
      .catch((e) => {
        console.error('signinWithSocialProvider error', e);
        this.props.setSocialLoginError(e);
      })
      .finally(() => {
        this.setState({ isSigning: false });
      });
  }

  render() {
    const { isSigning } = this.state;
    const idBaseHost = process.env.REACT_APP_ID_BASE_HOST;
    const signup_url = `${idBaseHost}/signup?from_service=qa`; // アカウント登録は事前のID基盤ログインが不要なためproceedIdPageを使わない
    // お問い合わせ用googleフォーム
    const CONTACT_URL =
      'https://docs.google.com/forms/d/1Ei--dwswtZjKMTytAR3Zf9Cn3YYdVUL418Q933VXI-c/viewform';

    const onClickChangePassword = () => {
      this.props.proceedIdPage('/users/password/reset');
    };

    return (
      <div className="Body">
        <div className={styles.logoPanel}>
          <a
            className={styles.appStoreLink}
            target="_blank"
            rel="noopener noreferrer"
            href="https://apps.apple.com/jp/app/id1151798574"
          >
            <img
              src={'/images/store_ios.svg'}
              alt="AppStoreからダウンロード"
              width="95"
              height="35"
            />
          </a>
          <a
            className={styles.playStoreLink}
            target="_blank"
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=jp.antaa.qa.android.AntaaQa"
          >
            <img
              src={'/images/store_android.png'}
              alt="GooglePlayで手に入れよう"
              width="95"
              height="35"
            />
          </a>
        </div>
        <div className={styles.panel}>
          <div className={styles.helpText}>
            <p>
              ※アカウント統合をされた方は、アカウント統合時に作成/使用したメールアドレスでログインしてください。
              <br />
              メールアドレスがわからない方は、
              <a
                href={`${CONTACT_URL}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                こちら
              </a>
              よりお問い合わせください。
            </p>
          </div>
          <div className={styles.subArea}>
            <p className={styles.hint}>アカウントをお持ちでない方はこちら</p>
            <p className={styles.text}>医師/医学生限定</p>
            <a className={styles.link} href={`${signup_url}`}>
              <button
                className={styles.antaaSignUpBtn}
                onClick={() => sendAnalyticsEvent('auth', 'show_signup', null)}
              >
                新規会員登録
              </button>
            </a>
          </div>
          <button
            className={styles.facebookBtn}
            onClick={() => this.signinWithFacebook()}
            disabled={isSigning}
          >
            Facebookでログイン
          </button>
          <button
            className={styles.appleBtn}
            onClick={() => this.signinWithApple()}
            disabled={isSigning}
          >
            Appleでログイン
          </button>
          <form>
            <p className={styles.legend}>メールアドレス</p>
            <input
              type="text"
              className={styles.inputText}
              autoComplete="email"
              value={this.state.email}
              onKeyPress={(e) => e.key === 'Enter' && this.login()}
              onChange={(e) => this.setState({ email: e.target.value })}
            />
            <p className={styles.legend}>パスワード</p>
            <input
              type="password"
              className={styles.inputText}
              autoComplete="password"
              onKeyPress={(e) => e.key === 'Enter' && this.login()}
              onChange={(e) => this.setState({ password: e.target.value })}
            />
            <div className={styles.action}>
              {this.renderErrorMessage()}
              <button
                type="button"
                className={styles.antaaSignInBtn}
                onClick={this.login}
                disabled={isSigning}
              >
                メールアドレスでログイン
              </button>
              <div className={styles.forget} onClick={onClickChangePassword}>
                パスワードをお忘れの方はこちら
              </div>
            </div>
            <li className={styles.faq}>
              <a
                href="https://note.com/antaa/n/ne7676bee6c70"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  sendAnalyticsEvent('link_to', 'note', 'faq_page')
                }
              >
                ログインに関するFAQ
                <FontAwesomeIcon
                  className={styles.faqIcon}
                  size="sm"
                  icon={faExternalLinkAlt}
                />
              </a>
            </li>
          </form>
        </div>
      </div>
    );
  }

  renderErrorMessage() {
    const { errorMessage } = this.state;
    if (!errorMessage) {
      return null;
    }

    const lines = errorMessage.split('\n');
    return (
      <p className={styles.errorText}>
        {lines.map((line, i) => (
          <React.Fragment key={i}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </p>
    );
  }
}

export default Signin;

export const SET_UID = 'SET_UID';
export const SET_DISCLOSE_ID = 'SET_DISCLOSE_ID';
export const REQUEST_GET_USER = 'REQUEST_GET_USER';
export const GET_USER = 'GET_USER';
export const INPUT_EDIT_FORM = 'INPUT_EDIT_FORM';
export const INPUT_FILES = 'INPUT_FILES';
export const REQUEST_PUT_USER = 'REQUEST_PUT_USER';
export const PUT_USER = 'PUT_USER';
export const RESET_USER = 'RESET_USER';
export const RESET_ALL_STORE = 'RESET_ALL_STORE';
export const FOLLOW_USER = 'FOLLOW_USER';
export const UNFOLLOW_USER = 'UNFOLLOW_USER';

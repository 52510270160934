/* トラッキングの利用 */
const enableTracking = process.env.REACT_APP_GA_TRACKING_ENABLE;
/* トラッキングコード */
const trackingId = process.env.REACT_APP_GA_TRACKING_ID;
/* イベントカテゴリのサフィックス */
const categorySuffix = process.env.REACT_APP_GA_TRACKING_CATEGORY_SUFFIX;

/**
 * ページビューの送信
 *
 * 引数に指定した URL にアクセスしたことを Google Analytics
 * に送信する。
 *
 * @param {string} pathName - URL
 */
export const sendAnalyticsPageView = (pathName) => {
  if (enableTracking !== 'true') {
    // console.log(`<sendAnalyticsEvent> - sendAnalyticsPageView:${pathName}`);
    return;
  }

  if (window && window.gtag) {
    window.gtag('config', trackingId, {
      page_path: pathName,
    });
  }
};

/**
 * イベントの送信
 *
 * 引数に指定したイベント情報を Google Analytics に送信する。
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs/events?hl=ja#send_events
 *
 * @param {string} category - カテゴリー('question'|'reply'|'draftQuestion')
 * @param {string} action - アクション('create'|'like'|'createFromDraft')
 * @param {string|null} label - ラベル(省略)
 * @param {number|null} value - 値(省略)
 */
export const sendAnalyticsEvent = (
  category,
  action,
  label = null,
  value = 1,
) => {
  if (enableTracking !== 'true') {
    console.log(
      `<sendAnalyticsEvent> - sendAnalyticsEvent:${category}${categorySuffix}:${action}:${label}:${value}`,
    );
    return;
  }
  try {
    if (window && window.gtag) {
      window.gtag('event', action, {
        event_category: category + categorySuffix,
        event_label: label,
        value: value,
      });
    }
  } catch (e) {
    /* エラーは捨てる */
  }
};

/**
 * GAへ送信するトラッキング情報にuserIdを設定する
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs/cookies-user-id?hl=ja
 *
 * @param {string} userId - 外部ユーザーID
 */
export const setUserId = (userId) => {
  if (window && window.gtag) {
    window.gtag('set', {
      user_id: userId,
    });
  }
};

import * as React from 'react';
import licenses from '../../constants/license.json';
import styles from './OssLicense.module.css';

export default function OssLicense() {
  return (
    <div className={styles.root}>
      <h1 className={styles.title}>オープンソースライセンス</h1>
      {Object.keys(licenses).map((name) => (
        <>
          <h2 className={styles.name}>{name}</h2>
          <pre className={styles.text}>{licenses[name].licenseText}</pre>
        </>
      ))}
    </div>
  );
}

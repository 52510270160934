import * as React from 'react';
import { Link } from 'react-router-dom';
import Area from '../Area';
import styles from './header.module.css';
import { STATUS } from '../../constants/status';
import { logout } from '../../utils/logout';
import { firebase } from '../../firebase';

/**
 * ヘッダー
 * 自アカウント取得やログアウト処理も含む
 *
 * @reactProps {object} user - ログインユーザーオブジェクト
 * @reactProps {number} status - 登録の進捗ステータス
 * @reactProps {function} getUser - ユーザー情報を取得
 * @reactProps {function} openPostModal - 質問モーダルを開く
 * @reactProps {function} closePostModal - 質問モーダルを閉じる
 * @reactProps {function} resetAllStore - すべてのストアをリセットする
 * @reactProps {function} confirm - 確認ダイアログを開く関数
 * @reactProps {string} accountIntegrationStatus - アカウント統合ステータス
 */
class Header extends React.Component {
  logout() {
    this.props.confirm('ログアウトしますか？', () => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          logout(this.props.resetAllStore);
        });
    });
  }

  renderMenu() {
    if (this.props.status > STATUS.SIGNIN) {
      return (
        <nav className={styles.right}>
          <ul className={styles.lists}>
            <li className={styles.list}>
              <Link
                className={`${styles.logout}`}
                to="/"
                onClick={() => this.logout()}
              >
                ログアウト
              </Link>
            </li>
          </ul>
        </nav>
      );
    }
    return null;
  }

  render() {
    return (
      <header className={styles.wrapper}>
        <Area>
          <div className={styles.container}>
            <h1>
              <Link to="/">
                <img
                  className={styles.logo}
                  src="/images/antaa_logo_small.png"
                  alt="Antaa"
                />
              </Link>
            </h1>
            {this.renderMenu()}
          </div>
        </Area>
      </header>
    );
  }
}

export default Header;

import * as c from '../constants/hospitals';

/**
 * 所属医療機関一覧の取得成功をreducerへ通知する
 * @param {object} items - 項目オブジェクト
 */
export function successGetHospitals(items) {
  return {
    type: c.GET_HOSPITALS,
    payload: {
      hospitals: items.hospitals,
      totalHospitalNum: items.totalHospitalNum,
    },
  };
}

/**
 * 所属医療機関一覧の取得失敗をreducerへ通知する
 * @param {string} message - エラーメッセージ文字列
 */
export function failureGetHospitals(message) {
  return {
    type: c.GET_HOSPITALS,
    payload: {
      message,
    },
    error: true,
  };
}

/**
 * 所属医療機関一覧の削除をreducerへ通知する
 * @param {object} items - 項目オブジェクト
 */
export function clearHospitals() {
  return {
    type: c.CLEAR_HOSPITALS,
    payload: {
      hospitals: [],
      totalHospitalNum: null,
    },
  };
}

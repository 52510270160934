import * as c from '../constants/profileUpdate';
import { RESET_ALL_STORE } from '../constants/user';

const initialState = {
  isFetchingNecessity: false,
  profileUpdateModal: {
    show: false,
    isSkippedBefore: false,
  },
  profileHospitalUpdateModal: {
    show: false,
  },
  profileDepartmentUpdateModal: {
    show: false,
    awaitingHospitalUpdate: false,
  },
  isHandlingUserAction: false,
  error: null,
  departmentUpdateErrors: [],
};

export default function ui(state = initialState, action) {
  switch (action.type) {
    case c.REQUEST_GET_NECESSITY_PROFILE_UPDATE_INDUCTION:
      return {
        ...state,
        isFetchingNecessity: true,
        profileUpdateModal: {
          show: false,
          isSkippedBefore: false,
        },
        profileHospitalUpdateModal: {
          show: false,
        },
        profileDepartmentUpdateModal: {
          show: false,
          awaitingHospitalUpdate: false,
        },
      };
    case c.SUCCESS_GET_NECESSITY_PROFILE_UPDATE_INDUCTION: {
      const {
        isNecessary,
        suggestCount,
        partialUpdateNecessary,
      } = action.payload;

      // If isNecessary is true, we don't show hospital and department update popups
      const showHospitalUpdateModal =
        !isNecessary && partialUpdateNecessary.hospital;
      const showDepartmentUpdateModal =
        !isNecessary && partialUpdateNecessary.department;

      return {
        ...state,
        isFetchingNecessity: false,
        profileUpdateModal: {
          show: isNecessary,
          isSkippedBefore: suggestCount >= 2,
        },
        profileHospitalUpdateModal: {
          show: showHospitalUpdateModal,
        },
        profileDepartmentUpdateModal: {
          show: showDepartmentUpdateModal,
          // If showHospitalUpdateModal is true, we wait for hospital update to be done
          awaitingHospitalUpdate: showHospitalUpdateModal,
        },
      };
    }
    case c.FAILURE_GET_NECESSITY_PROFILE_UPDATE_INDUCTION:
      return {
        ...state,
        isFetchingNecessity: false,
        profileUpdateModal: {
          show: false,
          isSkippedBefore: false,
        },
        profileHospitalUpdateModal: {
          show: false,
        },
        profileDepartmentUpdateModal: {
          show: false,
        },
        error: action.payload.error,
      };

    case c.CLOSE_PROFILE_UPDATE_MODAL:
      return {
        ...state,
        profileUpdateModal: {
          show: false,
          isSkippedBefore: false,
        },
      };

    case c.REQUEST_HANDLE_USER_ACTION_PROFILE_UPDATE:
      return {
        ...state,
        isHandlingUserAction: true,
      };

    case c.SUCCESS_HANDLE_USER_ACTION_PROFILE_UPDATE:
      return {
        ...state,
        isHandlingUserAction: false,
      };

    case c.FAILURE_HANDLE_USER_ACTION_PROFILE_UPDATE:
      return {
        ...state,
        isHandlingUserAction: false,
        error: action.payload.error,
      };

    case c.CLOSE_PROFILE_HOSPITAL_UPDATE_MODAL:
      return {
        ...state,
        profileHospitalUpdateModal: {
          show: false,
        },
        profileDepartmentUpdateModal: {
          ...state.profileDepartmentUpdateModal,
          awaitingHospitalUpdate: false,
        },
      };

    case c.REQUEST_HANDLE_PROFILE_HOSPITAL_UPDATE:
      return {
        ...state,
        isHandlingUserAction: true,
      };

    case c.SUCCESS_HANDLE_PROFILE_HOSPITAL_UPDATE:
      return {
        ...state,
        isHandlingUserAction: false,
        profileDepartmentUpdateModal: {
          ...state.profileDepartmentUpdateModal,
          awaitingHospitalUpdate: false,
        },
      };

    case c.FAILURE_HANDLE_PROFILE_HOSPITAL_UPDATE:
      return {
        ...state,
        isHandlingUserAction: false,
        profileDepartmentUpdateModal: {
          ...state.profileDepartmentUpdateModal,
          awaitingHospitalUpdate: false,
        },
        error: action.payload.error,
      };

    case c.CLOSE_PROFILE_DEPARTMENT_UPDATE_MODAL:
      return {
        ...state,
        profileDepartmentUpdateModal: {
          show: false,
          awaitingHospitalUpdate: false,
        },
      };

    case c.REQUEST_HANDLE_PROFILE_DEPARTMENT_UPDATE:
      return {
        ...state,
        isHandlingUserAction: true,
      };

    case c.SUCCESS_HANDLE_PROFILE_DEPARTMENT_UPDATE:
      return {
        ...state,
        isHandlingUserAction: false,
      };

    case c.FAILURE_HANDLE_PROFILE_DEPARTMENT_UPDATE:
      return {
        ...state,
        isHandlingUserAction: false,
        error: action.payload.error,
        departmentUpdateErrors: action.payload.errors,
      };

    case RESET_ALL_STORE:
      return initialState;

    default:
      return state;
  }
}

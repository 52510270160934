import { connect } from 'react-redux';
import ProfileHospitalUpdateModal from './ProfileHospitalUpdateModal';
import { closeProfileHospitalUpdateModal } from '../../../actions/profileUpdate';
import { getHospitals } from '../../../services/hospitals';
import { getUser } from '../../../services/user';
import { handleProfileHospitalUpdate } from '../../../services/profileUpdate';

function mapStateToProps({ profileUpdate, auth, user, hospitals }) {
  return {
    discloseId: auth.discloseId,
    user: user.user,
    profileHospitalUpdateModal: profileUpdate.profileHospitalUpdateModal,
    isFetchingNecessity: profileUpdate.isFetchingNecessity,
    isHandlingUserAction: profileUpdate.isHandlingUserAction,
    hospitals,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUser(id) {
      dispatch(getUser(id));
    },
    getHospitals(query, limit, offset) {
      dispatch(getHospitals(query, limit, offset));
    },
    onModalFormSubmit(hospitalId, hospitalName, handleFetchingUserData) {
      dispatch(
        handleProfileHospitalUpdate(hospitalId, hospitalName, async () => {
          await handleFetchingUserData();
          dispatch(closeProfileHospitalUpdateModal());
        }),
      );
    },
    handleModalClose() {
      dispatch(closeProfileHospitalUpdateModal());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileHospitalUpdateModal);

import React from 'react';
import styles from './primaryButton.module.css';

/**
 * プライマリーボタン
 *
 * @reactProps {string} type - input要素のtype属性
 * @reactProps {string} theme - ボタンのテーマ（CSSのスタイリングにのみ利用）
 * @reactProps {function} onClick - クリック時に発火される関数
 * @reactProps {string} value - ボタンのテキスト
 * @reactProps {object} style - スタイル
 * @reactProps {boolean} disabled - disabled属性
 * @reactProps {boolean} loading - ボタン発火の通信によるローディング中かどうか
 */
function PrimaryButton({
  type = 'button',
  theme = 'submit',
  onClick,
  value,
  style,
  disabled = false,
  loading = false,
}) {
  return (
    <input
      type={type}
      className={`${styles.button} ${styles[theme]} ${
        loading ? styles.isLoading : ''
      }`}
      onClick={onClick}
      value={value}
      style={style}
      disabled={disabled || loading}
    />
  );
}

export default PrimaryButton;

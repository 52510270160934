import * as c from '../constants/threads';
import {
  POST_MESSAGE,
  DELETE_MESSAGE,
  EDIT_MESSAGE,
} from '../constants/messages';
import { POST_REPLY, DELETE_REPLY } from '../constants/replies';
import { DELETE_QUESTION, EDIT_QUESTION } from '../constants/question';
import { RESET_ALL_STORE } from '../constants/user';

const initialState = {
  thread: {}, // 質問単体オブジェクト
  requestGetGroupThreads: false, // グループ質問一覧を取得中フラグ
  groupThreads: null, // グループ質問一覧オブジェクト
  groupThreadsCount: 0, // グループ質問一覧の合計数
  requestGetLikedThreads: false, // 保存した質問一覧を取得中フラグ
  likedThreads: null, // 保存した質問一覧オブジェクト
  likedThreadsCount: 0, // 保存した質問一覧の合計数
  requestGetUnsolvedThreads: false, // 未解決の質問一覧を取得中フラグ
  unsolvedThreads: null, // 未解決の質問一覧オブジェクト
  unsolvedThreadsCount: 0, // 未解決の質問一覧の合計数
  requestGetFilteredThreads: false, // フィルターされた質問一覧を取得中フラグ
  filteredThreads: null, // フィルターされた質問一覧オブジェクト
  filteredThreadsCount: 0, // フィルターされた質問一覧の合計数
  requestGetSearchedThreads: false, // 検索結果一覧の取得中フラグ
  searchedThreads: null, // 検索結果一覧オブジェクト
  searchedThreadsCount: 0, // 検索結果一覧の合計数
  requestGetKnowledgeThreads: false, // 共有した症例一覧を取得中フラグ
  knowledgeThreads: null, // 共有した症例一覧オブジェクト
  knowledgeThreadsCount: 0, // 共有した症例一覧の合計数
  requestGetAnsweredThreads: false, // 回答済み質問の一覧を取得中フラグ
  answeredThreads: null, // 回答済み質問の一覧配列
  answeredThreadsCount: 0, // 回答済み質問の合計数
  requestGetAskedThreads: false, // 質問済み質問の一覧を取得中フラグ
  askedThreads: null, // 質問済み質問の一覧配列
  askedThreadsCount: 0, // 質問済み質問の合計数
  requestGetSharedThreads: false, // 共有済み症例の一覧を取得中フラグ
  sharedThreads: null, // 共有済み症例の一覧配列
  sharedThreadsCount: 0, // 共有済み症例の合計数
  error: null, // エラー文字列
  shouldUpdate: false, // 質問一覧更新フラグ

  // エラー管理。
  // error（上記）だと複数のエラー情報で上書きしあってしまう問題あり。
  // TODO errorからこちらへ移行していく。
  errors: {
    getThread: null,
  },
};

export default function threads(state = initialState, action) {
  /**
   * 質問リストに保存を設定
   * @param {array} threads
   * @param {object} payload
   */
  const addLikeToThreads = (threads, payload) => {
    if (threads === null) {
      return null;
    }
    return threads.map((thread) => {
      if (thread.questionId === payload.threadId) {
        return {
          ...thread,
          likeUserIds: [...thread.likeUserIds, payload.uid],
          likeCount: thread.likeCount + 1,
        };
      } else {
        return thread;
      }
    });
  };

  /**
   * 質問リストから保存を解除
   * @param {array} threads
   * @param {object} payload
   */
  const cancelLikeToThreads = (threads, payload) => {
    if (threads === null) {
      return null;
    }
    return threads.map((thread) => {
      if (thread.questionId === payload.threadId) {
        return {
          ...thread,
          likeUserIds: thread.likeUserIds.filter((id) => id !== payload.uid),
          likeCount: thread.likeCount - 1,
        };
      } else {
        return thread;
      }
    });
  };

  /**
   * 回答リストに保存を設定
   * @param {array} threads
   * @param {object} payload
   */
  const addLikeToMessages = (threads, payload) => {
    if (threads === null) {
      return null;
    }
    return threads.map((thread) => {
      if (thread.questionId === payload.threadId) {
        const answers = thread.answers?.map((answer) => {
          if (answer.answerId === payload.messageId) {
            return {
              ...answer,
              likeUserIds: [...answer.likeUserIds, payload.uid],
              likeCount: answer.likeCount + 1,
            };
          } else {
            return answer;
          }
        });
        return {
          ...thread,
          answers,
        };
      } else {
        return thread;
      }
    });
  };

  /**
   * 回答リストから保存を解除
   * @param {array} threads
   * @param {object} payload
   */
  const cancelLikeToMessages = (threads, payload) => {
    if (threads === null) {
      return null;
    }
    return threads.map((thread) => {
      if (thread.questionId === payload.threadId) {
        const answers = thread.answers?.map((answer) => {
          if (answer.answerId === payload.messageId) {
            return {
              ...answer,
              likeUserIds: answer.likeUserIds.filter(
                (id) => id !== payload.uid,
              ),
              likeCount: answer.likeCount - 1,
            };
          } else {
            return answer;
          }
        });
        return {
          ...thread,
          answers,
        };
      } else {
        return thread;
      }
    });
  };

  /**
   * 質問リストを編集
   * @param threads
   * @param payload
   */
  const editMessageToThreads = (threads, payload) => {
    if (threads === null || threads.length === 0) {
      return threads;
    }
    const edit = (thread) => thread.questionId === payload.threadId;
    const notEdit = (thread) => thread.questionId !== payload.threadId;
    const targetThread = threads.filter(edit)[0];
    const otherThread = threads.filter(notEdit);

    if (!targetThread) {
      return threads;
    }

    const { addPhotoUrls, removePhotoIds } = payload.params;
    /* 削除した画像を省く */
    if (removePhotoIds) {
      targetThread.questionPhotos = targetThread.questionPhotos.filter(
        (item) => !removePhotoIds.includes(item.photoId),
      );
    }
    /* アップロードした画像を追加する */
    if (addPhotoUrls) {
      targetThread.questionPhotos = [
        ...targetThread.questionPhotos,
        ...addPhotoUrls,
      ];
    }

    return [
      {
        ...targetThread,
        postType: payload.params.postType,
        title: payload.params.title,
        message: payload.params.text,
        departmentIds: payload.params.departmentCodes,
        isUrgent: payload.params.isUrgent,
      },
      ...otherThread,
    ];
  };

  switch (action.type) {
    // 質問を取得する
    case c.GET_THREAD:
      if (action.error) {
        return {
          ...state,
          errors: {
            ...state.errors,
            getThread: action.payload.message,
          },
        };
      }
      return {
        ...state,
        thread: action.payload.thread,
        errors: {
          ...state.errors,
          getThread: null,
        },
      };

    // グループ質問一覧を取得開始する
    case c.REQUEST_GET_GROUP_THREADS:
      return {
        ...state,
        groupThreads: null,
        groupThreadsCount: 0,
        requestGetGroupThreads: true,
        error: null,
      };

    // グループ質問一覧を取得する
    case c.GET_GROUP_THREADS:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
          requestGetGroupThreads: false,
        };
      }
      return {
        ...state,
        groupThreads: action.payload.threads,
        groupThreadsCount: action.payload.threadsCount,
        requestGetGroupThreads: false,
        error: null,
      };

    // 次のグループ質問一覧を取得開始する
    case c.REQUEST_GET_GROUP_THREADS_WITH_CURSOR:
      return {
        ...state,
        requestGetGroupThreads: true,
      };

    // 次のグループ質問一覧を取得する
    case c.GET_GROUP_THREADS_WITH_CURSOR:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
        };
      }
      return {
        ...state,
        groupThreads: [
          ...(state.groupThreads || []),
          ...action.payload.threads,
        ],
        groupThreadsCount: action.payload.threadsCount,
        requestGetGroupThreads: false,
        error: null,
      };

    // 保存した質問一覧を取得開始する
    case c.REQUEST_GET_LIKED_THREADS:
      return {
        ...state,
        likedThreads: null,
        requestGetLikedThreads: true,
        error: null,
      };

    // 保存した質問一覧を取得
    case c.GET_LIKED_THREADS:
    case c.GET_LIKED_THREADS_WITH_CURSOR:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
          requestGetLikedThreads: false,
        };
      }
      return {
        ...state,
        likedThreads: [
          ...(state.likedThreads || []),
          ...action.payload.threads,
        ],
        likedThreadsCount: action.payload.threadsCount,
        requestGetLikedThreads: false,
        error: null,
      };

    // 次の保存した質問一覧を取得開始する
    case c.REQUEST_GET_LIKED_THREADS_WITH_CURSOR:
      return {
        ...state,
        requestGetLikedThreads: true,
      };

    // 未解決の質問一覧を取得開始する
    case c.REQUEST_GET_UNSOLVED_THREADS:
      return {
        ...state,
        unsolvedThreads: null,
        requestGetUnsolvedThreads: true,
        error: null,
      };

    // 未回答の質問一覧を取得する
    case c.GET_UNSOLVED_THREADS:
    case c.GET_UNSOLVED_THREADS_WITH_CURSOR:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
          requestGetUnsolvedThreads: false,
        };
      }
      return {
        ...state,
        unsolvedThreads: [
          ...(state.unsolvedThreads || []),
          ...(action.payload.threads || []),
        ],
        unsolvedThreadsCount: action.payload.threadsCount,
        requestGetUnsolvedThreads: false,
        error: null,
      };

    // 次の未回答の質問一覧を取得開始する
    case c.REQUEST_GET_UNSOLVED_THREADS_WITH_CURSOR:
      return {
        ...state,
        requestGetUnsolvedThreads: true,
      };

    // 症例一覧を取得開始する
    case c.REQUEST_GET_KNOWLEDGE_THREADS:
      return {
        ...state,
        knowledgeThreads: null,
        requestGetKnowledgeThreads: true,
        error: null,
      };

    // 症例一覧を取得する
    case c.GET_KNOWLEDGE_THREADS:
    case c.GET_KNOWLEDGE_THREADS_WITH_CURSOR:
      return {
        ...state,
        knowledgeThreads: [
          ...(state.knowledgeThreads || []),
          ...(action.payload.threads || []),
        ],
        knowledgeThreadsCount: action.payload.threadsCount,
        requestGetKnowledgeThreads: false,
        error: null,
      };

    // 次の症例一覧を取得開始する
    case c.REQUEST_GET_KNOWLEDGE_THREADS_WITH_CURSOR:
      return {
        ...state,
        requestGetKnowledgeThreads: true,
      };

    // 検索結果の質問一覧を取得開始する
    case c.REQUEST_GET_SEARCHED_THREADS:
      return {
        ...state,
        searchedThreads: null,
        searchedThreadsCount: 0,
        requestGetSearchedThreads: true,
        error: null,
      };

    // 検索結果の質問一覧を取得する
    case c.GET_SEARCHED_THREADS:
    case c.GET_SEARCHED_THREADS_WITH_CURSOR:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
          requestGetSearchedThreads: false,
        };
      }
      return {
        ...state,
        requestGetSearchedThreads: false,
        searchedThreads: [
          ...(state.searchedThreads || []),
          ...(action.payload.threads || []),
        ],
        searchedThreadsCount: action.payload.threadsCount,
        error: null,
      };

    // 検索結果の質問一覧の次の20件を取得開始する
    case c.REQUEST_GET_SEARCHED_THREADS_WITH_CURSOR:
      return {
        ...state,
        requestGetSearchedThreads: true,
      };

    // フィルターされた質問一覧を取得開始する
    case c.REQUEST_GET_FILTERED_THREADS:
      return {
        ...state,
        filteredThreads: null,
        filteredThreadsCount: 0,
        requestGetFilteredThreads: true,
        error: null,
      };

    // フィルターされた質問一覧の次の20件を取得開始する
    case c.REQUEST_GET_FILTERED_THREADS_WITH_CURSOR:
      return {
        ...state,
        requestGetFilteredThreads: true,
      };

    // フィルターされた質問一覧を取得する
    case c.GET_FILTERED_THREADS:
    case c.GET_FILTERED_THREADS_WITH_CURSOR:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
          requestGetFilteredThreads: false,
        };
      }
      return {
        ...state,
        requestGetFilteredThreads: false,
        filteredThreads: [
          ...(state.filteredThreads || []),
          ...(action.payload.threads || []),
        ],
        filteredThreadsCount: action.payload.threadsCount,
        error: null,
      };

    // 回答した質問一覧を取得のリクエスト開始
    case c.REQUEST_GET_ANSWERED_THREADS:
      return {
        ...state,
        answeredThreads: null,
        requestGetAnsweredThreads: true,
      };

    // 次の回答した質問一覧を取得のリクエスト開始
    case c.REQUEST_GET_ANSWERED_THREADS_WITH_CURSOR:
      return {
        ...state,
        requestGetAnsweredThreads: true,
      };

    // 回答した質問一覧を取得する
    case c.GET_ANSWERED_THREADS:
    case c.GET_ANSWERED_THREADS_WITH_CURSOR:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
          requestGetAnsweredThreads: false,
        };
      }
      return {
        ...state,
        answeredThreads: [
          ...(state.answeredThreads || []),
          ...(action.payload.threads || []),
        ],
        answeredThreadsCount: action.payload.threadsCount,
        requestGetAnsweredThreads: false,
        error: null,
      };

    // 聞いた質問一覧を取得のリクエスト開始
    case c.REQUEST_GET_ASKED_THREADS:
      return {
        ...state,
        askedThreads: null,
        requestGetAskedThreads: true,
      };

    // 次の聞いた質問一覧を取得のリクエスト開始
    case c.REQUEST_GET_ASKED_THREADS_WITH_CURSOR:
      return {
        ...state,
        requestGetAskedThreads: true,
      };

    // 聞いた質問一覧を取得する
    case c.GET_ASKED_THREADS:
    case c.GET_ASKED_THREADS_WITH_CURSOR:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
          requestGetAskedThreads: false,
        };
      }
      return {
        ...state,
        askedThreads: [
          ...(state.askedThreads || []),
          ...action.payload.threads,
        ],
        askedThreadsCount: action.payload.threadsCount,
        requestGetAskedThreads: false,
        error: null,
      };

    // 共有した症例一覧を取得開始する
    case c.REQUEST_GET_SHARED_THREADS:
      return {
        ...state,
        sharedThreads: null,
        requestGetSharedThreads: true,
      };

    // 共有した症例一覧を取得する
    case c.GET_SHARED_THREADS:
    case c.GET_SHARED_THREADS_WITH_CURSOR:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
          requestGetSharedThreads: false,
        };
      }
      return {
        ...state,
        sharedThreads: [
          ...(state.sharedThreads || []),
          ...(action.payload.threads || []),
        ],
        sharedThreadsCount: action.payload.threadsCount,
        requestGetSharedThreads: false,
      };

    // 次の共有した症例一覧を取得開始する
    case c.REQUEST_GET_SHARED_THREADS_WITH_CURSOR:
      return {
        ...state,
        requestGetSharedThreads: true,
      };

    // apiの実行より先に質問を保存時のstateを変更する
    case c.FAST_LIKE_THREAD:
      return {
        ...state,
        groupThreads: addLikeToThreads(state.groupThreads, action.payload),
        thread:
          Object.keys(state.thread).length > 0 &&
          state.thread.questionId === action.payload.threadId
            ? {
                ...state.thread,
                likeUserIds: [...state.thread.likeUserIds, action.payload.uid],
                likeCount: state.thread.likeCount + 1,
              }
            : state.thread,
        unsolvedThreads: addLikeToThreads(
          state.unsolvedThreads,
          action.payload,
        ),
        filteredThreads: addLikeToThreads(
          state.filteredThreads,
          action.payload,
        ),
        searchedThreads: addLikeToThreads(
          state.searchedThreads,
          action.payload,
        ),
        knowledgeThreads: addLikeToThreads(
          state.knowledgeThreads,
          action.payload,
        ),
        askedThreads: addLikeToThreads(state.askedThreads, action.payload),
        answeredThreads: addLikeToThreads(
          state.answeredThreads,
          action.payload,
        ),
        sharedThreads: addLikeToThreads(state.sharedThreads, action.payload),
      };

    // apiの実行より先に回答いいね付与時のstateを変更する
    case c.FAST_LIKE_MESSAGE:
      return {
        ...state,
        groupThreads: addLikeToMessages(state.groupThreads, action.payload),
        filteredThreads: addLikeToMessages(
          state.filteredThreads,
          action.payload,
        ),
        searchedThreads: addLikeToMessages(
          state.searchedThreads,
          action.payload,
        ),
        knowledgeThreads: addLikeToMessages(
          state.knowledgeThreads,
          action.payload,
        ),
        askedThreads: addLikeToMessages(state.askedThreads, action.payload),
        answeredThreads: addLikeToMessages(
          state.answeredThreads,
          action.payload,
        ),
        sharedThreads: addLikeToMessages(state.sharedThreads, action.payload),
      };

    // 質問を保存する
    case c.LIKE_THREAD:
      if (action.error) {
        return {
          ...state,
          groupThreads: cancelLikeToThreads(state.groupThreads, action.payload),
          thread:
            Object.keys(state.thread).length > 0 &&
            state.thread.questionId === action.payload.threadId
              ? {
                  ...state.thread,
                  likeUserIds: state.thread.likeUserIds.filter(
                    (id) => id !== action.payload.uid,
                  ),
                  likeCount: state.thread.likeCount - 1,
                }
              : state.thread,
          unsolvedThreads: cancelLikeToThreads(
            state.unsolvedThreads,
            action.payload,
          ),
          likedThreads:
            state.likedThreads &&
            state.likedThreads.filter(
              (thread) => thread.questionId !== action.payload.threadId,
            ),
          filteredThreads: cancelLikeToThreads(
            state.filteredThreads,
            action.payload,
          ),
          searchedThreads: cancelLikeToThreads(
            state.searchedThreads,
            action.payload,
          ),
          knowledgeThreads: cancelLikeToThreads(
            state.knowledgeThreads,
            action.payload,
          ),
          askedThreads: cancelLikeToThreads(state.askedThreads, action.payload),
          answeredThreads: cancelLikeToThreads(
            state.answeredThreads,
            action.payload,
          ),
          sharedThreads: cancelLikeToThreads(
            state.sharedThreads,
            action.payload,
          ),
        };
      }
      return {
        ...state,
      };

    // apiの実行より先に質問への保存取り消し時のstateを変更する
    case c.FAST_UNLIKE_THREAD:
      return {
        ...state,
        groupThreads: cancelLikeToThreads(state.groupThreads, action.payload),
        thread:
          Object.keys(state.thread).length > 0 &&
          state.thread.questionId === action.payload.threadId
            ? {
                ...state.thread,
                likeUserIds: state.thread.likeUserIds.filter(
                  (id) => id !== action.payload.uid,
                ),
                likeCount: state.thread.likeCount - 1,
              }
            : state.thread,
        unsolvedThreads: cancelLikeToThreads(
          state.unsolvedThreads,
          action.payload,
        ),
        likedThreads:
          state.likedThreads &&
          state.likedThreads.filter(
            (thread) => thread.questionId !== action.payload.threadId,
          ),
        filteredThreads: cancelLikeToThreads(
          state.filteredThreads,
          action.payload,
        ),
        searchedThreads: cancelLikeToThreads(
          state.searchedThreads,
          action.payload,
        ),
        knowledgeThreads: cancelLikeToThreads(
          state.knowledgeThreads,
          action.payload,
        ),
        askedThreads: cancelLikeToThreads(state.askedThreads, action.payload),
        answeredThreads: cancelLikeToThreads(
          state.answeredThreads,
          action.payload,
        ),
        sharedThreads: cancelLikeToThreads(state.sharedThreads, action.payload),
      };

    // apiの実行より先に回答いいね解除時のstateを変更する
    case c.FAST_UNLIKE_MESSAGE:
      return {
        ...state,
        groupThreads: cancelLikeToMessages(state.groupThreads, action.payload),
        unsolvedThreads: cancelLikeToMessages(
          state.unsolvedThreads,
          action.payload,
        ),
        likedThreads:
          state.likedThreads &&
          state.likedThreads.filter(
            (thread) => thread.questionId !== action.payload.threadId,
          ),
        filteredThreads: cancelLikeToMessages(
          state.filteredThreads,
          action.payload,
        ),
        searchedThreads: cancelLikeToMessages(
          state.searchedThreads,
          action.payload,
        ),
        knowledgeThreads: cancelLikeToMessages(
          state.knowledgeThreads,
          action.payload,
        ),
        askedThreads: cancelLikeToMessages(state.askedThreads, action.payload),
        answeredThreads: cancelLikeToMessages(
          state.answeredThreads,
          action.payload,
        ),
        sharedThreads: cancelLikeToMessages(
          state.sharedThreads,
          action.payload,
        ),
      };

    // 質問への保存を取り消す
    case c.UNLIKE_THREAD:
      if (action.error) {
        return {
          ...state,
          groupThreads: addLikeToThreads(state.groupThreads, action.payload),
          thread:
            Object.keys(state.thread).length > 0 &&
            state.thread.questionId === action.payload.threadId
              ? {
                  ...state.thread,
                  likeUserIds: [
                    ...state.thread.likeUserIds,
                    action.payload.uid,
                  ],
                  likeCount: state.thread.likeCount + 1,
                }
              : state.thread,
          unsolvedThreads: addLikeToThreads(
            state.unsolvedThreads,
            action.payload,
          ),
          filteredThreads: addLikeToThreads(
            state.filteredThreads,
            action.payload,
          ),
          searchedThreads: addLikeToThreads(
            state.searchedThreads,
            action.payload,
          ),
          knowledgeThreads: addLikeToThreads(
            state.knowledgeThreads,
            action.payload,
          ),
          askedThreads: addLikeToThreads(state.askedThreads, action.payload),
          answeredThreads: addLikeToThreads(
            state.answeredThreads,
            action.payload,
          ),
          sharedThreads: addLikeToThreads(state.sharedThreads, action.payload),
        };
      }
      return {
        ...state,
      };

    // 回答を投稿する
    case POST_MESSAGE:
      if (action.error) {
        return state;
      }

      return {
        ...state,
        shouldUpdate: true,
      };

    // 回答を削除する
    case DELETE_MESSAGE:
      if (action.error) {
        return state;
      }

      return {
        ...state,
        shouldUpdate: true,
      };

    // 回答を編集する
    case EDIT_MESSAGE:
      if (action.error) {
        return state;
      }

      return {
        ...state,
        shouldUpdate: true,
      };

    // 返信を投稿する
    case POST_REPLY:
      if (action.error) {
        return state;
      }

      return {
        ...state,
        shouldUpdate: true,
      };

    // 返信を削除する
    case DELETE_REPLY:
      if (action.error) {
        return state;
      }
      return {
        ...state,
        shouldUpdate: true,
      };

    // 質問を編集する
    case EDIT_QUESTION:
      if (action.error) {
        return state;
      }

      // 各スレッドの中身も修正する必要がある
      return {
        ...state,
        groupThreads: editMessageToThreads(state.groupThreads, action.payload),
        likedThreads: editMessageToThreads(state.likedThreads, action.payload),
        unsolvedThreads: editMessageToThreads(
          state.unsolvedThreads,
          action.payload,
        ),
        filteredThreads: editMessageToThreads(
          state.filteredThreads,
          action.payload,
        ),
        searchedThreads: editMessageToThreads(
          state.searchedThreads,
          action.payload,
        ),
        knowledgeThreads: editMessageToThreads(
          state.knowledgeThreads,
          action.payload,
        ),
        askedThreads: editMessageToThreads(state.askedThreads, action.payload),
        answeredThreads: editMessageToThreads(
          state.answeredThreads,
          action.payload,
        ),
        sharedThreads: editMessageToThreads(
          state.sharedThreads,
          action.payload,
        ),
      };

    // 質問を削除する
    case DELETE_QUESTION:
      if (action.payload.error) {
        return {
          ...state,
          error: action.payload.message,
        };
      }
      return {
        ...state,
        groupThreads: deleteQuestion(state.groupThreads, action.payload),
        likedThreads: deleteQuestion(state.likedThreads, action.payload),
        unsolvedThreads: deleteQuestion(state.unsolvedThreads, action.payload),
        filteredThreads: deleteQuestion(state.filteredThreads, action.payload),
        searchedThreads: deleteQuestion(state.searchedThreads, action.payload),
        knowledgeThreads: deleteQuestion(
          state.knowledgeThreads,
          action.payload,
        ),
        askedThreads: deleteQuestion(state.askedThreads, action.payload),
        answeredThreads: deleteQuestion(state.answeredThreads, action.payload),
        sharedThreads: deleteQuestion(state.sharedThreads, action.payload),
      };

    // Stateをリセットする
    case RESET_ALL_STORE:
    case c.RESET_ALL_THREADS:
      return initialState;

    case c.RESET_SHOULD_THREADS_UPDATE:
      return {
        ...state,
        shouldUpdate: false,
      };

    default:
      return state;
  }
}

/**
 * 質問一覧から特定の質問を削除
 * @param {array} threads - 質問一覧配列
 * @param {object} payload - 取得したオブジェクト
 * @returns {*}
 */
const deleteQuestion = (threads, payload) => {
  if (threads === null || threads.length === 0) {
    return threads;
  }
  return threads.filter((thread) => thread.questionId !== payload.questionId);
};

import React from 'react';
import { getUrls, regexUrl } from './url';

export const formatText = (text) => {
  if (text === undefined || text === null) {
    return null;
  }

  return text
    .split(/(\n)/g)
    .map((txt, i) =>
      txt !== '\n' ? replaceUrls(txt, i) : <br key={i + 10000} />,
    );
};

/**
 * テキストにURLが存在した場合、aタグに変換する(keyには一意になる適当な値を割り振る)
 *
 * @param {string} txt - テキスト
 * @param {string} index - テキスト
 */
const replaceUrls = (txt, index) => {
  let text = txt.split(regexUrl());

  for (let i = 0; i < text.length; i++) {
    const keyIndex = 100 * (index + 1);
    const urls = getUrls(text[i]);
    text[i] =
      urls && urls.length > 0 ? (
        <a
          href={text[i]}
          key={i + keyIndex}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => e.stopPropagation()}
        >
          {text[i]}
        </a>
      ) : (
        <span key={i + keyIndex}>{text[i]}</span>
      );
  }

  return text;
};

import * as c from '../constants/question';
import { RESET_ALL_STORE } from '../constants/user';
import { LIKE_THREAD, UNLIKE_THREAD } from '../constants/threads';
import { POST_MESSAGE, DELETE_MESSAGE } from '../constants/messages';
import { CLOSE_DETAIL_MODAL, OPEN_DETAIL_MODAL } from '../constants/ui';
import { Question } from '../constants/status';

const initialState = {
  isGettingQuestionTemplates: false, // テンプレートの取得リクエスト中かどうか
  isPostingQuestion: false, // 投稿の作成リクエスト中かどうか
  isEditingQuestion: false, // 投稿の編集リクエスト中かどうか
  isGettingQuestion: false, // 投稿の取得リクエスト中かどうか
  isDeletingQuestion: false, // 投稿の削除リクエスト中かどうか
  isPostingDraftQuestion: false, // 下書き投稿の作成リクエスト中かどうか
  isEditingDraftQuestion: false, // 下書き投稿の更新リクエスト中かどうか
  isSavedDraftQuestion: false, // 下書き投稿の保存済みかどうか
  isChangedQuestion: false, // 質問が変更されたかどうか
  draftQuestionId: undefined, // 下書き質問のId
  postType: Question.postType.QUESTION, // 投稿の種別文字列
  title: '', // 投稿時の入力中のタイトル
  text: '', // 投稿時の入力中のテキスト
  departmentCodes: [], // 投稿時の選択中の診療科目
  isUrgent: false, // 投稿時の選択中の緊急フラグ
  isDraft: false, // 下書きフラグ
  groupId: null, // 投稿時の選択中のグループ
  files: null, // 投稿時の選択中のファイル
  photoUrls: [], // 投稿画像のURL
  templates: null, // テンプレートオブジェクト配列
  errors: [], // バリデーションエラー
  error: null, // アプリケーションエラー
  show: null, // 詳細表示のデータ
  prev: {}, // 編集前のデータ
  edit: {
    // 編集中のデータ
    postType: Question.postType.QUESTION,
    title: '',
    text: '',
    groupId: null,
    departmentCodes: [],
    isUrgent: false,
    isDraft: false,
    files: null,
    photoUrls: [],
    errors: [],
    error: null,
  },
  answers: [], // 回答
};

export default function question(state = initialState, action) {
  switch (action.type) {
    // 質問テンプレート取得リクエストの開始
    case c.REQUEST_GET_QUESTION_TEMPLATES:
      return {
        ...state,
        isGettingQuestionTemplates: true,
      };

    // テンプレートを取得する
    case c.GET_QUESTION_TEMPLATES:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
        };
      }
      return {
        ...state,
        isGettingQuestionTemplates: false,
        templates: action.payload.templates,
      };

    // 質問の入力項目を保存する
    case c.INPUT_QUESTION_FORM:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
        isChangedQuestion: true,
        isSavedDraftQuestion: false,
      };

    // 質問の入力項目のデフォルトの値を保存
    case c.SET_DEFAULT_QUESTION_FORM:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
        isChangedQuestion: false,
        isSavedDraftQuestion: false,
      };

    // 質問投稿リクエストの開始
    case c.REQUEST_POST_QUESTION:
      return {
        ...state,
        isPostingQuestion: true,
      };

    // 下書き質問投稿リクエストの開始
    case c.REQUEST_POST_DRAFT_QUESTION:
      return {
        ...state,
        isPostingDraftQuestion: true,
      };

    // 質問詳細表示
    case OPEN_DETAIL_MODAL:
      return {
        ...state,
        show: action.payload.question,
      };

    // 質問詳細消去
    case CLOSE_DETAIL_MODAL:
      return {
        ...state,
        show: null,
      };

    // 回答の投稿
    case POST_MESSAGE:
      return {
        ...state,
        show: state.show
          ? {
              ...state.show,
              // answerCount: state.show.answerCount + 1,
            }
          : state.show,
      };

    // 回答の削除
    case DELETE_MESSAGE:
      return {
        ...state,
        show: state.show
          ? {
              ...state.show,
            }
          : state.show,
      };

    // 興味ありの設定
    case LIKE_THREAD:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
        };
      }
      return {
        ...state,
        show: state.show
          ? {
              ...state.show,
              likeUserIds: [...state.show.likeUserIds, action.payload.uid],
              likeCount: state.show.likeCount + 1,
            }
          : state.show,
      };

    // 興味ありの設定解除
    case UNLIKE_THREAD:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
        };
      }
      return {
        ...state,
        show: state.show
          ? {
              ...state.show,
              likeUserIds: state.show.likeUserIds.filter(
                (id) => id !== action.payload.uid,
              ),
              likeCount: state.show.likeCount - 1,
            }
          : state.show,
      };

    // 質問投稿の入力項目を検査する
    case c.VALIDATE_POST_QUESTION:
      if (action.payload.errors.length > 0) {
        return {
          ...state,
          errors: action.payload.errors,
          error: null,
        };
      }
      return {
        ...state,
        errors: [],
      };

    // 質問を投稿する
    case c.POST_QUESTION:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
          isPostingQuestion: false,
        };
      }
      return initialState;

    // 下書き質問をテキストフィールドに挿入
    case c.INSERT_QUESTION_FROM_DRAFT:
      return {
        ...state,
        draftQuestionId: action.payload.question.questionId,
        isChangedQuestion: false,
        isSavedDraftQuestion: true,
        postType: action.payload.question.postType,
        title: action.payload.question.title,
        text: action.payload.question.message,
        groupId: action.payload.question.groupOfResource.groupId,
        departmentCodes: action.payload.question.departmentIds || [],
        photoUrls: action.payload.question.questionPhotos || [],
        isUrgent: action.payload.question.isUrgent,
        isDraft: true,
        prev: {
          ...state.prev,
          postType: action.payload.question.postType,
          title: action.payload.question.title,
          text: action.payload.question.message,
          groupId: action.payload.question.groupOfResource.groupId,
          departmentCodes: action.payload.question.departmentIds || [],
          photoUrls: action.payload.question.questionPhotos || [],
          isUrgent: action.payload.question.isUrgent,
          isDraft: true,
        },
      };

    // 下書き質問を投稿する
    case c.POST_DRAFT_QUESTION:
      return {
        ...state,
        isPostingDraftQuestion: false,
        prev: {
          ...state.prev,
          postType: action.payload.params.postType,
          title: action.payload.params.title,
          text: action.payload.params.message,
          groupId: action.payload.params.groupId,
          departmentCodes: action.payload.params.departmentIds || [],
          photoUrls: action.payload.questionPhotoUrls || [],
          isUrgent: action.payload.params.isUrgent,
          isDraft: true,
        },
      };

    // 質問編集リクエストの開始
    case c.REQUEST_EDIT_QUESTION:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
        };
      }
      return {
        ...state,
        isEditingQuestion: true,
      };

    // 下書き質問編集リクエストの開始
    case c.REQUEST_EDIT_DRAFT_QUESTION:
      return {
        ...state,
        isEditingDraftQuestion: true,
      };

    // 質問編集の入力項目を検査する
    case c.VALIDATE_EDIT_QUESTION:
      if (action.payload.errors.length > 0) {
        return {
          ...state,
          edit: {
            ...state.edit,
            errors: action.payload.errors,
            error: null,
          },
        };
      }
      return {
        ...state,
        edit: {
          ...state.edit,
          errors: [],
        },
      };

    // 下書き質問編集の入力項目を検査する
    case c.VALIDATE_EDIT_DRAFT_QUESTION:
      if (action.payload.errors.length > 0) {
        return {
          ...state,
          errors: action.payload.errors,
          error: null,
        };
      }
      return {
        ...state,
        errors: [],
      };

    // 質問編集リクエストの完了
    case c.EDIT_QUESTION:
      if (action.error) {
        return {
          ...state,
          edit: {
            ...state.edit,
            error: action.payload.message,
          },
          isEditingQuestion: false,
        };
      }
      return initialState;

    // 下書き質問編集リクエストの完了
    case c.EDIT_DRAFT_QUESTION:
      return {
        ...initialState,
        isEditingDraftQuestion: false,
      };

    // 質問の編集項目を保存する
    case c.INPUT_EDIT_QUESTION_FORM:
      return {
        ...state,
        edit: {
          ...state.edit,
          [action.payload.key]: action.payload.value,
        },
      };

    // 質問取得リクエストの開始
    case c.REQUEST_GET_QUESTION:
      return {
        ...state,
        isGettingQuestion: true,
      };

    // スレッドを取得する
    case c.GET_QUESTION:
      return {
        ...state,
        prev: {
          ...state.edit,
          postType: action.payload.thread.postType,
          title: action.payload.thread.title,
          text: action.payload.thread.message,
          groupId: action.payload.thread.groupOfResource.groupId,
          departmentCodes: action.payload.thread.departmentIds || [],
          photoUrls: action.payload.thread.questionPhotos || [],
          isUrgent: action.payload.thread.isUrgent,
        },
        edit: {
          ...state.edit,
          postType: action.payload.thread.postType,
          title: action.payload.thread.title,
          text: action.payload.thread.message,
          groupId: action.payload.thread.groupOfResource.groupId,
          departmentCodes: action.payload.thread.departmentIds || [],
          photoUrls: action.payload.thread.questionPhotos || [],
          isUrgent: action.payload.thread.isUrgent,
        },
        isGettingQuestion: false,
      };

    // 質問削除リクエストの開始
    case c.REQUEST_DELETE_QUESTION:
      return {
        ...state,
        isDeletingQuestion: true,
      };

    // 質問削除リクエストの完了
    case c.DELETE_QUESTION:
      if (action.error) {
        return {
          ...state,
          isDeletingQuestion: false,
        };
      }
      return initialState;

    // 質問編集部分のStateをリセットする
    case c.RESET_EDIT_QUESTION:
      return {
        ...state,
        edit: initialState.edit,
      };

    case c.RESET_ALL_QUESTION:
      return {
        ...initialState,
        templates: state.templates,
      };

    // すべてリセットする
    case RESET_ALL_STORE:
      return initialState;

    default:
      return state;
  }
}

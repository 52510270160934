import * as c from '../constants/auth';

const initialState = {
  id: null, // firebase UID
  discloseId: null, // 外部向けユーザーID
  user: null, // ユーザー情報オブジェクト
  profilePhotoBlob: null, // ユーザープロファイル Blob オブジェクト
  permissions: null, // 権限
  error: null,
  socialLoginError: null, // Socialログインからリダイレクトで戻ってきたときのエラー
  emailVerificationSendError: null, // メールアドレス確認メール送信エラー
  emailVerificationTooManyRequestsError: null, // 短時間複数リクエストエラー
  updateEmailVerificationSendError: null, // メールアドレス変更時確認メール送信エラー
  updateEmailVerificationTooManyRequestsError: null, // メールアドレス変更時短時間複数リクエストエラー
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case c.SET_SOCIAL_LOGIN_ERROR:
      return {
        ...state,
        socialLoginError: action.payload.socialLoginError,
      };
    case c.SET_UID:
      return {
        ...state,
        id: action.payload.id,
      };
    case c.SET_DISCLOSE_ID:
    case c.SET_PRE_REGISTER:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
        };
      }
      return {
        ...state,
        discloseId: action.payload.discloseId,
      };
    case c.GET_AUTH_USER:
      return {
        ...state,
        user: action.payload.user,
      };
    case c.GET_AUTH_USER_PHOTO:
      return {
        ...state,
        profilePhotoBlob: action.payload.profilePhotoBlob,
      };
    case c.GET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload.permissions,
      };
    case c.SUCCESS_SEND_VERIFICATION_MAIL:
      return {
        ...state,
        emailVerificationSendError: null,
        emailVerificationTooManyRequestsError: false,
      };
    case c.FAILURE_SEND_VERIFICATION_MAIL:
      return {
        ...state,
        emailVerificationSendError: action.payload.message,
        emailVerificationTooManyRequestsError: false,
      };
    case c.FAILURE_VERIFICATION_MAIL_TOO_MANY_REQUESTS:
      return {
        ...state,
        emailVerificationSendError: null,
        emailVerificationTooManyRequestsError: true,
      };
    case c.SUCCESS_SEND_UPDATE_EMAIL_VERIFICATION_MAIL:
      return {
        ...state,
        updateEmailVerificationSendError: null,
        updateEmailVerificationTooManyRequestsError: false,
      };
    case c.FAILURE_SEND_UPDATE_EMAIL_VERIFICATION_MAIL:
      return {
        ...state,
        updateEmailVerificationSendError: action.payload.message,
        updateEmailVerificationTooManyRequestsError: false,
      };
    case c.FAILURE_EMAIL_VERIFICATION_MAIL_UPDATE_TOO_MANY_REQUESTS:
      return {
        ...state,
        updateEmailVerificationSendError: null,
        updateEmailVerificationTooManyRequestsError: true,
      };
    case c.RESET_PERMISSIONS:
    case c.RESET_ALL_STORE:
      return initialState;

    default:
      return state;
  }
}

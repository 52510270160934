import { connect } from 'react-redux';
import Signin from './Signin';
import { setPreRegister } from '../../services/auth';
import { setSocialLoginError } from '../../actions/auth';
import { idLogin, proceedIdPage } from '../../services/id';

function mapStateToProps({ auth }) {
  return {
    auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    async idLogin() {
      await dispatch(idLogin());
    },
    setSocialLoginError(error) {
      dispatch(setSocialLoginError(error));
    },
    setPreRegister() {
      return dispatch(setPreRegister());
    },
    async proceedIdPage(path) {
      await dispatch(proceedIdPage(path));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Signin);

import Signup from './Signup';
import { setPreRegister, sendVerificationMail } from '../../services/auth';
import { setSocialLoginError } from '../../actions/auth';
import { connect } from 'react-redux';

function mapStateToProps({ auth }) {
  return {
    auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSocialLoginError(error) {
      dispatch(setSocialLoginError(error));
    },
    setPreRegister() {
      return dispatch(setPreRegister());
    },
    sendVerificationMail() {
      return dispatch(sendVerificationMail());
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Signup);

import React from 'react';
import styles from './select.module.css';

/**
 * セレクトボックス
 *
 * @reactProps {string} name - セレクトボックス名
 * @reactProps {string} value - テキストエリアのvalue
 * @reactProps {string} className - テキストエリアのvalue
 * @reactProps {function} onFocus - フォーカス時に発火する関数
 * @reactProps {function} onChange - 変更時に発火する関数
 */
function Select({ name, value, className, onFocus, onChange, children }) {
  return (
    <select
      name={name}
      value={value}
      className={[styles.select, className].join(' ')}
      onFocus={onFocus}
      onChange={onChange}
    >
      {children}
    </select>
  );
}

export default Select;

import * as c from '../constants/streamContents';

const initialState = {
  streamContents: null, // 情報オブジェクト
  viewCount: 0, // 閲覧数
  liveViewerCount: 0, // ライブ閲覧数
  error: null, // API用
  notPurchaseError: false, // 有料コンテンツ取得エラーフラグ
  isForbidden: null, // 閲覧権限取得エラーフラグ
};

export default function streamContents(state = initialState, action) {
  switch (action.type) {
    // コンテンツ情報を取得する
    case c.GET_STREAM_CONTENTS:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
        };
      }

      return {
        ...state,
        isForbidden: false,
        streamContents: action.payload.streamContents,
        error: null,
      };

    // 閲覧数表示
    case c.INCREMENT_STREAMING_CONTENT_VIEW:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
        };
      }

      return {
        ...state,
        viewCount: action.payload.viewCount,
        error: null,
      };

    // 個人情報開示許可
    case c.AGREEMEMT_STREAMING_CONTENT_POLICY:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
        };
      }

      return {
        ...state,
        error: null,
      };

    // 無課金で有料コンテンツをリクエストした場合のエラー
    case c.FAILURE_PURCHASE:
      return {
        ...state,
        notPurchaseError: true,
      };

    // 閲覧権限が不足していた場合のエラー
    case c.FAILURE_FORBIDDEN:
      return {
        ...state,
        isForbidden: true,
      };

    // オンライン配信コンテンツのグループID取得
    case c.FETCH_STREAMING_CONTENT_GROUP_ID:
      return {
        ...state,
        error: action.payload.message,
      };

    default:
      return state;
  }
}

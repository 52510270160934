import React from 'react';
import styles from './loading.module.css';

/**
 * ローディング表示用のコンポーネント
 *
 * @reactProps {string} type - 興味ありのスレッド一覧
 */
function Loading({ type = 'nowrap' }) {
  if (type === 'wrap') {
    return (
      <div className={styles.wrap}>
        <img
          className={styles.loading}
          src="/images/loading.gif"
          alt="loading..."
        />
      </div>
    );
  }
  if (type === 'center') {
    return (
      <div className={styles.center}>
        <img
          className={styles.loading}
          src="/images/loading.gif"
          alt="loading..."
        />
      </div>
    );
  }
  return (
    <div className={styles.loading}>
      <img
        className={styles.loading}
        src="/images/loading.gif"
        alt="loading..."
      />
    </div>
  );
}

export default Loading;

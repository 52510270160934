/**
 *
 * ユーザーステータス
 *
 * アプリケーション内部で表示画面を判断するためのステータスID。
 * データベースの Users.userStatusId とは関係ないため注意。
 */
export const STATUS = {
  SIGNIN: 0,
  SIGNUP: 1,
  DOCTOR_AUTHENTICATION: 2,
  REGISTERED: 3,
  EXITED_USER: 4,
  TERMS_OF_SERVICE: 5,
  PRIVACY_POLICY: 6,
  ERROR: 99,
};

export const User = {
  /* 性別 */
  gender: {
    MALE: 'male',
    FEMALE: 'female',
  },
  /* 否認カテゴリ */
  invalidCategory: {
    INVALID_INFO: 'invalidInfo',
    INVALID_PHOTO: 'invalidPhoto',
    DUPLICATED_ACCOUNT: 'duplicatedAccount',
  },
  /* ステータス */
  status: {
    CREATED: 'created', // ( 1) アカウント登録後
    REVIEWING: 'reviewing', // ( 2) 医師免許登録 Done。申請受理待ち
    OK: 'ok', // (16) 参加
    TRIAL: 'trial', // (33) トライアル
    TRIAL_REVIEWING: 'trial_reviewing', // (34) トライアルからの医師免許登録 Done。申請受理待ち
    INVALID: 'invalid', // (64) 審査不適
    TRIAL_INVALID: 'trial_invalid', // (96) トライアルからの審査不適
    EXIT: 'exit', // (65) 退会
    BAN: 'ban', // (66) BAN
    EXPIRE: 'expire', // (67) invite 期限切れ
    REGISTERED: 'registered', // (128) アカウント登録済み
  },
  /* トライアルユーザーステータス */
  trialStatus: ['trial', 'trial_reviewing', 'trial_invalid'],
};

export const Question = {
  /* 投稿種別 */
  postType: {
    QUESTION: 'question',
    KNOWLEDGE: 'knowledge',
  },
};

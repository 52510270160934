import * as React from 'react';
import styles from './area.module.css';

/**
 * 横幅を整えるためのコンポーネント
 *
 * @reactProps {object} children - 内側に描画するコンポーネント
 */
export default ({ children }) => {
  return <div className={styles.wrapper}>{children}</div>;
};

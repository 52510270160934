import { connect } from 'react-redux';
import App from './App';
import { setStatus } from '../../actions/register';
import { setSocialLoginError, setUid } from '../../actions/auth';
import {
  checkAuthenticationInduction,
  resetDoctorAuthenticationInductionModal,
} from '../../actions/id';
import {
  getAuthUser,
  setDiscloseId,
  setUserStatus,
  setPreRegister,
} from '../../services/auth';
import { getRegisterRequest } from '../../services/register';
import { getPrivacyPolicyAgreements } from '../../services/privacyPolicyAgreements';
import { getTermsOfServiceAgreements } from '../../services/termsOfServiceAgreements';
import {
  idLogin,
  proceedIdPage,
  getAccountIntegrationStatus,
} from '../../services/id';

function mapStateToProps({ auth, register, id }) {
  return {
    auth,
    register,
    id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    async idLogin() {
      await dispatch(idLogin());
    },
    async proceedIdPage(path) {
      await dispatch(proceedIdPage(path));
    },
    setStatus(status) {
      dispatch(setStatus(status));
    },
    setSocialLoginError(error) {
      dispatch(setSocialLoginError(error));
    },
    setUid(id) {
      dispatch(setUid(id));
    },
    setDiscloseId() {
      return dispatch(setDiscloseId());
    },
    getRegisterRequest() {
      return dispatch(getRegisterRequest());
    },
    getAuthUser(discloseId) {
      return dispatch(getAuthUser(discloseId));
    },
    setUserStatus() {
      return dispatch(setUserStatus());
    },
    setPreRegister() {
      return dispatch(setPreRegister());
    },
    getPrivacyPolicyAgreements() {
      return dispatch(getPrivacyPolicyAgreements());
    },
    getTermsOfServiceAgreements() {
      return dispatch(getTermsOfServiceAgreements());
    },
    checkAuthenticationInduction(userStatus) {
      return dispatch(checkAuthenticationInduction(userStatus));
    },
    resetDoctorAuthenticationInductionModal() {
      return dispatch(resetDoctorAuthenticationInductionModal());
    },
    getAccountIntegrationStatus() {
      return dispatch(getAccountIntegrationStatus());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

import * as c from '../constants/register';
import { RESET_ALL_STORE } from '../constants/user';
import { STATUS } from '../constants/status';

const initialState = {
  /*
   * 0: ログイン画面へ
   * 1: メール受信確認画面へ
   * 2: 基本情報入力画面へ
   * 3: 医師免許提出画面へ
   * 4: 審査中画面へ
   * 5: 審査無効画面へ
   * 6: 一覧画面へ
   */
  status: null,
  isPostingRegisterRequest: false, // 登録要求情報の登録リクエスト中かどうか
  isPostingLicenseInfo: false, // ライセンス情報の登録リクエスト中かどうか
  isEditingLicenseInfo: false, // ライセンス情報の更新リクエスト中かどうか
  isEditingRegisterRequestInfo: false, // 登録要求情報の更新リクエスト中かどうか
  genderCode: 'male', // 性別
  firstName: '', // 名
  lastName: '', // 姓
  oldLastName: '', // 旧姓
  profilePhotoUrl: '', // プロフィール画像のURL
  licensedYear: '', // ライセンスを取得した年
  departmentCode: '', // 診療科Id
  otherDepartment: null, // その他診療科
  universityId: '', // 大学Id
  twitterAccount: null, // Twitterユーザー名
  hospitalName: null, // 病院名
  hospitalId: null, // 病院（SCUELに紐づいた病院ID）
  trainingHospitalName: null, // 初期研修病院名
  trainingHospitalId: null, // 初期研修病院（SCUELに紐づいた病院ID）
  prefectureId: 0, // 勤務地(都道府県ID)
  files: null, // 選択中のファイル
  licenseImage: null, // ライセンス画像
  licenseSecondImage: null, // ライセンス画像(2枚目)
  licenseNumber: '', // ライセンス番号
  birthAt: '', //生年月日
  registrationDay: '', // 登録日
  licenseInfo: null, // ReadOnlyライセンス情報画面で使用
  requestInfo: null, // 登録要求内容
  editRequestInfo: null, // 登録要求編集画面で使用
  editLicenseInfo: null, // ライセンス編集画面で使用
  errors: [], // エラー配列
  error: null, // エラー文字列
};

export default function register(state = initialState, action) {
  switch (action.type) {
    // 登録状況ステータスを保存する
    case c.SET_STATUS:
      return {
        ...state,
        status: action.payload.status,
      };

    // 登録フォームに入力した項目を保存する
    case c.INPUT_REGISTER_FORM:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    // 登録要求情報の投稿リクエスト開始
    case c.REQUEST_POST_REGISTER_REQUEST_INFO:
      return {
        ...state,
        isPostingRegisterRequest: true,
      };

    // 登録要求情報の投稿バリデーション
    case c.VALIDATE_POST_REGISTER_REQUEST_INFO:
      if (action.payload.errors.length > 0) {
        return {
          ...state,
          errors: action.payload.errors,
          error: null,
          isPostingRegisterRequest: false,
        };
      }
      return {
        ...state,
        errors: [],
        isPostingRegisterRequest: true,
      };

    // 登録要求情報の投稿リクエスト完了
    case c.POST_REGISTER_REQUEST_INFO:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
          isPostingRegisterRequest: false,
        };
      }

      return {
        ...state,
        errors: [],
        error: null,
        isPostingRegisterRequest: false,
      };

    // 1枚目のライセンス画像の登録リクエスト開始
    case c.REQUEST_UPLOAD_FIRST_LICENSE_PHOTO:
      return {
        ...state,
        isPostingRegisterRequest: true,
      };

    // 1枚目のライセンス画像の登録リクエスト完了
    case c.UPLOAD_FIRST_LICENSE_PHOTO:
      if (action.error) {
        return {
          ...state,
          errors: [],
          error: action.payload.message,
          isPostingRegisterRequest: false,
        };
      }

      return {
        ...state,
        errors: [],
        error: null,
        isPostingRegisterRequest: true,
      };

    // 2枚目のライセンス画像の登録リクエスト開始
    case c.REQUEST_UPLOAD_SECOND_LICENSE_PHOTO:
      return {
        ...state,
        isPostingRegisterRequest: true,
      };

    // 2枚目のライセンス画像の登録リクエスト完了
    case c.UPLOAD_SECOND_LICENSE_PHOTO:
      if (action.error) {
        return {
          ...state,
          errors: [],
          error: action.payload.message,
          isPostingRegisterRequest: false,
        };
      }

      return {
        ...state,
        status: STATUS.DOCTOR_AUTHENTICATION,
        errors: [],
        error: null,
        isPostingRegisterRequest: false,
      };

    // 登録要求内容の取得
    case c.GET_REGISTER_REQUEST_INFO:
      if (action.error) {
        return {
          ...state,
          error: action.payload.error,
        };
      }
      return {
        ...state,
        requestInfo: action.payload.requestInfo,
        editRequestInfo: action.payload.requestInfo, // 編集用にデータを保管
        errors: [],
        error: null,
      };

    // 編集フォームに入力した項目を保存する
    case c.INPUT_EDIT_REGISTER_REQUEST_INFO:
      return {
        ...state,
        editRequestInfo: {
          ...state.editRequestInfo,
          [action.payload.key]: action.payload.value,
        },
      };

    // 登録要求情報の更新バリデーション
    case c.VALIDATE_EDIT_REGISTER_REQUEST_INFO:
      if (action.payload.errors.length > 0) {
        return {
          ...state,
          errors: action.payload.errors,
          error: null,
          isEditingRegisterRequestInfo: false,
        };
      }
      return {
        ...state,
        errors: [],
        isEditingRegisterRequestInfo: true,
      };

    // 登録要求情報の更新リクエスト開始
    case c.REQUEST_EDIT_REGISTER_REQUEST_INFO:
      return {
        ...state,
        isEditingRegisterRequestInfo: true,
      };

    // 登録要求情報の更新リクエスト完了
    case c.EDIT_REGISTER_REQUEST_INFO:
      if (action.error) {
        return {
          ...state,
          errors: [],
          error: action.payload.message,
          isEditingRegisterRequestInfo: false,
        };
      }
      return {
        ...state,
        errors: [],
        error: null,
        isEditingRegisterRequestInfo: false,
      };

    // すべてリセットする
    case RESET_ALL_STORE:
      return initialState;

    default:
      return state;
  }
}

import * as c from '../constants/search';

const initialState = {
  keyword: '', // 検索キーワード
};

export default function replies(state = initialState, action) {
  switch (action.type) {
    case c.SAVE_KEYWORD:
      return {
        ...state,
        keyword: action.payload.keyword,
      };
    default:
      return state;
  }
}

import * as c from '../constants/privacyPolicyAgreements';

const initialState = {
  agreements: null,
  error: null,
};

export default function privacyPolicyAgreements(state = initialState, action) {
  switch (action.type) {
    // プラポリ同意が必要なバージョンを取得する
    case c.GET_AGREEMENTS:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
        };
      }
      return {
        ...state,
        agreements: action.payload.agreements,
        error: null,
      };
    // プラポリ同意状態を登録を取得する
    case c.POST_AGREEMENTS:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
        };
      }
      return {
        ...state,
        agreements: action.payload.agreements,
        error: null,
      };
    default:
      return state;
  }
}

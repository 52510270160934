import * as c from '../constants/profileUpdate';

export function requestGetNecessityProfileUpdateInduction() {
  return {
    type: c.REQUEST_GET_NECESSITY_PROFILE_UPDATE_INDUCTION,
  };
}

export function successGetNecessityProfileUpdateInduction(data) {
  return {
    type: c.SUCCESS_GET_NECESSITY_PROFILE_UPDATE_INDUCTION,
    payload: {
      isNecessary: data.isNecessary,
      suggestCount: data.suggestCount,
      partialUpdateNecessary: {
        hospital: data.partialUpdateNecessary.hospital,
        department: data.partialUpdateNecessary.department,
      },
    },
  };
}

export function failureGetNecessityProfileUpdateInduction(message) {
  return {
    type: c.FAILURE_GET_NECESSITY_PROFILE_UPDATE_INDUCTION,
    payload: {
      error: message,
    },
  };
}

export function closeProfileUpdateModal() {
  return {
    type: c.CLOSE_PROFILE_UPDATE_MODAL,
  };
}

export function requestHandleUserActionProfileUpdate() {
  return {
    type: c.REQUEST_HANDLE_USER_ACTION_PROFILE_UPDATE,
  };
}

export function successHandleUserActionProfileUpdate() {
  return {
    type: c.SUCCESS_HANDLE_USER_ACTION_PROFILE_UPDATE,
  };
}

export function failureHandleUserActionProfileUpdate(message) {
  return {
    type: c.FAILURE_HANDLE_USER_ACTION_PROFILE_UPDATE,
    payload: {
      error: message,
    },
  };
}

export function closeProfileHospitalUpdateModal() {
  return {
    type: c.CLOSE_PROFILE_HOSPITAL_UPDATE_MODAL,
  };
}

export function requestHandleProfileHospitalUpdate() {
  return {
    type: c.REQUEST_HANDLE_PROFILE_HOSPITAL_UPDATE,
  };
}

export function successHandleProfileHospitalUpdate() {
  return {
    type: c.SUCCESS_HANDLE_PROFILE_HOSPITAL_UPDATE,
  };
}

export function failureHandleProfileHospitalUpdate(message) {
  return {
    type: c.FAILURE_HANDLE_PROFILE_HOSPITAL_UPDATE,
    payload: {
      error: message,
    },
  };
}

export function closeProfileDepartmentUpdateModal() {
  return {
    type: c.CLOSE_PROFILE_DEPARTMENT_UPDATE_MODAL,
  };
}

export function requestHandleProfileDepartmentUpdate() {
  return {
    type: c.REQUEST_HANDLE_PROFILE_DEPARTMENT_UPDATE,
  };
}

export function successHandleProfileDepartmentUpdate() {
  return {
    type: c.SUCCESS_HANDLE_PROFILE_DEPARTMENT_UPDATE,
  };
}

export function failureHandleProfileDepartmentUpdate(message, errors = []) {
  return {
    type: c.FAILURE_HANDLE_PROFILE_DEPARTMENT_UPDATE,
    payload: {
      error: message,
      errors,
    },
  };
}

import * as c from '../constants/user';

const initialState = {
  isPuttingUser: false, // ユーザー情報の変更リクエスト中かどうか
  isGettingUser: false, // ユーザー情報を取得中であるかどうか
  user: null, // ユーザー情報オブジェクト
  editUser: null, // 編集中のユーザー情報オブジェクト
  error: null, // API用
  errors: [], // 編集時の入力バリデーション用
  files: null, // ファイル
  putSuccess: false, // 編集成功フラグ
};

export default function user(state = initialState, action) {
  switch (action.type) {
    // ユーザ情報の取得を開始する
    case c.REQUEST_GET_USER:
      return {
        ...state,
        isGettingUser: true,
      };

    // ユーザー情報を取得する
    case c.GET_USER:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
          isGettingUser: false,
        };
      }
      return {
        ...state,
        user: action.payload.user,
        editUser: action.payload.user,
        error: null,
        isGettingUser: false,
      };

    // 編集フォームの入力項目を保存する
    case c.INPUT_EDIT_FORM:
      return {
        ...state,
        editUser: {
          ...state.editUser,
          [action.payload.key]: action.payload.value,
        },
      };

    // ファイルを保存する
    case c.INPUT_FILES:
      return {
        ...state,
        files: action.payload.files,
      };

    // ユーザー情報編集リクエストの開始
    case c.REQUEST_PUT_USER:
      return {
        ...state,
        isPuttingUser: true,
      };

    // ユーザー情報編集リクエストの完了
    case c.PUT_USER:
      if (action.error) {
        return {
          ...state,
          error: action.payload.error || null,
          errors: action.payload.errors || [],
          isPuttingUser: false,
        };
      }
      return {
        ...state,
        user: state.editUser,
        error: null,
        errors: [],
        putSuccess: true,
        isPuttingUser: false,
      };

    // ユーザー情報をリセットする
    // すべてリセットする
    case c.RESET_USER:
    case c.RESET_ALL_STORE:
      return initialState;

    // ユーザーをフォローする
    case c.FOLLOW_USER:
      return {
        ...state,
        user: {
          ...state.user,
          isFollowed: true,
          followerCount: state.user.followerCount + 1,
        },
      };

    // ユーザーをフォロー解除する
    case c.UNFOLLOW_USER:
      return {
        ...state,
        user: {
          ...state.user,
          isFollowed: false,
          followerCount: Math.max(state.user.followerCount - 1, 0),
        },
      };

    default:
      return state;
  }
}

import React from 'react';
import styles from './notFound.module.css';

/**
 * 404ページ
 */
function NotFound() {
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.text}>ページが見つかりません</h1>
    </div>
  );
}

export default NotFound;

import * as c from '../constants/auth';

/**
 * Socialログインからリダイレクトで戻ってきた時のエラーを格納
 * @param {Error} error - エラー
 */
export function setSocialLoginError(error) {
  return {
    type: c.SET_SOCIAL_LOGIN_ERROR,
    payload: {
      socialLoginError: error,
    },
  };
}

/**
 * UIDの設定を通知
 * @param {string} id - uid
 * @return {object} Actionオブジェクト
 */
export function setUid(id) {
  return {
    type: c.SET_UID,
    payload: {
      id,
    },
  };
}

/**
 * 仮ユーザー作成の成功を通知
 * @param {string} discloseId - 外部向けユーザーID
 * @returns {{payload: {discloseId: *}, type: *}}
 */
export function successSetPreRegister(discloseId) {
  return {
    type: c.SET_PRE_REGISTER,
    payload: {
      discloseId,
    },
  };
}

/**
 * 仮ユーザー作成の失敗を通知
 * @param {string} message - エラーメッセージ
 * @returns {{payload: {message: *}, type: *, error: boolean}}
 */
export function failureSetPreRegister(message) {
  return {
    type: c.SET_PRE_REGISTER,
    payload: {
      message,
    },
    error: true,
  };
}

/**
 * 外部向けユーザーIDの設定を通知
 * @param discloseId - 外部向けユーザーID
 * @returns {{payload: {discloseId: *}, type: string}}
 */
export function successSetDiscloseId(discloseId) {
  return {
    type: c.SET_DISCLOSE_ID,
    payload: {
      discloseId,
    },
  };
}

/**
 * 認証ユーザー情報の取得の成功を通知
 * @param {object} user - ログインユーザーオブジェクト
 * @returns {{payload: {user: *}, type: string}}
 */
export function successGetAuthUser(user) {
  return {
    type: c.GET_AUTH_USER,
    payload: {
      user,
    },
  };
}

/**
 * 認証ユーザーのプロファイル画像取得の成功を通知
 * @param profilePhotoBlob
 * @returns {{payload: {profilePhotoBlob: *}, type: string}}
 */
export function successGetAuthUserPhoto(profilePhotoBlob) {
  return {
    type: c.GET_AUTH_USER_PHOTO,
    payload: {
      profilePhotoBlob,
    },
  };
}

/**
 * 認証ユーザーの権限取得成功を通知
 * @param {array} permissions - パーミッション情報
 * @returns {{payload: {permissions: *}, type: string}}
 */
export function successGetPermissions(permissions) {
  return {
    type: c.GET_PERMISSIONS,
    payload: {
      permissions,
    },
  };
}

/**
 * 認証ユーザーの権限取得失敗を通知
 * @param {string} message - エラーメッセージ
 * @returns {{payload: {message: *}, type: string, error: boolean}}
 */
export function failureGetPermissions(message) {
  return {
    type: c.GET_PERMISSIONS,
    payload: {
      message,
    },
    error: true,
  };
}

export function successSendUpdateEmailVerificationMail() {
  return {
    type: c.SUCCESS_SEND_UPDATE_EMAIL_VERIFICATION_MAIL,
  };
}

export function failureSendUpdateEmailVerificationMail(message) {
  return {
    type: c.FAILURE_SEND_UPDATE_EMAIL_VERIFICATION_MAIL,
    payload: {
      message,
    },
    error: true,
  };
}

export function failureEmailVerificationMailUpdateTooManyRequests() {
  return {
    type: c.FAILURE_EMAIL_VERIFICATION_MAIL_UPDATE_TOO_MANY_REQUESTS,
  };
}

export function successSendVerificationMail() {
  return {
    type: c.SUCCESS_SEND_VERIFICATION_MAIL,
  };
}

export function failureSendVerificationMail(message) {
  return {
    type: c.FAILURE_SEND_VERIFICATION_MAIL,
    payload: {
      message,
    },
    error: true,
  };
}

export function failureEmailVerificationTooManyRequests() {
  return {
    type: c.FAILURE_VERIFICATION_MAIL_TOO_MANY_REQUESTS,
  };
}

/**
 * 全てのStore情報をリセットする
 *
 * @return {object} Actionオブジェクト
 */
export function resetAllStore() {
  return {
    type: c.RESET_ALL_STORE,
  };
}

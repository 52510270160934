import React from 'react';
import styles from './fieldset.module.css';

/**
 * 入力フォームのフィールドセット
 *
 * @reactProps {string} legend - 項目名
 * @reactProps {object} children - 内側に表示するコンポーネント
 * @reactProps {boolean} option - trueの場合は必須項目
 * @reactProps {string} alignItems - 縦方向の揃え方
 * @reactProps {string} secondLegend - legend項目名下に追加する項目名
 * @reactProps {string} direction - フィールドセット内子コンポーネントの整列方向
 */
function Fieldset({
  legend,
  secondLegend = false,
  children,
  option = true,
  alignItems = 'center',
  direction = 'row',
}) {
  let containerClass;
  let legendClass;
  if (direction === 'column') {
    containerClass = styles.fieldsetCol;
    legendClass = styles.legend_col;
    alignItems = 'flex-start';
  } else {
    containerClass = styles.fieldsetRow;
    legendClass = styles.legend_row;
  }

  return (
    <>
      <dl className={containerClass} style={{ alignItems }}>
        <dt className={legendClass}>
          <div className={styles.flex}>
            <span>{legend}</span>
            {option === false && <span className={styles.required}>*必須</span>}
          </div>
          {secondLegend && (
            <dt className={styles.legend}>
              <div className={styles.flex}>
                <span>{secondLegend}</span>
              </div>
            </dt>
          )}
        </dt>
        <dd className={styles.content}>{children}</dd>
      </dl>
    </>
  );
}

export default Fieldset;

export const ID_LOGIN = 'ID_LOGIN';
export const REQUEST_GET_ACCOUNT_INTEGRATION_STATUS =
  'REQUEST_GET_ACCOUNT_INTEGRATION_STATUS';
export const SUCCESS_GET_ACCOUNT_INTEGRATION_STATUS =
  'SUCCESS_GET_ACCOUNT_INTEGRATION_STATUS';
export const FAILURE_GET_ACCOUNT_INTEGRATION_STATUS =
  'FAILURE_GET_ACCOUNT_INTEGRATION_STATUS';

export const CLOSE_ACCOUNT_INTEGRATION_INVITATION_MODAL =
  'CLOSE_ACCOUNT_INTEGRATION_INVITATION_MODAL';
export const CLOSE_DOCTOR_AUTHENTICATION_INDUCTION_MODAL =
  'CLOSE_DOCTOR_AUTHENTICATION_INDUCTION_MODAL';
export const RESET_DOCTOR_AUTHENTICATION_INDUCTION_MODAL =
  'RESET_DOCTOR_AUTHENTICATION_INDUCTION_MODAL';
export const CHECK_AUTHENTICATION_INDUCTION = 'CHECK_AUTHENTICATION_INDUCTION';

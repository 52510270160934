import React from 'react';
import styles from './errorScreen.module.css';
import PrimaryButton from '../Form/PrimaryButton';
import { sendContactMail } from '../../services/mail';
import MessageBoard from '../MessageBoard';

class ErrorScreen extends React.Component {
  render() {
    return (
      <div>
        <MessageBoard
          alignLeft="true"
          text={
            '以下をお試し頂き、解消しない場合はAntaa運営事務局までお問い合わせください。<br/><br/>* ページをリロードしてください<br/>* 一旦ログアウトした後、再度ログインしてください'
          }
        />
        <div className={styles.btn}>
          <PrimaryButton onClick={sendContactMail} value="お問い合わせ" />
        </div>
      </div>
    );
  }
}

export default ErrorScreen;

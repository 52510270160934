export const OPEN_POST_MODAL = 'OPEN_POST_MODAL';
export const CLOSE_POST_MODAL = 'CLOSE_POST_MODAL';
export const OPEN_EDIT_POST_MODAL = 'OPEN_EDIT_POST_MODAL';
export const CLOSE_EDIT_POST_MODAL = 'CLOSE_EDIT_POST_MODAL';
export const OPEN_DEPARTMENT_MODAL = 'OPEN_DEPARTMENT_MODAL';
export const CLOSE_DEPARTMENT_MODAL = 'CLOSE_DEPARTMENT_MODAL';
export const OPEN_EDIT_DEPARTMENT_MODAL = 'OPEN_EDIT_DEPARTMENT_MODAL';
export const CLOSE_EDIT_DEPARTMENT_MODAL = 'CLOSE_EDIT_DEPARTMENT_MODAL';
export const OPEN_NOTIFY_MODAL = 'OPEN_NOTIFY_MODAL';
export const CLOSE_NOTIFY_MODAL = 'CLOSE_NOTIFY_MODAL';
export const OPEN_MANAGER_NOTIFY_MODAL = 'OPEN_MANAGER_NOTIFY_MODAL';
export const CLOSE_MANAGER_NOTIFY_MODAL = 'CLOSE_MANAGER_NOTIFY_MODAL';
export const OPEN_MENU_MODAL = 'OPEN_MENU_MODAL';
export const CLOSE_MENU_MODAL = 'CLOSE_MENU_MODAL';
export const OPEN_MANAGE_GROUP_MENU_MODAL = 'OPEN_MANAGE_GROUP_MENU_MODAL';
export const CLOSE_MANAGE_GROUP_MENU_MODAL = 'CLOSE_MANAGE_GROUP_MENU_MODAL';
export const OPEN_MESSAGE_FORM = 'OPEN_MESSAGE_FORM';
export const CLOSE_MESSAGE_FORM = 'CLOSE_MESSAGE_FORM';
export const OPEN_REPLY_FORM = 'OPEN_REPLY_FORM';
export const CLOSE_REPLY_FORM = 'CLOSE_REPLY_FORM';
export const OPEN_DETAIL_MODAL = 'OPEN_DETAIL_MODAL';
export const CLOSE_DETAIL_MODAL = 'CLOSE_DETAIL_MODAL';
export const OPEN_CONFIRM_MODAL = 'OPEN_CONFIRM_MODAL';
export const CLOSE_CONFIRM_MODAL = 'CLOSE_CONFIRM_MODAL';
export const SHOW_DRAFT_LIST = 'SHOW_DRAFT_LIST';
export const CLOSE_DRAFT_LIST = 'CLOSE_DRAFT_LIST';
export const OPEN_POLICY_MODAL = 'OPEN_POLICY_MODAL';
export const CLOSE_POLICY_MODAL = 'CLOSE_POLICY_MODAL';
export const OPEN_TERMS_MODAL = 'OPEN_TERMS_MODAL';
export const CLOSE_TERMS_MODAL = 'CLOSE_TERMS_MODAL';

import { idBaseURL, isSuccess, option, fetchData } from '../utils/fetch';
import * as actions from '../actions/id';

/**
 * アカウント統合状態の取得
 */
export const getAccountIntegrationStatus = () => async (dispatch) => {
  dispatch(actions.requestGetAccountIntegrationStatus());
  const op = await option.json();
  return await fetchData(idBaseURL() + '/account-integration/status', op)
    .then((response) => isSuccess(response))
    .then((json) =>
      dispatch(
        actions.successGetAccountIntegrationStatus(json.integrationStatus),
      ),
    )
    .catch(async (error) => {
      await dispatch(actions.failureGetAccountIntegrationStatus(error.message));
      throw error;
    });
};

/**
 * アカウント統合済み判定
 */
export const isAccountIntegrated = (accountIntegrationStatus) => {
  return (
    !!accountIntegrationStatus &&
    (accountIntegrationStatus === 'done' ||
      accountIntegrationStatus === 'not_required')
  );
};

/**
 * ID基盤ログイン
 */
export const idLogin = (discloseId) => async (dispatch) => {
  const op = await option.json('POST');
  op.credentials = 'include';
  if (discloseId) {
    op.body = JSON.stringify({ discloseId });
  }
  return await fetchData(idBaseURL() + '/login', op)
    .then((response) => isSuccess(response))
    .then((json) => dispatch(actions.successIdLogin(json)))
    .catch((error) => {
      dispatch(actions.failureIdLogin(error.message));
      throw error;
    });
};

/**
 * ID基盤のWebページへ遷移
 */
export const proceedIdPage = async (path) => {
  const idBaseHost = process.env.REACT_APP_ID_BASE_HOST;

  // ID基盤ログイン（認証済みCookieを更新するため必ず呼ぶ）
  await idLogin();

  // ID基盤の画面へ遷移
  window.location.href = `${idBaseHost}${path}`;
};

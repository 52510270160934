import * as c from '../constants/id';

export function failureIdLogin(message) {
  return {
    type: c.ID_LOGIN,
    payload: {
      message,
    },
    error: true,
  };
}

export function successIdLogin(payload) {
  return {
    type: c.ID_LOGIN,
    payload,
  };
}

export function requestGetAccountIntegrationStatus() {
  return {
    type: c.REQUEST_GET_ACCOUNT_INTEGRATION_STATUS,
  };
}

export function failureGetAccountIntegrationStatus(message) {
  return {
    type: c.FAILURE_GET_ACCOUNT_INTEGRATION_STATUS,
    payload: {
      message,
    },
    error: true,
  };
}

export function successGetAccountIntegrationStatus(integrationStatus) {
  return {
    type: c.SUCCESS_GET_ACCOUNT_INTEGRATION_STATUS,
    payload: {
      integrationStatus,
    },
  };
}

export function closeAccountIntegrationInvitationModal() {
  return {
    type: c.CLOSE_ACCOUNT_INTEGRATION_INVITATION_MODAL,
  };
}

export function closeDoctorAuthenticationInductionModal() {
  return {
    type: c.CLOSE_DOCTOR_AUTHENTICATION_INDUCTION_MODAL,
  };
}

export function resetDoctorAuthenticationInductionModal() {
  return {
    type: c.RESET_DOCTOR_AUTHENTICATION_INDUCTION_MODAL,
  };
}

export function checkAuthenticationInduction(userStatus) {
  return {
    type: c.CHECK_AUTHENTICATION_INDUCTION,
    payload: {
      userStatus,
    },
  };
}

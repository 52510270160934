export const GET_QUESTION = 'GET_QUESTION';
export const REQUEST_GET_QUESTION = 'REQUEST_GET_QUESTION';
export const REQUEST_GET_QUESTION_TEMPLATES = 'REQUEST_GET_QUESTION_TEMPLATES';
export const GET_QUESTION_TEMPLATES = 'GET_QUESTION_TEMPLATES';
export const INPUT_QUESTION_FORM = 'INPUT_QUESTION_FORM';
export const SET_DEFAULT_QUESTION_FORM = 'SET_DEFAULT_QUESTION_FORM';
export const INPUT_EDIT_QUESTION_FORM = 'INPUT_EDIT_QUESTION_FORM';
export const VALIDATE_POST_QUESTION = 'VALIDATE_POST_QUESTION';
export const REQUEST_POST_QUESTION = 'REQUEST_POST_QUESTION';
export const REQUEST_POST_DRAFT_QUESTION = 'REQUEST_POST_DRAFT_QUESTION';
export const POST_QUESTION = 'POST_QUESTION';
export const POST_DRAFT_QUESTION = 'POST_DRAFT_QUESTION';
export const INSERT_QUESTION_FROM_DRAFT = 'INSERT_QUESTION_FROM_DRAFT';
export const VALIDATE_EDIT_QUESTION = 'VALIDATE_EDIT_QUESTION';
export const VALIDATE_EDIT_DRAFT_QUESTION = 'VALIDATE_EDIT_DRAFT_QUESTION';
export const REQUEST_EDIT_QUESTION = 'REQUEST_EDIT_QUESTION';
export const REQUEST_EDIT_DRAFT_QUESTION = 'REQUEST_EDIT_DRAFT_QUESTION';
export const EDIT_QUESTION = 'EDIT_QUESTION';
export const EDIT_DRAFT_QUESTION = 'EDIT_DRAFT_QUESTION';
export const REQUEST_DELETE_QUESTION = 'REQUEST_DELETE_QUESTION';
export const DELETE_QUESTION = 'DELETE_QUESTION';
export const RESET_EDIT_QUESTION = 'RESET_EDIT_QUESTION';
export const RESET_ALL_QUESTION = 'RESET_ALL_QUESTION';

import * as c from '../constants/hospitals';

const initialState = {
  hospitals: [], // 所属医療機関一覧の配列
  totalHospitalNum: null, // 所属医療機関の検索ヒット数
  error: null,
};

export default function hospitals(state = initialState, action) {
  switch (action.type) {
    case c.GET_HOSPITALS:
      return {
        ...state,
        hospitals: action.payload.hospitals,
        totalHospitalNum: action.payload.totalHospitalNum,
      };
    case c.CLEAR_HOSPITALS:
      return {
        ...state,
        hospitals: [],
        totalHospitalNum: null,
      };

    default:
      return state;
  }
}

import { idBaseURL } from '../utils/fetch';

export const logout = async (resetAllStore) => {
  try {
    const response = await fetch(idBaseURL() + '/logout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include', // クッキーを含める
    });
    if (!response.ok) {
      console.error('Failed to logout via API');
    }
  } catch (error) {
    console.error('Error during API logout:', error);
  }
  // ID基盤ログアウトの成否にフロント側は一律ログアウトさせる
  resetAllStore();
  window.location.href = '/';
};

import React, { useEffect } from 'react';
import styles from './profileUpdateModal.module.css';
import Modal from '../Modal';
import PrimaryButton from '../../Form/PrimaryButton';
import { useHistory } from 'react-router-dom';

function ProfileUpdateModalContent({
  children,
  headerContent,
  onProceed,
  onClose,
  isHandlingUserAction,
  userId,
}) {
  const history = useHistory();

  const handleProceed = () => {
    onProceed();
    // Proceed to the Edit Profile Page
    if (userId) {
      history.push(`/profile/${userId}/edit`);
    }
  };

  return (
    <div className={styles.modal}>
      <div className={styles.modalHeading}>
        <h1 className={styles.heading}>{headerContent}</h1>
      </div>
      <hr />
      <div className={styles.modalBody}>
        <div className={styles.content}>{children}</div>
        <div className={styles.actions}>
          <PrimaryButton
            value="今すぐ更新する"
            onClick={handleProceed}
            disabled={isHandlingUserAction}
          />
        </div>
        <div className={styles.closeActions}>
          <button type="link" onClick={onClose} disabled={isHandlingUserAction}>
            後で更新する
          </button>
        </div>
      </div>
    </div>
  );
}

function ProfileUpdateModal(props) {
  const {
    handleModalNecessity,
    profileUpdateModal,
    isFetchingNecessity,
    isHandlingUserAction,
    handleModalActionNow,
    handleModalActionLater,
    handleModalCloseWithoutAction,
    user,
  } = props;

  useEffect(() => {
    handleModalNecessity();
  }, [handleModalNecessity]);

  if (!profileUpdateModal.show || isFetchingNecessity) {
    return null;
  }

  const headerContent = profileUpdateModal.isSkippedBefore
    ? 'プロフィール更新をお忘れではありませんか？'
    : 'プロフィール定期更新のお願い';

  return (
    <Modal close={handleModalCloseWithoutAction} confirm={true}>
      <ProfileUpdateModalContent
        headerContent={headerContent}
        onProceed={handleModalActionNow}
        onClose={handleModalActionLater}
        isHandlingUserAction={isHandlingUserAction}
        userId={user && user.userId ? user.userId : null}
      >
        <p>
          Antaaではご登録いただいたプロフィール情報に基づき、おすすめのスライドやご案内をお届けしています。
        </p>
        <p>「今すぐ更新する」よりご登録内容のご更新をお願いいたします。</p>
        <p className={styles.subContent}>
          ※病院名のサジェスト表示が追加されました。カンタン入力が可能です。
        </p>
      </ProfileUpdateModalContent>
    </Modal>
  );
}
export default ProfileUpdateModal;

import { connect } from 'react-redux';
import LoadingMask from './LoadingMask';

function mapStateToProps({ groups, threads }) {
  return {
    groups,
    threads,
  };
}

export default connect(mapStateToProps)(LoadingMask);

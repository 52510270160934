import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';
import Signin from '../Signin';
import Signup from '../Signup';
import ResetPassword from '../ResetPassword';
import NotFound from '../NotFound';
import Loading from '../Loading';
import LoadingMask from '../LoadingMask';
import { firebase } from '../../firebase';
import styles from './app.module.css';
import { STATUS } from '../../constants/status';
import * as privacyPolicyConst from '../../constants/privacyPolicyAgreements';
import * as termsOfServiceConst from '../../constants/termsOfServiceAgreements';
import { sendAnalyticsPageView } from '../../utils/analytics';
import AsyncContainer from '../../utils/AsyncContainer/AsyncContainer';
import ConfirmModal from '../Modal/ConfirmModal';
import ProfileUpdateModal from '../Modal/ProfileUpdateModal';
import ProfileHospitalUpdateModal from '../Modal/ProfileHospitalUpdateModal';
import ProfileDepartmentUpdateModal from '../Modal/ProfileDepartmentUpdateModal';
import AccountIntegrationInductionModal from '../Modal/AccountIntegrationInductionModal';
import OssLicense from '../OssLicense';
import { User } from '../../constants/status';
import ExitedUserScreen from '../ExitedUserScreen';
import ErrorScreen from '../ErrorScreen';
// TODO: メール確認画面表示をフローの後方に修正時に利用。
// import EmailVerifiedRemindText from '../EmailVerifiedRemindText';
import MobileAppInstallBanner from '../Banner/MobileAppInstallBanner';
import DoctorAuthenticationInductionModal from '../Modal/DoctorAuthenticationInductionModal';
import { isAccountIntegrated } from '../../services/id';

/*
 * スクリプトファイルの分割
 * コンポーネントを描画の際に読みこむ
 */
const Routes = AsyncContainer(() =>
  import('../Routes').then((module) => module.default),
);

const RedirectToTop = ({ location }) => <Redirect to={`/${location.search}`} />;

/**
 * アプリケーションのルートコンポーネント
 * ログインの判定や未ログイン時のルーティングも行う
 *
 * @reactProps {object} auth - store
 * @reactProps {object} register - store
 * @reactProps {function} setDiscloseId - 外部向けIDの設定
 * @reactProps {function} getAuthUser - ログインユーザーの取得
 * @reactProps {function} setStatus - 新規登録の進捗ステータスをStoreにセットする
 * @reactProps {function} setUid - ユーザーのidをStoreにセットする
 */
class App extends React.Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    /*
    // SNSログインがPOPUPになったので不要
    try {
      const result = await firebase.auth().getRedirectResult();
      if (result.credential && result.additionalUserInfo.isNewUser) {
        // 仮ユーザー作成
        await this.props.setPreRegister();
      }
    } catch (error) {
      this.props.setSocialLoginError(error);
    }
    */

    // URLのクエリパラメータを取得
    const queryParams = new URLSearchParams(window.location.search);

    // "with_refresh_status"パラメータの値を取得し、"true"ならばreload
    if (queryParams.get('with_refresh_status') === 'true') {
      queryParams.delete('with_refresh_status');
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.replaceState(null, '', newUrl);
      location.reload();
    }

    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        try {
          await this.props.idLogin();
        } catch (e) {
          console.error(e);
        }
      }
      this.setStatus(user);
    });

    // Service Workerが残存していればunregisterする。
    // 経緯：
    // Service Workerの副作用により古いキャッシュで動作してしまう問題があった。
    // そこでService Workerの利用停止を判断した。
    // しかし、既にWorkerがregister済みの環境があるため、unregisterするコードを残しておく必要が出た。
    if (
      'serviceWorker' in navigator &&
      'getRegistrations' in navigator.serviceWorker
    ) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
    }
  }

  /**
   * ルーティングによりパスが切り替わったら画面最上部にスクロールさせる
   * （そうしないとスクロールが残ったまま画面遷移してしまうため）
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);

      /* アナリティクス情報の送信 */
      sendAnalyticsPageView(this.props.location.pathname);

      // 画面遷移の度に医師認証モーダルの表示判定をリセットする
      this.props.resetDoctorAuthenticationInductionModal();
    }

    // 医師/医学生認証のモーダルを表示するか判定
    // アカウント統合モーダルが表示される場合は、医師認証モーダルを表示しない
    if (
      this.props.id.doctorAuthenticationInductionModal.show === undefined &&
      isAccountIntegrated(this.props.id.accountIntegrationStatus)
    ) {
      // 医師認証モーダル表示判定
      this.props.checkAuthenticationInduction(
        this.props.auth?.user?.userStatus,
      );
    }
  }

  async setStatus(user) {
    const status = await this.checkStatus(user);
    this.props.setUid(user ? user.uid : null);
    this.props.setStatus(status);
    this.props.setUserStatus();
  }

  /**
   * ユーザーの登録状態をチェックする
   *
   */
  async checkStatus(user) {
    if (!user) {
      return STATUS.SIGNIN;
    }

    await this.props.setUserStatus();
    const { userStatus } = this.props.auth.user;
    if (
      userStatus === User.status.BAN ||
      userStatus === User.status.EXIT ||
      userStatus === User.status.EXPIRE
    ) {
      return STATUS.EXITED_USER;
    }

    await this.props.setDiscloseId();
    if (!this.props.auth.discloseId) {
      return STATUS.SIGNIN;
    }

    // アカウント統合状態取得
    await this.props.getAccountIntegrationStatus();

    await this.props.getRegisterRequest();
    const requestInfo = this.props.register.requestInfo;

    if (
      requestInfo === null ||
      requestInfo.userStatus === User.status.CREATED
    ) {
      return STATUS.SIGNUP;
    }

    const termsOfServiceAgreements = await this.props.getTermsOfServiceAgreements();
    if (
      termsOfServiceAgreements.payload.agreements.hasNeedAgreeVersions &&
      termsOfServiceAgreements.payload.agreements.needAgreeVersions.includes(
        termsOfServiceConst.REQUIRED_VERSION,
      )
    ) {
      return STATUS.TERMS_OF_SERVICE;
    }

    const privacyPolicyAgreements = await this.props.getPrivacyPolicyAgreements();
    if (
      privacyPolicyAgreements.payload.agreements.hasNeedAgreeVersions &&
      privacyPolicyAgreements.payload.agreements.needAgreeVersions.includes(
        privacyPolicyConst.REQUIRED_VERSION,
      )
    ) {
      return STATUS.PRIVACY_POLICY;
    }

    if (requestInfo.userStatus === User.status.REVIEWING) {
      if (!isAccountIntegrated(this.props.id.accountIntegrationStatus)) {
        // アカウント統合未完了時はホーム画面へ遷移＆アカウント統合誘導ポップアップを表示する
        return STATUS.REGISTERED;
      }
      return STATUS.DOCTOR_AUTHENTICATION;
    }

    if (requestInfo.userStatus === User.status.INVALID) {
      if (!isAccountIntegrated(this.props.id.accountIntegrationStatus)) {
        // アカウント統合未完了時はホーム画面へ遷移＆アカウント統合誘導ポップアップを表示する
        return STATUS.REGISTERED;
      }
      return STATUS.DOCTOR_AUTHENTICATION;
    }

    if (
      requestInfo.userStatus === User.status.REGISTERED ||
      requestInfo.userStatus === User.status.OK
    ) {
      return STATUS.REGISTERED;
    }
    // システム不具合などでどの条件にも当てはまらない場合を考慮
    return STATUS.ERROR;
  }

  render() {
    const { status } = this.props.register;
    const { doctorAuthenticationInductionModal } = this.props.id;
    const idBaseHost = process.env.REACT_APP_ID_BASE_HOST;

    switch (status) {
      case null:
        return <Loading type="wrap" />;

      case STATUS.SIGNIN: // ログイン画面
        return (
          <div className={styles.wrapper}>
            <Header />
            <Switch>
              <Route exact={true} path="/" component={Signin} />
              <Route exact={true} path="/signup" component={Signup} />
              <Route exact={true} path="/login" component={RedirectToTop} />
              <Route
                exact={true}
                path="/resetPassword"
                component={ResetPassword}
              />
              <Route
                exact={true}
                path="/threadId/:threadId"
                component={Signin}
              />
              <Route exact={true} path="/group/:groupId" component={Signin} />
              <Route
                exact={true}
                path="/group/:groupId/post"
                component={Signin}
              />
              <Route
                exact={true}
                path="/profile/editEmail"
                component={Signin}
              />
              <Route
                exact={true}
                path="/profile/self/edit"
                component={Signin}
              />
              <Route exact={true} path="/profile/:uid" component={Signin} />
              <Route
                exact={true}
                path="/stream/contents/:contentId"
                component={Signin}
              />
              <Route exact={true} path="/stream/contents" component={Signin} />
              <Route exact={true} path="/auth/redirect" component={Signin} />
              <Route exact={true} path="/oss/qa-app" component={OssLicense} />
              <Route component={NotFound} />
            </Switch>
            <Footer />
            <ConfirmModal />
            <MobileAppInstallBanner />
          </div>
        );

      case STATUS.SIGNUP: // アカウント登録エントリポイント
        window.location.href = `${idBaseHost}/signup?from_service=qa`; // アカウント登録は事前のID基盤ログインが不要なためproceedIdPageを使わない
        return null;

      case STATUS.TERMS_OF_SERVICE: //利用規約同意画面
        this.props.proceedIdPage(
          '/users/terms-of-service/agreement?from_service=qa',
        );
        return null;

      case STATUS.PRIVACY_POLICY: //プラポリ同意画面
        this.props.proceedIdPage(
          '/users/privacy-policy/agreement?from_service=qa',
        );
        return null;

      case STATUS.DOCTOR_AUTHENTICATION: // 医師／医学生認証エントリポイント
        this.props.proceedIdPage('/users/doctor/register?from_service=qa');
        return null;

      case STATUS.EXITED_USER: // 退会済み画面へ
        return (
          <div className={styles.wrapper}>
            <Header />
            <ExitedUserScreen />
            <Footer />
            <ConfirmModal />
            <MobileAppInstallBanner />
          </div>
        );

      case STATUS.ERROR: // エラー画面
        return (
          <div className={styles.wrapper}>
            <Header />
            <ErrorScreen />
            <Footer />
            <ConfirmModal />
            <MobileAppInstallBanner />
          </div>
        );

      default:
        // サービス画面へ（STATUS.REGISTERED）
        return (
          <div className={styles.wrapper}>
            <Routes location={this.props.location} />
            <Footer />
            <LoadingMask />
            <ConfirmModal />
            <AccountIntegrationInductionModal />
            {doctorAuthenticationInductionModal.show && (
              <DoctorAuthenticationInductionModal />
            )}
            <ProfileDepartmentUpdateModal />
            <ProfileHospitalUpdateModal />
            <ProfileUpdateModal />
            <MobileAppInstallBanner />
          </div>
        );
    }
  }
}

export default App;

import { combineReducers } from 'redux';
import auth from '../reducers/auth';
import ui from '../reducers/ui';
import register from '../reducers/register';
import user from '../reducers/user';
import question from '../reducers/question';
import threads from '../reducers/threads';
import messages from '../reducers/messages';
import replies from '../reducers/replies';
import pr from '../reducers/pr';
import privacyPolicyAgreements from '../reducers/privacyPolicyAgreements';
import departments from './departments';
import universities from './universities';
import images from './images';
import groups from './groups';
import streamContents from './streamContents';
import streamContentsChat from './streamContentsChat';
import streamContentsList from './streamContentsList';
import draftQuestions from './draftQuestions';
import search from './search';
import hospitals from './hospitals';
import trainingHospitals from './trainingHospitals';
import profileUpdate from './profileUpdate';
import id from './id';

const reducers = combineReducers({
  auth,
  groups,
  ui,
  register,
  user,
  question,
  threads,
  messages,
  replies,
  pr,
  privacyPolicyAgreements,
  departments,
  universities,
  images,
  streamContents,
  streamContentsChat,
  streamContentsList,
  draftQuestions,
  search,
  hospitals,
  trainingHospitals,
  profileUpdate,
  id,
});

export default reducers;

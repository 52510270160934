export const GET_REPLIES = 'GET_REPLIES';
export const GET_REPLIES_WITH_CURSOR = 'GET_REPLIES_WITH_CURSOR';
export const LIKE_REPLY = 'LIKE_REPLY';
export const UNLIKE_REPLY = 'UNLIKE_REPLY';
export const INPUT_REPLY_FORM = 'INPUT_REPLY_FORM';
export const VALIDATE_POST_REPLY = 'VALIDATE_POST_REPLY';
export const REQUEST_POST_REPLY = 'REQUEST_POST_REPLY';
export const POST_REPLY = 'POST_REPLY';
export const VALIDATE_EDIT_REPLY = 'VALIDATE_EDIT_REPLY';
export const EDIT_REPLY = 'EDIT_REPLY';
export const REQUEST_DELETE_REPLY = 'REQUEST_DELETE_REPLY';
export const DELETE_REPLY = 'DELETE_REPLY';

import ProfileUpdateModal from './ProfileUpdateModal';
import { closeProfileUpdateModal } from '../../../actions/profileUpdate';
import {
  getNecessityProfileUpdateInduction,
  handleUserActionProfileUpdate,
} from '../../../services/profileUpdate';
import { connect } from 'react-redux';

function mapStateToProps({ profileUpdate, auth }) {
  return {
    user: auth.user,
    profileUpdateModal: profileUpdate.profileUpdateModal,
    isFetchingNecessity: profileUpdate.isFetchingNecessity,
    isHandlingUserAction: profileUpdate.isHandlingUserAction,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleModalNecessity() {
      dispatch(getNecessityProfileUpdateInduction());
    },
    handleModalCloseWithoutAction() {
      dispatch(closeProfileUpdateModal());
    },
    handleModalActionNow() {
      dispatch(handleUserActionProfileUpdate('now'));
      dispatch(closeProfileUpdateModal());
    },
    handleModalActionLater() {
      dispatch(handleUserActionProfileUpdate('later'));
      dispatch(closeProfileUpdateModal());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileUpdateModal);

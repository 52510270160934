import * as c from '../constants/trainingHospitals';

const initialState = {
  trainingHospitals: [], // 初期研修病院一覧の配列
  totalHospitalNum: null, // 初期研修病院の検索ヒット数
  error: null,
};

export default function trainingHospitals(state = initialState, action) {
  switch (action.type) {
    case c.GET_TRAINING_HOSPITALS:
      return {
        ...state,
        trainingHospitals: action.payload.trainingHospitals,
        totalTrainingHospitalNum: action.payload.totalTrainingHospitalNum,
      };
    case c.CLEAR_TRAINING_HOSPITALS:
      return {
        ...state,
        trainingHospitals: [],
        totalTrainingHospitalNum: null,
      };

    default:
      return state;
  }
}

/**
 * 等値の判定
 *
 * 引数に指定した2つの配列が同じ値を持っているかを判定する。
 *
 * @param {array} a - 確認対象配列
 * @param {array} b - 確認対象配列
 * @returns {boolean} 判定結果(true:等値/false:等値でない)
 */
export const equals = (a, b) => {
  if (!Array.isArray(a)) return false;
  if (!Array.isArray(b)) return false;
  if (a.length !== b.length) return false;
  for (let i = 0, n = a.length; i < n; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

/**
 * 最後の要素を取得
 *
 * 引数に指定した要素の、一番最後の要素を返す。
 * もし、引数が配列でない場合には引数をそのまま返却する。
 *
 * @param {array} items - 取得対象の配列
 * @returns {*} 配列の最後の要素
 */
export const getLast = (items) => {
  if (!Array.isArray(items)) {
    return items;
  } else if (items.length === 0) {
    return items;
  }
  return items.slice(-1)[0];
};

/**
 * 要素の空判定
 *
 * 配列でない要素が引数に渡された場合には false となる。
 *
 * @param {array} items - 判定対象の配列
 * @returns {boolean} 判定結果(true:空/false:空でない)
 */
export const isEmpty = (items) => !Array.isArray(items) || items.length === 0;

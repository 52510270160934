export const GET_MESSAGES = 'GET_MESSAGES';
export const GET_MESSAGES_WITH_CURSOR = 'GET_MESSAGES_WITH_CURSOR';
export const LIKE_MESSAGE = 'LIKE_MESSAGE';
export const UNLIKE_MESSAGE = 'UNLIKE_MESSAGE';
export const INPUT_MESSAGE_FORM = 'INPUT_MESSAGE_FORM';
export const VALIDATE_POST_MESSAGE = 'VALIDATE_POST_MESSAGE';
export const REQUEST_POST_MESSAGE = 'REQUEST_POST_MESSAGE';
export const POST_MESSAGE = 'POST_MESSAGE';
export const VALIDATE_EDIT_MESSAGE = 'VALIDATE_EDIT_MESSAGE';
export const EDIT_MESSAGE = 'EDIT_MESSAGE';
export const RESET_MESSAGES = 'RESET_MESSAGES';
export const REQUEST_DELETE_MESSAGE = 'REQUEST_DELETE_MESSAGE';
export const DELETE_MESSAGE = 'DELETE_MESSAGE';

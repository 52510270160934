export const SET_SOCIAL_LOGIN_ERROR = 'SET_SOCIAL_LOGIN_ERROR';
export const SET_UID = 'SET_UID';
export const SET_PRE_REGISTER = 'SET_PRE_REGISTER';
export const SET_DISCLOSE_ID = 'SET_DISCLOSE_ID';
export const GET_AUTH_USER = 'GET_AUTH_USER';
export const GET_AUTH_USER_PHOTO = 'GET_AUTH_USER_PHOTO';
export const RESET_ALL_STORE = 'RESET_ALL_STORE';
export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const RESET_PERMISSIONS = 'RESET_PERMISSIONS';
export const SUCCESS_SEND_VERIFICATION_MAIL = 'SUCCESS_SEND_VERIFICATION_MAIL';
export const FAILURE_SEND_VERIFICATION_MAIL = 'FAILURE_SEND_VERIFICATION_MAIL';
export const FAILURE_VERIFICATION_MAIL_TOO_MANY_REQUESTS =
  'FAILURE_VERIFICATION_MAIL_TOO_MANY_REQUESTS';
export const SUCCESS_SEND_UPDATE_EMAIL_VERIFICATION_MAIL =
  'SUCCESS_SEND_UPDATE_EMAIL_VERIFICATION_MAIL';
export const FAILURE_SEND_UPDATE_EMAIL_VERIFICATION_MAIL =
  'FAILURE_SEND_UPDATE_EMAIL_VERIFICATION_MAIL';
export const FAILURE_EMAIL_VERIFICATION_MAIL_UPDATE_TOO_MANY_REQUESTS =
  'FAILURE_EMAIL_VERIFICATION_MAIL_UPDATE_TOO_MANY_REQUESTS';

import * as c from '../constants/images';

/**
 * 質問画像取得の成功
 *
 * @param {number} questionId - 質問ID
 * @param {string} photoUrl - 画像 api URL 文字列
 * @param {string} blobUrl - blob画像 api URL 文字列
 * @returns {{payload: {urls: {photoUrl: *, blobUrl: *}, questionId: *}, type: string}}
 */
export function successGetQuestionImages(questionId, photoUrl, blobUrl) {
  return {
    type: c.GET_QUESTION_IMAGES,
    payload: {
      questionId,
      urls: {
        photoUrl,
        blobUrl,
      },
    },
  };
}

/**
 * 質問画像の削除
 *
 * 引数に指定した質問IDの画像データを削除する。
 *
 * @param {number} questionId - 質問ID
 * @returns {{payload: {questionId: *}, type: string}}
 */
export function deleteQuestionImage(questionId) {
  return {
    type: c.DELETE_QUESTION_IMAGE,
    payload: {
      questionId,
    },
  };
}

import React from 'react';
import styles from './messageBoard.module.css';

/**
 * メッセージを表示させるための長方形のボード
 *
 * @reactProps {string} text - 表示テキスト
 */
class MessageBoard extends React.Component {
  render() {
    const { text, alignLeft } = this.props;

    return (
      <div className={styles.content}>
        <div
          className={`${styles.message} ${alignLeft ? styles.alignLeft : ''}`}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
    );
  }
}

export default MessageBoard;

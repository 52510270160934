import { connect } from 'react-redux';
import ProfileDepartmentUpdateModal from './ProfileDepartmentUpdateModal';
import { closeProfileDepartmentUpdateModal } from '../../../actions/profileUpdate';
import { getDepartments } from '../../../services/departments';
import { getUser } from '../../../services/user';
import { handleProfileDepartmentUpdate } from '../../../services/profileUpdate';

function mapStateToProps({ profileUpdate, auth, user, departments }) {
  return {
    discloseId: auth.discloseId,
    user: user.user,
    profileDepartmentUpdateModal: profileUpdate.profileDepartmentUpdateModal,
    errors: profileUpdate.departmentUpdateErrors,
    isFetchingNecessity: profileUpdate.isFetchingNecessity,
    isHandlingUserAction: profileUpdate.isHandlingUserAction,
    departments,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUser(id) {
      dispatch(getUser(id));
    },
    getDepartments() {
      dispatch(getDepartments());
    },
    onModalFormSubmit(
      departmentId,
      otherDepartment,
      handleUpdatesOnValidationSuccess,
      handleFetchingUserData,
    ) {
      dispatch(
        handleProfileDepartmentUpdate(
          departmentId,
          otherDepartment,
          handleUpdatesOnValidationSuccess,
          async () => {
            await handleFetchingUserData();
            dispatch(closeProfileDepartmentUpdateModal());
          },
        ),
      );
    },
    handleModalClose() {
      dispatch(closeProfileDepartmentUpdateModal());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileDepartmentUpdateModal);

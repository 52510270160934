import * as c from '../constants/privacyPolicyAgreements';
import { sendAnalyticsEvent } from '../utils/analytics';

/**
 * プラポリ同意が必要なバージョンの取得成功をReducerに通知する
 *
 * @param {object} agreements - 同意状態
 * @return {object} Actionオブジェクト
 */
export function successGetAgreements(agreements) {
  sendAnalyticsEvent('agreements', 'index');
  return {
    type: c.GET_AGREEMENTS,
    payload: {
      agreements: agreements,
    },
  };
}

/**
 * プラポリ同意状態の登録成功をReducerに通知する
 *
 * @param {object} agreements - 同意状態
 * @return {object} Actionオブジェクト
 */
export function successPostAgreements(agreements) {
  return {
    type: c.POST_AGREEMENTS,
    payload: {
      agreements: agreements,
    },
  };
}

/**
 * プラポリ同意が必要なバージョンの取得失敗をReducerに通知する
 *
 * @param {string} message - エラーメッセージ文字列
 * @return {object} Actionオブジェクト
 */
export function failureGetAgreements(message) {
  return {
    type: c.GET_AGREEMENTS,
    payload: {
      message,
    },
    error: true,
  };
}

/**
 * プラポリ同意状態の登録失敗をReducerに通知する
 *
 * @param {string} message - エラーメッセージ文字列
 * @return {object} Actionオブジェクト
 */
export function failurePostAgreements(message) {
  return {
    type: c.POST_AGREEMENTS,
    payload: {
      message,
    },
    error: true,
  };
}

import React from 'react';
import styles from './textfield.module.css';

/**
 * テキストフィールド
 *
 * @reactProps {string} type - input要素のtype、デフォルトは'text'
 * @reactProps {string} placeholder - プレースホルダー
 * @reactProps {string} value - テキストエリアのvalue
 * @reactProps {object} style - スタイル
 * @reactProps {object} className - スタイル（クラス）
 * @reactProps {function} onChange - 変更時に発火する関数
 * @reactProps {function} onKeyPress - キーボードを打鍵時に発火する関数
 * @reactProps {number} max - 最大長
 * @reactProps {number} min - 最小長
 * @reactProps {boolean} disabled - コントロールが無効か否か
 */
function Textfield({
  placeholder,
  type = 'text',
  onChange,
  onKeyPress,
  onFocus,
  style,
  className,
  value = '',
  min,
  max,
  disabled = false,
}) {
  return (
    <input
      type={type}
      className={[styles.input, className].join(' ')}
      placeholder={placeholder}
      onChange={onChange}
      onKeyPress={onKeyPress}
      onFocus={onFocus}
      style={style}
      value={value}
      minLength={min}
      maxLength={max}
      disabled={disabled}
    />
  );
}

export default Textfield;

import * as c from '../constants/ui';
import { sendAnalyticsEvent } from '../utils/analytics';

/**
 * 質問モーダルを開く
 */
export function openPostModal() {
  sendAnalyticsEvent('question', 'input'); // GAへイベントを送信する

  return {
    type: c.OPEN_POST_MODAL,
  };
}

/**
 * 質問モーダルを閉じる
 */
export function closePostModal() {
  return {
    type: c.CLOSE_POST_MODAL,
  };
}

/**
 * 質問詳細モーダルを開く
 *
 * @param {number} questionId - 質問ID
 * @param {object} question - 質問データ
 * @param {object} [options]
 * @param {number} [options.indexInThreads] - 質問一覧内の表示位置（インデックス）
 * @param {number} [options.highlightAnswerId] - 強調表示する回答のID
 * @param {number} [options.highlightReplyId] - 強調表示する返信のID
 */
export function openDetailModal(
  questionId,
  question,
  { indexInThreads, highlightAnswerId, highlightReplyId } = {},
) {
  if (indexInThreads === 0 || indexInThreads) {
    sendAnalyticsEvent('question', 'show', `${indexInThreads}`);
  } else {
    sendAnalyticsEvent('question', 'show', null);
  }

  return {
    type: c.OPEN_DETAIL_MODAL,
    payload: {
      questionId,
      question,
      highlightAnswerId,
      highlightReplyId,
    },
  };
}

/**
 * 質問詳細モーダルを閉じる
 * @returns {{type: string}}
 */
export function closeDetailModal() {
  return {
    type: c.CLOSE_DETAIL_MODAL,
  };
}

/**
 * 質問編集モーダルを開く
 */
export function openEditPostModal(threadId) {
  return {
    type: c.OPEN_EDIT_POST_MODAL,
    payload: {
      threadId,
    },
  };
}

/**
 * 質問編集モーダルを閉じる
 */
export function closeEditPostModal() {
  return {
    type: c.CLOSE_EDIT_POST_MODAL,
  };
}

/**
 * 診療科目選択モーダルを開く
 */
export function openDepartmentModal() {
  return {
    type: c.OPEN_DEPARTMENT_MODAL,
  };
}

/**
 * 診療科目選択モーダルを閉じる
 */
export function closeDepartmentModal() {
  return {
    type: c.CLOSE_DEPARTMENT_MODAL,
  };
}

/**
 * 診療科目編集モーダルを開く
 */
export function openEditDepartmentModal() {
  return {
    type: c.OPEN_EDIT_DEPARTMENT_MODAL,
  };
}

/**
 * 診療科目編集モーダルを閉じる
 */
export function closeEditDepartmentModal() {
  return {
    type: c.CLOSE_EDIT_DEPARTMENT_MODAL,
  };
}

/**
 * メッセージフォームを開く
 */
export function openMessageForm() {
  sendAnalyticsEvent('answer', 'input'); // GAへイベントを送信する

  return {
    type: c.OPEN_MESSAGE_FORM,
  };
}

/**
 * メッセージフォームを閉じる
 */
export function closeMessageForm() {
  return {
    type: c.CLOSE_MESSAGE_FORM,
  };
}

/**
 * リプライフォームを開く
 *
 * @param {string} messageId - メッセージID
 */
export function openReplyForm(messageId) {
  sendAnalyticsEvent('reply', 'input'); // GAへイベントを送信する

  return {
    type: c.OPEN_REPLY_FORM,
    payload: {
      messageId,
    },
  };
}

/**
 * リプライフォームを閉じる
 */
export function closeReplyForm() {
  return {
    type: c.CLOSE_REPLY_FORM,
  };
}

/**
 * お知らせモーダルを開く
 */
export function openNotifyModal() {
  return {
    type: c.OPEN_NOTIFY_MODAL,
  };
}

/**
 * お知らせモーダルを閉じる
 */
export function closeNotifyModal() {
  return {
    type: c.CLOSE_NOTIFY_MODAL,
  };
}

/**
 * 運営からのお知らせのモーダルを開く
 */
export function openManagerNotifyModal() {
  return {
    type: c.OPEN_MANAGER_NOTIFY_MODAL,
  };
}

/**
 * 運営からのお知らせのモーダルを閉じる
 */
export function closeManagerNotifyModal() {
  return {
    type: c.CLOSE_MANAGER_NOTIFY_MODAL,
  };
}

/**
 * レスポンシブ対応時の診療科選択モーダルを開く
 */
export function openMenuModal() {
  return {
    type: c.OPEN_MENU_MODAL,
  };
}

/**
 * レスポンシブ対応時の診療科選択モーダルを閉じる
 */
export function closeMenuModal() {
  return {
    type: c.CLOSE_MENU_MODAL,
  };
}

/**
 * レスポンシブ対応時のグループ管理メニューモーダルを開く
 * @params {string} groupId - グループID
 * @returns {{payload: {groupId: *}, type: *}}
 */
export function openManageGroupMenuModal(groupId) {
  return {
    type: c.OPEN_MANAGE_GROUP_MENU_MODAL,
    payload: {
      groupId,
    },
  };
}

/**
 * レスポンシブ対応時のグループ管理メニューモーダルを閉じる
 * @returns {{type: *}}
 */
export function closeManageGroupMenuModal() {
  return {
    type: c.CLOSE_MANAGE_GROUP_MENU_MODAL,
  };
}

/**
 * 確認モーダルを開く
 *
 * @param {string} message - メッセージ
 * @param {function} onOK - OK押下時の処理
 * @param {string} type - モーダル種類文字列('confirm'|'alert')
 * @param {function} onCancel - キャンセル押下時の処理
 * @returns {{payload: {onCancel: *, message: *, onOK: *}, type: *}}
 */
export function openConfirmModal(
  message,
  onOK,
  okButtonTitle,
  cancelButtonTitle,
  styles,
  type = 'confirm',
  onCancel = undefined,
) {
  return {
    type: c.OPEN_CONFIRM_MODAL,
    payload: {
      message,
      type,
      onOK,
      okButtonTitle,
      cancelButtonTitle,
      styles,
      onCancel,
    },
  };
}

/**
 * 確認モーダルを閉じる
 * @returns {{type: *}}
 */
export function closeConfirmModal() {
  return {
    type: c.CLOSE_CONFIRM_MODAL,
  };
}

/**
 * 下書き一覧を開く
 * @returns {{type: *}}
 */
export function showDraftList() {
  return {
    type: c.SHOW_DRAFT_LIST,
  };
}

/**
 * 下書き一覧を閉じる
 * @returns {{type: *}}
 */
export function closeDraftList() {
  return {
    type: c.CLOSE_DRAFT_LIST,
  };
}

/**
 * プラポリ同意モーダルを開く
 */

export function openPrivacyPolicyModal() {
  return {
    type: c.OPEN_POLICY_MODAL,
  };
}

/**
 * プラポリ同意モーダルを閉じる
 */

export function closePrivacyPolicyModal() {
  return {
    type: c.CLOSE_POLICY_MODAL,
  };
}

/**
 * 利用規約同意モーダルを開く
 */

export function openTermsOfServiceModal() {
  return {
    type: c.OPEN_TERMS_MODAL,
  };
}

/**
 * 利用規約同意モーダルを閉じる
 */

export function closeTermsOfServiceModal() {
  return {
    type: c.CLOSE_TERMS_MODAL,
  };
}

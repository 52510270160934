import { connect } from 'react-redux';
import * as ui from '../../../actions/ui';
import AccountIntegrationInductionModal from './AccountIntegrationInductionModal';
import {
  idLogin,
  proceedIdPage,
  getAccountIntegrationStatus,
} from '../../../services/id';
import { closeAccountIntegrationInvitationModal } from '../../../actions/id';
import { resetAllStore } from '../../../actions/auth';

function mapStateToProps({ auth, id }) {
  return {
    accountIntegrationInductionModal: id.accountIntegrationInductionModal,
    accountIntegrationStatus: id.integrationStatus,
    error: id.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    async idLogin() {
      await dispatch(idLogin());
    },
    async proceedIdPage(path) {
      await dispatch(proceedIdPage(path));
    },
    async getAccountIntegrationStatus() {
      await dispatch(getAccountIntegrationStatus());
    },
    handleModalClose() {
      dispatch(closeAccountIntegrationInvitationModal());
    },
    alert(message) {
      dispatch(ui.openConfirmModal(message, null, '閉じる', '', [], 'alert'));
    },
    resetAllStore() {
      dispatch(resetAllStore());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountIntegrationInductionModal);

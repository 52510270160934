import * as React from 'react';
import { Link } from 'react-router-dom';
import { getMessage } from '../../utils/feedback';
import styles from './signup.module.css';
import { firebase } from '../../firebase';
import { sendAnalyticsEvent } from '../../utils/analytics';

/**
 * 新規登録コンポーネント
 */
class Signup extends React.Component {
  state = {
    isSigning: false,
    email: '',
    password: '',
    errorMessage: '',
  };

  componentDidMount() {
    // ID基盤にリダイレクト
    const idBaseHost = process.env.REACT_APP_ID_BASE_HOST;
    const url = `${idBaseHost}/signup?from_service=qa`;
    window.location.href = url; // アカウント登録は事前のID基盤ログインが不要なためproceedIdPageを使わない
    return;
    /*
    sendAnalyticsEvent('auth', 'show_screen', 'signup');

    if (this.props.auth && this.props.auth.socialLoginError) {
      this.setState({
        errorMessage: getMessage(this.props.auth.socialLoginError.code),
      });
      this.props.setSocialLoginError(null);
    }
    */
  }

  sendEmail(user) {
    user.sendEmailVerification();
  }

  // TODO: 改善後のメール送信/「医師/医学生選択画面」への画面遷移実装
  signup() {
    sendAnalyticsEvent('auth', 'signup', 'email');
    const { email, password } = this.state;
    this.setState({ isSigning: true });
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((res) => {
        this.setState({ isSigning: false });
        this.props.sendVerificationMail();
      })
      .then(async () => {
        /* 仮ユーザー作成 */
        await this.props.setPreRegister();
      })
      .catch((error) => {
        this.setState({
          errorMessage: getMessage(error.code),
          isSigning: false,
        });
      });
  }

  // Facebookで新規登録
  // 新規登録済みの場合はログインがされる
  signupWithFacebook() {
    sendAnalyticsEvent('auth', 'signup', 'facebook');
    const provider = new firebase.auth.FacebookAuthProvider();
    this.setState({ isSigning: true });
    firebase
      .auth()
      .signInWithRedirect(provider)
      .catch((error) => {
        this.setState({
          errorMessage: getMessage(error.code),
          isSigning: false,
        });
      });
  }

  render() {
    const { isSigning } = this.state;

    return (
      <div className="Body">
        <div className={styles.logoPanel}>
          <img src="/images/antaa_logo_small.png" alt="Antaa" />
          <p className={styles.subTitle}>
            例えば当直、深夜の急患。それでも聞ける仲間がいる
          </p>
          <p className={styles.description}>
            現場で活躍する医師に相談できる質問解決プラットフォーム
          </p>
        </div>
        <div className={styles.panel}>
          <p className={styles.text}>医師/医学生限定</p>

          <button
            className={styles.facebookBtn}
            onClick={() => this.signupWithFacebook()}
            disabled={isSigning}
          >
            Facebookで登録
          </button>
          <form>
            <p className={styles.legend}>メールアドレス</p>
            <input
              type="text"
              className={styles.inputText}
              autoComplete="email"
              onKeyPress={(e) => e.key === 'Enter' && this.signup()}
              onChange={(e) => this.setState({ email: e.target.value })}
            />
            <p className={styles.emailHint}>
              非推奨：docomo・au・SoftBank等携帯キャリアのメールアドレス
            </p>
            <p className={styles.emailHint}>
              ※メールの設定によっては事務局からの連絡が届かない場合があります。
            </p>
            <p className={styles.legend}>パスワード</p>
            <input
              type="password"
              className={styles.inputText}
              autoComplete="password"
              onKeyPress={(e) => e.key === 'Enter' && this.signup()}
              onChange={(e) => this.setState({ password: e.target.value })}
            />
            <div className={styles.action}>
              <p className={styles.errorText}>{this.state.errorMessage}</p>
              <button
                type="button"
                className={styles.antaaBtn}
                onClick={() => this.signup()}
                disabled={isSigning}
              >
                メールアドレスで登録
              </button>
            </div>
          </form>
          <div className={styles.subArea}>
            <p className={styles.hint}>アカウント登録済みの方はこちら</p>
            <Link className={styles.link} to="/">
              ログイン
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Signup;

import LogRocket from 'logrocket';

export class LogRocketService {
  static isStarted = false;
  static LOGROCKET_SAMPLING_RATE = 0.05;

  // LogRocketの初期化
  static init() {
    // LOGROCKET_APP_IDがあるかどうか
    if (process.env.REACT_APP_LOGROCKET_ID) {
      // 6分の1の確率でLogRocket有効化
      if (Math.random() < this.LOGROCKET_SAMPLING_RATE) {
        LogRocket.init(process.env.REACT_APP_LOGROCKET_ID, {
          network: {
            // リクエストのサニタイズ
            requestSanitizer: (request) => {
              if (request.headers['Authorization']) {
                request.headers['Authorization'] = null;
              }
              if (request.headers['authorization']) {
                request.headers['authorization'] = null;
              }
              if (request.body) {
                request.body = null;
              }
              return request;
            },
            // レスポンスのサニタイズ
            responseSanitizer: (response) => {
              return null;
            },
          },
          // dom関連の設定
          dom: {
            inputSanitizer: true,
            textSanitizer: true,
          },
        });
        this.isStarted = true;
      }
    }
  }

  // LogRocketへ送信するユーザー名としてuserIdを設定
  static setUser(discloseId) {
    if (this.isStarted) {
      LogRocket.identify(discloseId);
    }
  }
}

import * as c from '../constants/streamContentsChat';

const initialState = {
  messages: null, // 情報オブジェクト
  totalCommentNum: 0,
  error: null, // API用
};

export default function streamContentsChat(state = initialState, action) {
  switch (action.type) {
    // コンテンツ情報を取得する
    case c.GET_STREAM_CONTENT_COMMENTS:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
        };
      }

      return {
        ...state,
        totalCommentNum: action.payload.totalCommentNum,
        messages: action.payload.comments,
        error: null,
      };

    default:
      return state;
  }
}

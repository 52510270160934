import React, { Component } from 'react';
import styles from './profileDepartmentUpdateModal.module.css';
import Modal from '../Modal';
import PrimaryButton from '../../Form/PrimaryButton';
import Select from '../../Form/Select';
import Fieldset from '../../Form/Fieldset';
import Textfield from '../../Form/Textfield';
import { DEPARTMENT_ID_OTHER_DOCTOR } from '../../../constants/departments';
import { sendAnalyticsEvent } from '../../../utils/analytics';
import { isBlank } from '../../../services/profileUpdate';

class ProfileDepartmentUpdateModal extends Component {
  state = {
    departmentId: null,
    otherDepartment: '',
    otherDepartmentTemp: '',
    popupWasShown: false,
  };

  get isDisabled() {
    const { isHandlingUserAction } = this.props;
    const { departmentId, otherDepartment } = this.state;

    return (
      !departmentId ||
      (departmentId === DEPARTMENT_ID_OTHER_DOCTOR &&
        isBlank(otherDepartment)) ||
      isHandlingUserAction
    );
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;

    if (user && prevProps.user !== user) {
      if (user.departmentId) {
        if (user.departmentId === DEPARTMENT_ID_OTHER_DOCTOR) {
          this.updateDepartmentAndOtherDepartment(
            user.departmentId,
            user.otherDepartment,
          );
        } else {
          this.updateDepartmentAndOtherDepartment(user.departmentId, '');
        }
      } else {
        this.updateDepartmentAndOtherDepartment(null, '');
      }
    }
  }

  onChangeDepartment = (value) => {
    this.updateDepartment(value);
    this.updateOtherDepartmentByDepartmentId(value);
  };

  onChangeOtherDepartment = (value) => {
    this.updateOtherDepartment(value);
  };

  onProceedSubmit = async () => {
    const { onModalFormSubmit, discloseId, getUser } = this.props;
    const { departmentId, otherDepartment } = this.state;
    onModalFormSubmit(
      departmentId,
      otherDepartment,
      () => {
        // On Validation Success, send analytics event before firing the api
        sendAnalyticsEvent(
          'profile_department_update_induction',
          'click',
          'update',
        );
      },
      () => {
        // Once Form Submitted, get latest user data
        getUser(discloseId);
      },
    );
  };

  onProceedCancel = () => {
    sendAnalyticsEvent('profile_department_update_induction', 'click', 'close');

    const { handleModalClose } = this.props;
    handleModalClose();
  };

  updateDepartmentAndOtherDepartment = (departmentId, otherDepartment) => {
    this.setState({
      departmentId,
      otherDepartment,
      otherDepartmentTemp: otherDepartment,
    });
  };

  updateDepartment = (value) => {
    this.setState({ departmentId: value });
  };

  updateOtherDepartment = (value) => {
    this.setState({
      otherDepartment: value,
      otherDepartmentTemp: value,
    });
  };

  updateOtherDepartmentByDepartmentId = (value) => {
    const { otherDepartmentTemp } = this.state;

    if (value === DEPARTMENT_ID_OTHER_DOCTOR) {
      this.setState({ otherDepartment: otherDepartmentTemp });
    } else {
      this.setState({ otherDepartment: '' });
    }
  };

  render() {
    const {
      profileDepartmentUpdateModal,
      isFetchingNecessity,
      errors,
    } = this.props;
    const { departments } = this.props.departments;
    const { departmentId, otherDepartment, popupWasShown } = this.state;

    if (
      !profileDepartmentUpdateModal.show ||
      (profileDepartmentUpdateModal.show &&
        profileDepartmentUpdateModal.awaitingHospitalUpdate) ||
      isFetchingNecessity
    ) {
      return null;
    } else if (!popupWasShown) {
      this.setState({ popupWasShown: true });
      sendAnalyticsEvent('profile_department_update_induction', 'show', '');
    }

    return (
      <Modal close={this.onProceedCancel} confirm={true}>
        <div className={styles.modal}>
          <div className={styles.modalHeading}>
            <div className={styles.closeBtn}>
              <img
                src={'/images/btn_close.svg'}
                onClick={this.onProceedCancel}
                alt="閉じる"
              />
            </div>
            <h1 className={styles.heading}>診療科登録のお願い</h1>
          </div>
          <div className={styles.modalBody}>
            <div className={styles.content}>
              <p>
                当アプリは高度な医療情報を提供することを目的
                としたアプリであり、薬機法及びアプリストア規約の観点から、診療科の登録をお願いしております。
              </p>
              <Fieldset legend="診療科" direction="column">
                <Select
                  name="departmentCode"
                  value={departmentId}
                  className={styles.selectDepartment}
                  onChange={(e) =>
                    this.onChangeDepartment(Number(e.target.value))
                  }
                >
                  <option value="">選択してください</option>
                  {departments.map((item, index) => (
                    <option value={item.departmentId} key={index}>
                      {item.departmentNameJp}
                    </option>
                  ))}
                </Select>
                <div className={styles.hint}>
                  <span>
                    ご自分に適した情報が届きます。
                    <br />
                    該当するものが無い場合は「その他」をお選びください。
                  </span>
                </div>
              </Fieldset>

              {departmentId == DEPARTMENT_ID_OTHER_DOCTOR && (
                <div className={styles.otherDepartment}>
                  <Fieldset legend="その他診療科" direction="column">
                    {errors.includes('EMPTY_OTHER_DEPARTMENT') && (
                      <p className={styles.errorText}>
                        「その他診療科」のご入力のお願い：プロフィール「その他診療科」欄のご入力がお済みでないようです。
                      </p>
                    )}
                    {errors.includes('OVER_OTHER_DEPARTMENT') && (
                      <p className={styles.errorText}>
                        255文字以内で入力してください
                      </p>
                    )}
                    <Textfield
                      value={otherDepartment}
                      placeholder="診療科名をこちらに入力してください。"
                      onChange={(e) =>
                        this.onChangeOtherDepartment(e.target.value)
                      }
                    />
                    <div className={styles.hint}>
                      <span>
                        研究医の方等、特定の診療科に従事されていない場合等は「診療科なし」とご記入ください。
                      </span>
                    </div>
                  </Fieldset>
                </div>
              )}
            </div>

            <div className={styles.actions}>
              <PrimaryButton
                style={{
                  minWidth: '200px',
                }}
                value="登録する"
                disabled={this.isDisabled}
                theme={this.isDisabled ? 'disabled' : 'submit'}
                onClick={this.onProceedSubmit}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ProfileDepartmentUpdateModal;

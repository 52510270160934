import * as c from '../constants/messages';
import { RESET_ALL_STORE } from '../constants/user';

const initialState = {
  isPostingMessage: false, // メッセージを投稿中かどうか
  messages: [], // メッセージ配列
  text: '', // 入力中のテキスト
  files: null, // 選択中のファイル
  error: null, // サーバーのエラー
  errors: [], // バリデーションエラー
};

export default function messages(state = initialState, action) {
  switch (action.type) {
    // メッセージを取得する
    case c.GET_MESSAGES:
      if (action.error) {
        return {
          ...state,
          error: action.payload.error,
        };
      }
      return {
        ...state,
        messages: action.payload.messages,
        error: null,
      };

    // 続きのメッセージを取得する
    case c.GET_MESSAGES_WITH_CURSOR:
      if (action.error) {
        return {
          ...state,
          error: action.payload.error,
        };
      }
      return {
        ...state,
        messages: {
          ...state.messages,
          ...action.payload.messages,
        },
        error: null,
      };

    // メッセージフォームに入力された項目を保存する
    case c.INPUT_MESSAGE_FORM:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    // 回答投稿の入力項目を検査する
    case c.VALIDATE_POST_MESSAGE:
      if (action.payload.errors.length > 0) {
        return {
          ...state,
          errors: action.payload.errors,
          error: null,
        };
      }
      return {
        ...state,
        errors: [],
      };

    // メッセージ投稿開始
    case c.REQUEST_POST_MESSAGE:
      return {
        ...state,
        isPostingMessage: true,
      };

    // メッセージ投稿完了
    case c.POST_MESSAGE:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
          isPostingMessage: false,
        };
      }
      return {
        ...state,
        text: '',
        files: null,
        errors: [],
        isPostingMessage: false,
      };

    // 回答削除リクエストの開始
    case c.REQUEST_DELETE_MESSAGE:
      return {
        ...state,
      };

    // 回答削除リクエストの完了
    case c.DELETE_MESSAGE:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
        };
      }
      return {
        ...state,
      };

    // 回答編集の入力項目を検査する
    case c.VALIDATE_EDIT_MESSAGE:
      if (action.payload.errors.length > 0) {
        return {
          ...state,
          messages: addError(state.messages, action.payload),
        };
      }
      return {
        ...state,
      };

    // メッセージ編集完了
    case c.EDIT_MESSAGE:
      return {
        ...state,
        messages: addError(state.messages, action.payload),
      };

    // Stateをリセットする
    case RESET_ALL_STORE:
    case c.RESET_MESSAGES:
      return initialState;
    default:
      return state;
  }
}

/***
 * エラーの追加
 *
 * もし、エラーデータが定義されていない場合には、
 * エラープロパティを空(デフォルト)に設定する。
 *
 * @param {array} messages - 更新対象の回答配列
 * @param {object} payload - ペイロード
 * @returns {array} 新しい回答配列
 */
const addError = (messages, payload) => {
  if (messages === null || messages.length === 0) {
    return messages;
  }
  const update = messages.map((message) => {
    if (message.answerId === payload.answerId) {
      return {
        ...message,
        errors: payload.errors || [],
        error: payload.message || null,
      };
    } else {
      return message;
    }
  });

  return [...update];
};

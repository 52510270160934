import { baseURL, isSuccess, option, fetchData } from '../utils/fetch';
import { excludeImages } from '../utils/files';
import * as actions from '../actions/register';
import moment from 'moment';
import { getYYYY_MM_DD } from '../utils/string';
import { DEPARTMENT_ID_OTHER_DOCTOR_STRING } from '../constants/departments';

const SELECTED_BLANK_VALUE = '0';

/**
 * 登録要求内容の取得
 * @returns {Function}
 */
export const getRegisterRequest = () => async (dispatch) => {
  const op = await option.json();

  return fetchData(baseURL() + 'users/register-request', op)
    .then((response) => isSuccess(response))
    .then((json) => json.registerRequestInformation)
    .then((res) => dispatch(actions.successGetRegisterRequest(res)))
    .catch((error) =>
      dispatch(actions.failureGetRegisterRequest(error.message)),
    );
};

/**
 * 登録要求情報の登録をする（その１）
 * バリデーションを行い、通ったら次の関数に進む
 *
 * @param {string} type - 申請方法
 * @param {object} params - APIに渡すパラメータ
 * @param {array} multidimensionalFiles - 添付ファイルデータ多次元配列
 * @param {function} onValidetionSuccess - バリデーション成功時に呼び出すハンドラ
 */
export const postRegisterRequestInfoFirstHalf = (
  type,
  params,
  multidimensionalFiles,
) => async (dispatch) => {
  if (type === 'image') {
    const filteredFiles = multidimensionalFiles.filter(Boolean);
    const files = filteredFiles.reduce((pre, current) => {
      pre.push(...current);
      return pre;
    }, []);
    dispatch(actions.requestPostRegisterRequestInfo());
    const errors = validateRegisterRequestInfo(type, params, files, 'POST');
    if (errors.length > 0) {
      dispatch(actions.validateErrorPostRegisterRequestInfo(errors));
      return { errors };
    } else {
      return { errors: null };
    }
  } else {
    dispatch(actions.requestPostRegisterRequestInfo());
    const errors = validateRegisterRequestInfo(type, params, '', 'POST');
    if (errors.length > 0) {
      dispatch(actions.validateErrorPostRegisterRequestInfo(errors));
      return { errors };
    } else {
      return { errors: null };
    }
  }
};

/**
 * 登録要求情報を登録する（その２）
 * プロフィール情報を保存した後、画像による申請の場合、ファイルのアップロード処理を開始する。
 *
 * @param {string} type - 申請方法
 * @param {object} params - APIに渡すパラメータ
 * @param {array} files - 添付ファイル
 */
export const postRegisterRequestInfoSecondHalf = (
  type,
  params,
  files,
  method,
) => async (dispatch) => {
  const body = {
    lastName: params.lastName,
    firstName: params.firstName,
    gender: params.genderCode,
    departmentId: params.departmentCode,
    otherDepartment: params.otherDepartment || null,
    twitterAccount: params.twitterAccount || null,
  };
  if (type === 'image') {
    body.universityName = params.universityName;
  } else {
    body.oldLastName = params.oldLastName;
    body.birthAt = getYYYY_MM_DD(params.birthAt);
    body.hospitalName = params.hospitalName;
    body.hospitalId = params.hospitalId;
    body.licenseNumber = params.licenseNumber;
    body.registrationAt = getYYYY_MM_DD(params.registrationAt);
  }
  if (method == 'POST') {
    const op = await option.json('POST', body);
    try {
      const response = await fetchData(
        baseURL() + 'users/register-request',
        op,
      );
      const registerRequest = await isSuccess(response);
      if (type === 'image') {
        const resultOfUploadLicensePhoto = await dispatch(
          uploadLicensePhoto(files),
        );
        if (
          registerRequest.resultInfo.status === 200 &&
          resultOfUploadLicensePhoto
        ) {
          dispatch(actions.successPostRegisterRequest());
          return registerRequest;
        } else {
          throw new Error(
            '登録に失敗しました。再度「登録する」ボタンを押してください。',
          );
        }
      }
      dispatch(actions.successPostRegisterRequest());
      return registerRequest;
    } catch (error) {
      dispatch(actions.failurePostRegisterRequestInfo(error.message));
    }
  } else {
    const op = await option.json('PATCH', body);
    try {
      const response = await fetchData(
        baseURL() + 'users/register-request',
        op,
      );
      const registerRequest = await isSuccess(response);
      if (type === 'image') {
        const resultOfUploadLicensePhoto = await dispatch(
          uploadEditLicensePhoto(files),
        );
        if (
          registerRequest.resultInfo.status === 200 &&
          resultOfUploadLicensePhoto
        ) {
          dispatch(actions.successEditRegisterRequestInfo());
          return registerRequest;
        } else {
          throw new Error(
            '更新に失敗しました。再度「更新する」ボタンを押してください。',
          );
        }
      }
      dispatch(actions.successEditRegisterRequestInfo());
      return registerRequest;
    } catch (error) {
      dispatch(actions.failureEditRegisterRequestInfo(error.message));
    }
  }
};

/**
 * 登録要求情報を登録する（その3）
 * ファイルをアップロードし、DBに保存する。
 *
 * @param {array} files - 添付ファイル
 */
export const uploadLicensePhoto = (files) => async (dispatch) => {
  dispatch(actions.requestUploadFirstLicensePhoto());
  dispatch(actions.requestUploadSecondLicensePhoto());
  const resFirst = await dispatch(setFirstLicensePhoto(files[0]));
  const resSecond = await dispatch(setSecondLicensePhoto(files[1]));
  if (resFirst.error || resSecond.error) {
    return false;
  } else {
    return true;
  }
};

/**
 * 登録要求情報の更新をする（その１）
 * バリデーションを行い、通ったら次の関数に進む
 *
 * @param {string} type - 申請方法
 * @param {object} params - APIに渡すパラメータ
 * @param {array} multidimensionalFiles - 添付ファイルデータ多次元配列
 */
export const editRegisterRequestInfo = (
  type,
  params,
  multidimensionalFiles,
) => async (dispatch) => {
  if (type === 'image') {
    const filteredFiles = multidimensionalFiles.filter(Boolean);
    const files = filteredFiles.reduce((pre, current) => {
      pre.push(...current);
      return pre;
    }, []);
    dispatch(actions.requestEditRegisterRequestInfo());
    const errors = validateRegisterRequestInfo(type, params, files, 'PATCH');
    if (errors.length > 0) {
      dispatch(actions.validateErrorEditRegisterRequestInfo(errors));
      return { registerRequestInfo: null, errors };
    } else {
      const registerRequestInfo = await dispatch(
        postRegisterRequestInfoSecondHalf(
          type,
          params,
          multidimensionalFiles,
          'PATCH',
        ),
      );
      return { registerRequestInfo, errors: null };
    }
  } else {
    dispatch(actions.requestEditRegisterRequestInfo());
    const errors = validateRegisterRequestInfo(type, params);
    if (errors.length > 0) {
      dispatch(actions.validateErrorEditRegisterRequestInfo(errors));
      return { registerRequestInfo: null, errors };
    } else {
      const registerRequestInfo = await dispatch(
        postRegisterRequestInfoSecondHalf(type, params, '', 'PATCH'),
      );
      return { registerRequestInfo, errors: null };
    }
  }
};

/**
 * 空・nullの確認
 *
 * @param {string} str - 確認テキスト
 * @returns {boolean} - 判定結果(true:未入力/false:入力)
 */
function isBlank(str) {
  return !str || str.trim() === '';
}

/**
 * 登録要求情報を検証する
 *
 * @param {string} type - 申請方法
 * @param {object} params - APIに渡すパラメータ
 * @param {array} files - 添付ファイルデータ配列
 * @return {array} errors - エラー内容の配列
 */
function validateRegisterRequestInfo(type, params, files, method) {
  const errors = [];
  const length = 255;
  const size = 10 * 1024 * 1024; // 10MB
  if (isBlank(params.firstName) || params.firstName.length > length) {
    errors.push('INVALID_FIRST_NAME');
  }
  if (isBlank(params.lastName) || params.lastName.length > length) {
    errors.push('INVALID_LAST_NAME');
  }
  if (
    isBlank(params.departmentCode) ||
    params.departmentCode === SELECTED_BLANK_VALUE
  ) {
    errors.push('EMPTY_DEPARTMENT_CODE');
  }
  if (
    params.departmentCode === DEPARTMENT_ID_OTHER_DOCTOR_STRING &&
    isBlank(params.otherDepartment)
  ) {
    errors.push('EMPTY_OTHER_DEPARTMENT');
  }
  if (params.otherDepartment && params.otherDepartment.length > length) {
    errors.push('OVER_OTHER_DEPARTMENT');
  }
  // Twitterユーザー名
  if (params.twitterAccount) {
    if (params.twitterAccount.length < 4 || params.twitterAccount.length > 15) {
      errors.push('INVALID_TWITTER_ACCOUNT_LENGTH');
    }
    if (params.twitterAccount.match(/[^a-zA-Z0-9_]/)) {
      errors.push('INVALID_TWITTER_ACCOUNT_CHAR_TYPE');
    }
  }
  if (type === 'number') {
    if (params.oldLastName !== null && params.oldLastName.length > length) {
      errors.push('OVER_OLD_LAST_NAME');
    }
    if (!params.hospitalName || params.hospitalName.length > length) {
      errors.push('INVALID_HOSPITAL_NAME');
    }
    const re = /^\d*$/; // 0埋めありの連続した整数
    // 生年月日
    if (isBlank(params.birthAt)) {
      errors.push('EMPTY_BIRTH_AT');
    } else if (!re.test(params.birthAt) || params.birthAt.length !== 8) {
      errors.push('INVALID_FORMAT_BIRTH_AT');
    } else if (!moment(getYYYY_MM_DD(params.birthAt)).isValid()) {
      errors.push('INVALID_DATE_BIRTH_AT');
    }
    // 登録番号
    if (!re.test(params.licenseNumber)) {
      errors.push('INVALID_LICENSE_NUMBER_FORMAT');
    } else if (
      !(params.licenseNumber.length === 4 || params.licenseNumber.length === 6)
    ) {
      errors.push('INVALID_LICENSE_NUMBER_DIGITS');
    }
    // 医籍登録年月日
    if (params.registrationAt === '') {
      errors.push('EMPTY_REGISTRATION_AT');
    } else if (
      !re.test(params.registrationAt) ||
      params.registrationAt.length !== 8
    ) {
      errors.push('INVALID_FORMAT_REGISTRATION_AT');
    } else if (!moment(getYYYY_MM_DD(params.registrationAt)).isValid()) {
      errors.push('INVALID_DATE_REGISTRATION_AT');
    }
  } else {
    if (isBlank(params.universityName)) {
      errors.push('EMPTY_UNIVERSITY_NAME');
    }
    if (method == 'POST') {
      if (files.filter(Boolean).length == 0) {
        errors.push('EMPTY_LICENSE_IMAGE');
      } else if (files.filter(Boolean).length < 2) {
        errors.push('SHORTAGE_LICENSE_IMAGE');
      }
    }
    if (excludeImages(files).length > 0) {
      errors.push('INVALID_IMAGE_TYPE');
    } else {
      files.forEach(
        (file) => file.size > size && errors.push('OVER_PROFILE_IMAGE'),
      );
    }
  }
  return errors;
}

/**
 * 画像アップロードでの登録要求情報更新（その3）
 * ファイルをアップロードし、DBに保存する。
 *
 * @param {array} files - 添付ファイル
 */
export const uploadEditLicensePhoto = (files) => async (dispatch) => {
  const filteredfiles = files.filter(Boolean);
  const existedFiles = filteredfiles.reduce((pre, current) => {
    pre.push(...current);
    return pre;
  }, []);

  if (!files[0] && !files[1]) {
    return true;
  } else if (files[0] && files[1]) {
    dispatch(actions.requestUploadFirstLicensePhoto());
    dispatch(actions.requestUploadSecondLicensePhoto());

    const resFirst = await dispatch(setFirstLicensePhoto(existedFiles[0]));
    const resSecond = await dispatch(setSecondLicensePhoto(existedFiles[1]));

    if (resFirst.error || resSecond.error) {
      return false;
    } else {
      return true;
    }
  } else if (files[0] && !files[1]) {
    dispatch(actions.requestUploadFirstLicensePhoto());
    const resFirst = await dispatch(setFirstLicensePhoto(existedFiles[0]));

    if (resFirst.error) {
      return false;
    } else {
      return true;
    }
  } else {
    dispatch(actions.requestUploadSecondLicensePhoto());
    const resSecond = await dispatch(setSecondLicensePhoto(existedFiles[0]));

    if (resSecond.error) {
      return false;
    } else {
      return true;
    }
  }
};

/**
 * 1枚目のライセンス画像のアップロード
 *
 * @param file
 * @returns {Promise}
 */
const setFirstLicensePhoto = (file) => async (dispatch) => {
  const resultOfSetFirstLicensePhoto = await dispatch(setLicensePhoto(file, 1));
  return resultOfSetFirstLicensePhoto;
};

/**
 * 2枚目のライセンス画像のアップロード
 *
 * @param file
 * @returns {Promise}
 */
const setSecondLicensePhoto = (file) => async (dispatch) => {
  const resultOfSetSecondLicensePhoto = await dispatch(
    setLicensePhoto(file, 2),
  );
  return resultOfSetSecondLicensePhoto;
};

/**
 * ライセンス画像のアップロード処理
 *
 * @param file
 * @returns {Promise}
 */
const setLicensePhoto = (file, index) => async (dispatch) => {
  const formData = new FormData();
  formData.append('photo', file);
  const op = await option.multipart(formData, 'PUT');
  if (index == 1) {
    return fetchData(baseURL() + 'users/license/photo/1', op)
      .then((response) => isSuccess(response))
      .then((json) => json.licensePhotoUrl)
      .then(() => dispatch(actions.successUploadFirstLicensePhoto()))
      .catch((error) =>
        dispatch(actions.failureUploadFirstLicensePhoto(error.message)),
      );
  } else {
    return fetchData(baseURL() + 'users/license/photo/2', op)
      .then((response) => isSuccess(response))
      .then((json) => json.licensePhotoUrl)
      .then(() => dispatch(actions.successUploadSecondLicensePhoto()))
      .catch((error) =>
        dispatch(actions.failureUploadSecondLicensePhoto(error.message)),
      );
  }
};

import * as Sentry from '@sentry/browser';

/**
 * Sentry設定
 * @see https://docs.sentry.io/workflow/releases/
 * @see https://docs.sentry.io/enriching-error-data/environments/
 */
const browserOptions = {
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_SENTRY_RELEASE,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
};

const browserClient = new Sentry.BrowserClient(browserOptions);

/**
 * Sentry初期化
 */
export const initSentry = () => {
  Sentry.init(browserOptions);
};

/**
 * 例外情報をSentryに送信する
 * @param {Error} e 例外情報
 * @see https://docs.sentry.io/platforms/javascript/advance-settings/
 */
export const captureException = (e) => {
  browserClient.captureException(e);
};

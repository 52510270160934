// Necessity API Constants
export const REQUEST_GET_NECESSITY_PROFILE_UPDATE_INDUCTION =
  'REQUEST_GET_NECESSITY_PROFILE_UPDATE_INDUCTION';
export const SUCCESS_GET_NECESSITY_PROFILE_UPDATE_INDUCTION =
  'SUCCESS_GET_NECESSITY_PROFILE_UPDATE_INDUCTION';
export const FAILURE_GET_NECESSITY_PROFILE_UPDATE_INDUCTION =
  'FAILURE_GET_NECESSITY_PROFILE_UPDATE_INDUCTION';

// Profile Update Guidance API Constants
export const CLOSE_PROFILE_UPDATE_MODAL = 'CLOSE_PROFILE_UPDATE_MODAL';
export const REQUEST_HANDLE_USER_ACTION_PROFILE_UPDATE =
  'REQUEST_HANDLE_USER_ACTION_PROFILE_UPDATE';
export const SUCCESS_HANDLE_USER_ACTION_PROFILE_UPDATE =
  'SUCCESS_HANDLE_USER_ACTION_PROFILE_UPDATE';
export const FAILURE_HANDLE_USER_ACTION_PROFILE_UPDATE =
  'FAILURE_HANDLE_USER_ACTION_PROFILE_UPDATE';

// Profile Hospital Update API Constants
export const CLOSE_PROFILE_HOSPITAL_UPDATE_MODAL =
  'CLOSE_PROFILE_HOSPITAL_UPDATE_MODAL';
export const REQUEST_HANDLE_PROFILE_HOSPITAL_UPDATE =
  'REQUEST_HANDLE_PROFILE_HOSPITAL_UPDATE';
export const SUCCESS_HANDLE_PROFILE_HOSPITAL_UPDATE =
  'SUCCESS_HANDLE_PROFILE_HOSPITAL_UPDATE';
export const FAILURE_HANDLE_PROFILE_HOSPITAL_UPDATE =
  'FAILURE_HANDLE_PROFILE_HOSPITAL_UPDATE';

// Profile Department Update API Constants
export const CLOSE_PROFILE_DEPARTMENT_UPDATE_MODAL =
  'CLOSE_PROFILE_DEPARTMENT_UPDATE_MODAL';
export const REQUEST_HANDLE_PROFILE_DEPARTMENT_UPDATE =
  'REQUEST_HANDLE_PROFILE_DEPARTMENT_UPDATE';
export const SUCCESS_HANDLE_PROFILE_DEPARTMENT_UPDATE =
  'SUCCESS_HANDLE_PROFILE_DEPARTMENT_UPDATE';
export const FAILURE_HANDLE_PROFILE_DEPARTMENT_UPDATE =
  'FAILURE_HANDLE_PROFILE_DEPARTMENT_UPDATE';

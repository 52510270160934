import { mailAddress } from '../constants/mail';
import packageJson from '../../package.json';
const br = '%0D%0A'; // 改行コード

export const sendContactMail = () => {
  const subject = 'お問い合わせ';
  const body = contactMailBody();
  location.href =
    'mailto:' + mailAddress + '?subject=' + subject + '&body=' + body;
};

const contactMailBody = () => {
  let body = `■お問合せ内容
    ※記入例
    ・○○の画面で○○の操作を行ったら、○○という状態となった
    ・○○という投稿の内容が不適切であると思う
    ※ご契約されている携帯のキャリアの設定等により、運営からのメールが正しく届かないことがあります。
    ドメイン「@antaa.jp」からのメールが受信拒否設定されていないことをご確認ください。
    ＝＝＝＝＝ここから下の内容は編集せずに、そのまま送信してください。＝＝＝＝＝
    ユーザーエージェント: ${window.navigator.userAgent}
    アプリバージョン： ${packageJson.version}
    ＝＝＝＝＝ここまで＝＝＝＝＝
    `;
  return body.replace(/\r?\n/g, br);
};

export const sendRegisterRequestMail = () => {
  const subject = '新初期研修医 証明書確認依頼';
  const body = registerRequestMailBody();
  location.href =
    'mailto:' + mailAddress + '?subject=' + subject + '&body=' + body;
};

const registerRequestMailBody = () => {
  let body = `

  ※記入例
  以下を添付の上、ご返信ください。
  ・医師国家試験の合格証書
  ・医籍登録済証明書
  ・所属医療機関発行の書類（職員証、入館カード等）
  `;
  return body.replace(/\r?\n/g, br);
};

import * as c from '../constants/universities';

const initialState = {
  universities: [], // 大学一覧の配列
  universitiesMap: new Map(), // 大学マップ(キー:ID(数値) 値:日本語大学名)
  universitiesNameMap: new Map(), // 大学名マップ(キー: 日本語大学名(string) 値: ID(数値))
  error: null,
};

export default function universities(state = initialState, action) {
  switch (action.type) {
    case c.GET_UNIVERSITIES:
      return {
        ...state,
        universities: action.payload.universities,
        universitiesMap: action.payload.universitiesMap,
        universitiesNameMap: action.payload.universitiesNameMap,
      };

    default:
      return state;
  }
}

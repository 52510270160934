import * as c from '../constants/draftQuestions';

const initialState = {
  isGettingQuestion: false, // 下書き質問の取得リクエスト中かどうか
  isGettingMoreQuestion: false, // 下書き質問の追加取得リクエスト中かどうか
  isDeletingQuestion: false, // 下書き質問の削除リクエスト中かどうか
  totalQuestionNum: 0, // 下書き質問の数
  questions: [], // 下書き質問の配列
  error: null, // エラー文字列
};

export default function draftQuestions(state = initialState, action) {
  switch (action.type) {
    // 下書き質問のリクエストを開始
    case c.REQUEST_GET_DRAFT_QUESTIONS:
      return {
        ...state,
        isGettingQuestion: true,
      };

    // 下書き質問の追加取得リクエスト開始
    case c.REQUEST_GET_MORE_DRAFT_QUESTIONS:
      return {
        ...state,
        isGettingMoreQuestion: true,
      };

    // 下書き質問を取得する
    case c.GET_DRAFT_QUESTIONS:
      return {
        ...state,
        questions: action.payload.questions,
        totalQuestionNum: action.payload.totalQuestionNum,
        isGettingQuestion: false,
        error: null,
      };

    // 下書き質問をさらに取得する
    case c.GET_MORE_DRAFT_QUESTIONS:
      return {
        ...state,
        questions: state.questions.concat(action.payload.questions),
        totalQuestionNum: action.payload.totalQuestionNum,
        isGettingMoreQuestion: false,
        error: null,
      };

    // 下書き質問の取得リクエストの失敗
    case c.FAIL_GET_DRAFT_QUESTIONS:
      return {
        ...state,
        error: action.payload.error,
      };

    // 下書き質問の削除リクエストの開始
    case c.REQUEST_DELETE_DRAFT_QUESTION:
      return {
        ...state,
        isDeletingQuestion: true,
      };

    // 下書き質問削除リクエストの完了
    case c.DELETE_DRAFT_QUESTION: {
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
        };
      }
      const { questionId } = action.payload;
      const totalQuestionNum = state.totalQuestionNum - 1;
      const questions = state.questions.filter(
        (_question) => _question.questionId !== questionId,
      );

      return {
        ...state,
        questions,
        totalQuestionNum,
        isDeletingQuestion: false,
      };
    }

    default:
      return state;
  }
}

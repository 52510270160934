/**
 * Firebaseのエラーコードに対応するエラーメッセージを取得
 *
 * 複数行のエラーメッセージは改行コード（\n）区切り。
 *
 * @param {string} code Firebaseのエラーコード
 * @returns {string} エラーメッセージ
 */
export const getMessage = (code) => {
  switch (code) {
    /* 新規登録 */
    case 'auth/weak-password':
      return 'パスワードは6文字以上で設定してください。';
    case 'auth/email-already-in-use':
      return '既に登録されているメールアドレスです。';
    case 'auth/account-exists-with-different-credential':
      return '異なるログイン方法で登録されているメールアドレスです。';
    /* ログイン */
    case 'auth/user-not-found':
    case 'auth/wrong-password':
      return 'メールアドレスまたはパスワードが正しくありません。\nSlideユーザーの方は新規登録をお願いします。';
    /* ログイン・新規登録共通 */
    case 'auth/invalid-email':
      return 'メールアドレスの形式が間違っています。';
    case 'auth/user-cancelled':
      return 'キャンセルされました。';
    default:
      return '処理に失敗しました。';
  }
};

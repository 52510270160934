export const SET_STATUS = 'SET_STATUS';
export const INPUT_REGISTER_FORM = 'INPUT_REGISTER_FORM';
export const GET_REGISTER_REQUEST_INFO = 'GET_REGISTER_REQUEST_INFO';
export const REQUEST_POST_REGISTER_REQUEST_INFO =
  'REQUEST_POST_REGISTER_REQUEST_INFO';
export const VALIDATE_POST_REGISTER_REQUEST_INFO =
  'VALIDATE_POST_REGISTER_REQUEST_INFO';
export const POST_REGISTER_REQUEST_INFO = 'POST_REGISTER_REQUEST_INFO';
export const REQUEST_UPLOAD_FIRST_LICENSE_PHOTO =
  'REQUEST_UPLOAD_FIRST_LICENSE_PHOTO';
export const REQUEST_UPLOAD_SECOND_LICENSE_PHOTO =
  'REQUEST_UPLOAD_SECOND_LICENSE_PHOTO';
export const UPLOAD_FIRST_LICENSE_PHOTO = 'UPLOAD_FIRST_LICENSE_PHOTO';
export const UPLOAD_SECOND_LICENSE_PHOTO = 'UPLOAD_SECOND_LICENSE_PHOTO';
export const INPUT_EDIT_REGISTER_REQUEST_INFO =
  'INPUT_EDIT_REGISTER_REQUEST_INFO';
export const VALIDATE_EDIT_REGISTER_REQUEST_INFO =
  'VALIDATE_EDIT_REGISTER_REQUEST_INFO';
export const REQUEST_EDIT_REGISTER_REQUEST_INFO =
  'REQUEST_EDIT_REGISTER_REQUEST_INFO';
export const EDIT_REGISTER_REQUEST_INFO = 'EDIT_REGISTER_REQUEST_INFO';

import 'react-app-polyfill/stable';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { initSentry } from './utils/sentry';
import App from './components/App';
import store from './store';
import './styles/reset.css';
import './styles/colors.css';
import './styles/fontsize.css';
import { LogRocketService } from '../src/services/logRocket';

initSentry();

// LogRocketの初期化
LogRocketService.init();

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Route path="/" component={App} />
    </Router>
  </Provider>,
  document.getElementById('root'),
);

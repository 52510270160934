import React, { useState } from 'react';
import styles from './DoctorAuthenticationInductionModal.module.css';
import { sendAnalyticsEvent } from '../../../utils/analytics';
import PrimaryButton from '../../Form/PrimaryButton';
import Modal from '../Modal';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function DoctorAuthenticationInductionModal(props) {
  const [isLoading, setIsLoading] = useState(false);
  const { idLogin, proceedIdPage, handleModalClose } = props;

  const onProceedDoctorAuthentication = async () => {
    sendAnalyticsEvent('doctor_authentication_induction', 'click', 'proceed');

    try {
      setIsLoading(true);

      // ID基盤ログイン
      await idLogin();

      // 医師認証画面を開く
      proceedIdPage('/users/doctor/register?from_service=qa');
    } catch (e) {
      setIsLoading(false);
      console.log(`IDログインに失敗しました; e = ${e}`);
      props.alert('処理に失敗しました。時間をおいて再度お試しください。');
    } finally {
      // ポップアップクローズ／ローディングインジケーターを解除しないまま画面遷移
    }
  };

  const onProceedCancel = () => {
    sendAnalyticsEvent('doctor_authentication_induction', 'click', 'close');
    handleModalClose();
  };

  let occupationLabel;
  switch (props.user?.occupation) {
    case 'doctor':
      occupationLabel = '医師';
      break;

    case 'student':
      occupationLabel = '医学生';
      break;

    default:
      // 医師・医学生以外の職種の場合はダイアログを閉じる
      return null;
  }

  sendAnalyticsEvent('doctor_authentication_induction', 'show', '');

  return (
    <Modal
      close={onProceedCancel}
      confirm={true}
      style={{ borderRadius: '20px' }}
    >
      <div className={styles.modal}>
        <div className={styles.modalHeading}>
          <FontAwesomeIcon
            className={styles.userIcon}
            icon={faUserCircle}
            size="5x"
            color="#999999"
          />
          <h1 className={styles.heading}>{occupationLabel}認証のお願い</h1>
        </div>

        <div className={styles.modalBody}>
          <div className={styles.content}>
            <p>まだ{occupationLabel}認証がされておりません。</p>
            <p>
              {occupationLabel}
              認証をしていただくことで、医師
              {!props.user.isDoctor ? `/${occupationLabel}` : ''}
              限定コンテンツをご利用いただけます。
            </p>
          </div>

          <div className={styles.actions}>
            <PrimaryButton
              style={{
                width: '100%',
                borderRadius: '20px',
                fontSize: '16px',
                fontWeight: 'bold',
              }}
              value={`${occupationLabel}認証を行う`}
              theme={'submit'}
              onClick={onProceedDoctorAuthentication}
              loading={isLoading}
            />
            <div className={styles.closeText} onClick={onProceedCancel}>
              あとで行う
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default DoctorAuthenticationInductionModal;

import * as React from 'react';
import PrimaryButton from '../Form/PrimaryButton';
import { getMessage } from '../../utils/feedback';
import { firebase } from '../../firebase';
import styles from './resetPassword.module.css';

/**
 * パスワードリセット
 */
class ResetPassword extends React.Component {
  state = {
    isSending: false,
    isSent: false,
    email: '',
    errorMessage: '',
  };

  resetPassword() {
    const auth = firebase.auth();
    const { email } = this.state;
    this.setState({
      isSending: true,
    });

    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        this.setState({
          isSending: false,
          isSent: true,
        });
      })
      .catch((error) => {
        this.setState({
          errorMessage: getMessage(error.code),
          isSending: false,
        });
      });
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.resetPassword();
  };

  render() {
    const { isSending, errorMessage, isSent } = this.state;
    return (
      <div className="Body">
        <div className={styles.panel}>
          {isSent ? (
            <div>
              <h1 className={styles.title}>パスワード再設定メールの送信完了</h1>
              <p className={styles.description}>
                パスワードの再設定メールを送信しました。
                メール本文内のURLを押下し、再設定を行ってください。
                <br />
                <br />※
                メールが届かない場合は迷惑メールフォルダもご確認ください。
              </p>
            </div>
          ) : (
            <form onSubmit={this.onSubmit}>
              <h1 className={styles.title}>パスワードの再設定</h1>
              <p className={styles.description}>
                ご入力メールアドレス宛てにパスワードの再設定メールを送信いたします。
              </p>
              <p className={styles.legend}>メールアドレス</p>
              <input
                type="text"
                className={styles.inputText}
                autoComplete="email"
                onChange={(e) => this.setState({ email: e.target.value })}
              />
              <div className={styles.action}>
                <p className={styles.errorText}>{errorMessage}</p>
                <PrimaryButton
                  type="submit"
                  value="パスワードをリセットする"
                  loading={isSending}
                />
              </div>
            </form>
          )}
        </div>
      </div>
    );
  }
}

export default ResetPassword;

import React from 'react';
import styles from './confirmModal.module.css';
import Modal from '../Modal';
import PrimaryButton from '../../Form/PrimaryButton';
import { formatText } from '../../../utils/formatText';

/**
 * 確認モーダル
 *
 * @param {object} confirmModal - 確認モーダルオブジェクト
 * @param {function} onClose - クローズ時の処理
 * @returns {null|*}
 * @constructor
 */
function ConfirmModal({ confirmModal, onClose }) {
  if (!confirmModal.open) {
    return null;
  }

  const onOK = () => {
    confirmModal.onOK && confirmModal.onOK();
    onClose();
  };

  const onCancel = () => {
    confirmModal.onCancel && confirmModal.onCancel();
    onClose();
  };

  const okButtonTitle = confirmModal.okButtonTitle
    ? confirmModal.okButtonTitle
    : 'OK';
  const cancelButtonTitle = confirmModal.cancelButtonTitle
    ? confirmModal.cancelButtonTitle
    : 'キャンセル';
  const cancelStyles =
    confirmModal.styles && confirmModal.styles.cancel
      ? confirmModal.styles.cancel
      : styles.cancel;

  return (
    <Modal close={onClose} confirm={true}>
      <div className={styles.modal}>
        <p>{formatText(confirmModal.message)}</p>
        <div className={styles.actions}>
          {confirmModal.type === 'confirm' && (
            <span className={cancelStyles} onClick={onCancel}>
              {cancelButtonTitle}
            </span>
          )}
          <PrimaryButton value={okButtonTitle} onClick={onOK} />
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmModal;

import { connect } from 'react-redux';
import * as ui from '../../../actions/ui';
import DoctorAuthenticationInductionModal from './DoctorAuthenticationInductionModal';
import { idLogin, proceedIdPage } from '../../../services/id';
import { getUser } from '../../../services/user';
import { closeDoctorAuthenticationInductionModal } from '../../../actions/id';

function mapStateToProps({ id, auth }) {
  return {
    user: auth.user,
    error: id.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    async idLogin() {
      await dispatch(idLogin());
    },
    async proceedIdPage(path) {
      await dispatch(proceedIdPage(path));
    },
    getUser(id) {
      return dispatch(getUser(id));
    },
    handleModalClose() {
      dispatch(closeDoctorAuthenticationInductionModal());
    },
    alert(message) {
      dispatch(ui.openConfirmModal(message, null, '閉じる', '', [], 'alert'));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DoctorAuthenticationInductionModal);

import { idBaseURL, isSuccess, option } from '../utils/fetch';
import {
  failureGetAgreements,
  successGetAgreements,
  successPostAgreements,
} from '../actions/privacyPolicyAgreements';
import * as c from '../constants/privacyPolicyAgreements';

/**
 * プラポリ同意が必要なバージョンを取得する
 */
export const getPrivacyPolicyAgreements = () => async (dispatch) => {
  const op = await option.json();
  return fetch(idBaseURL() + '/users/privacy-policy/agreement/need-agree', op)
    .then((response) => isSuccess(response))
    .then((json) => dispatch(successGetAgreements(json)))
    .catch((error) => dispatch(failureGetAgreements(error.message)));
};

/**
 * プラポリ同意状態を登録する
 */
export const sendAgreement = () => async (dispatch) => {
  const isAgree = true;
  const body = {
    isAgree,
    version: c.REQUIRED_VERSION,
  };
  const op = await option.json('POST', body);
  return fetch(idBaseURL() + '/users/privacy-policy/agreement', op)
    .then((response) => isSuccess(response))
    .then((json) => dispatch(successPostAgreements(json)));
};

import React from 'react';
import styles from './exitedUserScreen.module.css';
import PrimaryButton from '../Form/PrimaryButton';
import { sendContactMail } from '../../services/mail';
import MessageBoard from '../MessageBoard';

class ExitedUserScreen extends React.Component {
  render() {
    return (
      <div>
        <MessageBoard text={'ご利用のアカウントは退会済みです。'} />
        <div className={styles.btn}>
          <PrimaryButton onClick={sendContactMail} value="お問い合わせ" />
        </div>
      </div>
    );
  }
}

export default ExitedUserScreen;

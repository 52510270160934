import * as c from '../constants/id';
import { User } from '../constants/status';
import { isAccountIntegrated } from '../services/id';

const initialState = {
  accountIntegrationInductionModal: {
    show: false,
  },
  accountIntegrationStatus: undefined,
  doctorAuthenticationInductionModal: {
    show: undefined, // undefined: 表示するか判定中、true: 表宇、false: あとで行う（非表示）
  },
  error: null,
};

export default function ui(state = initialState, action) {
  switch (action.type) {
    case c.ID_LOGIN:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
        };
      }
      return {
        ...state,
        error: null,
      };
    case c.REQUEST_GET_ACCOUNT_INTEGRATION_STATUS:
      return {
        ...state,
        accountIntegrationInductionModal: {
          show: false,
        },
        error: null,
      };
    case c.SUCCESS_GET_ACCOUNT_INTEGRATION_STATUS: {
      const { integrationStatus } = action.payload;
      const showAccountIntegrationInductionModal = !isAccountIntegrated(
        integrationStatus,
      );

      return {
        ...state,
        accountIntegrationInductionModal: {
          show: showAccountIntegrationInductionModal,
        },
        accountIntegrationStatus: integrationStatus,
        error: null,
      };
    }
    case c.FAILURE_GET_ACCOUNT_INTEGRATION_STATUS:
      return {
        ...state,
        accountIntegrationInductionModal: {
          show: false,
        },
        error: action.payload.message,
      };

    case c.CLOSE_ACCOUNT_INTEGRATION_INVITATION_MODAL:
      return {
        ...state,
        accountIntegrationInductionModal: {
          show: false,
        },
      };

    case c.CLOSE_DOCTOR_AUTHENTICATION_INDUCTION_MODAL:
      return {
        ...state,
        doctorAuthenticationInductionModal: {
          show: false,
        },
      };

    case c.RESET_DOCTOR_AUTHENTICATION_INDUCTION_MODAL:
      return {
        ...state,
        doctorAuthenticationInductionModal: {
          show: undefined,
        },
      };

    case c.CHECK_AUTHENTICATION_INDUCTION: {
      const { userStatus } = action.payload;
      const showDoctorAuthenticationInductionModal =
        userStatus !== User.status.OK;

      return {
        ...state,
        doctorAuthenticationInductionModal: {
          show: showDoctorAuthenticationInductionModal,
        },
        userStatus: userStatus,
        error: null,
      };
    }

    default:
      return state;
  }
}

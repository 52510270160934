import React from 'react';
import styles from './footer.module.css';
import { isMobile } from 'react-device-detect';
import { STATUS } from '../../constants/status';

/**
 * フッター
 */
class Footer extends React.Component {
  render() {
    if (isMobile && this.props.status && this.props.status > STATUS.SIGNIN) {
      return null;
    }
    return (
      <footer className={styles.wrapper}>
        <div className={styles.wrap}>
          <dl>
            <dd>
              <ul className={styles.lists}>
                <li className={styles.list}>
                  <a
                    href="https://corp.antaa.jp"
                    target="_blank"
                    rel="noopener"
                  >
                    運営会社
                  </a>
                </li>
                <li className={styles.list}>
                  <a
                    href="https://corp.antaa.jp/terms"
                    target="_blank"
                    rel="noopener"
                  >
                    利用規約
                  </a>
                </li>
                <li className={styles.list}>
                  <a
                    href="https://corp.antaa.jp/privacypolicy"
                    target="_blank"
                    rel="noopener"
                  >
                    プライバシーポリシー
                  </a>
                </li>
              </ul>
            </dd>
            <dd className={styles.cr}>
              Copyright &copy;&nbsp;{' '}
              <a href="https://corp.antaa.jp/" target="_blank" rel="noopener">
                アンター株式会社
              </a>
            </dd>
          </dl>
        </div>
      </footer>
    );
  }
}

export default Footer;

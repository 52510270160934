import React from 'react';
import { MobileView, isIOS } from 'react-device-detect';
import styles from './MobileAppInstallBanner.module.css';

/**
 *
 * アプリインストール誘導バナー
 *
 * 押下するとAntaa QAのアプリストア(iOS/Android)のリンクを開く。
 * PCブラウザの利用または横幅が960px以上の場合には表示しない。
 *
 */
function MobileAppInstallBanner() {
  const link = isIOS
    ? 'https://apps.apple.com/jp/app/id1151798574'
    : 'https://play.google.com/store/apps/details?id=jp.antaa.qa.android.AntaaQa';

  return (
    <MobileView renderWithFragment>
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.wrapper}
      >
        <div className={styles.container}>
          <img
            className={styles.appIcon}
            src={'/images/antaa_icon_small.png'}
            alt="アプリアイコン"
          />
          <div className={styles.message}>
            <p className={styles.innerTitle}>公式アプリ</p>
            <p className={styles.innerDescription}>
              疾患・診療メニュー、計算機能はアプリだけ！
            </p>
          </div>
          <div className={styles.getIcon}>
            <a href={link} target="_blank" rel="noopener noreferrer">
              <img src={'/images/icon_get_app_white.png'} alt="アプリの入手" />
            </a>
          </div>
        </div>
      </a>
    </MobileView>
  );
}

export default MobileAppInstallBanner;

import { baseURL, isSuccess, option, fetchData } from '../utils/fetch';
import * as actions from '../actions/profileUpdate';
import { DEPARTMENT_ID_OTHER_DOCTOR } from '../constants/departments';

export function isBlank(str) {
  return !str || str.trim() === '';
}

export const getNecessityProfileUpdateInduction = () => async (dispatch) => {
  dispatch(actions.requestGetNecessityProfileUpdateInduction());
  const op = await option.json();
  return fetchData(baseURL() + 'users/profile/update-induction/necessity', op)
    .then((response) => isSuccess(response))
    .then((data) =>
      dispatch(actions.successGetNecessityProfileUpdateInduction(data)),
    )
    .catch((error) =>
      dispatch(
        actions.failureGetNecessityProfileUpdateInduction(error.message),
      ),
    );
};

export const handleUserActionProfileUpdate = (userAction) => async (
  dispatch,
) => {
  dispatch(actions.requestHandleUserActionProfileUpdate());
  const body = { userAction };
  const op = await option.json('PATCH', body);
  return fetchData(baseURL() + 'users/profile/update-induction/user-action', op)
    .then((response) => isSuccess(response))
    .then(() => dispatch(actions.successHandleUserActionProfileUpdate()))
    .catch((error) =>
      dispatch(actions.failureHandleUserActionProfileUpdate(error.message)),
    );
};

export const handleProfileHospitalUpdate = (
  hospitalId,
  hospitalName,
  onFetchedHospitalUpdate,
) => async (dispatch) => {
  dispatch(actions.requestHandleProfileHospitalUpdate());
  const body = { hospitalId, hospitalName };
  const op = await option.json('PATCH', body);
  await fetchData(baseURL() + 'users/profile/hospital', op)
    .then((response) => isSuccess(response))
    .then(() => dispatch(actions.successHandleProfileHospitalUpdate()))
    .catch((error) =>
      dispatch(actions.failureHandleProfileHospitalUpdate(error.message)),
    );
  await onFetchedHospitalUpdate();
  return;
};

export function validateProfileDepartmentUpdate(params) {
  const errors = [];
  const length = 255;
  if (
    params.departmentId === DEPARTMENT_ID_OTHER_DOCTOR &&
    isBlank(params.otherDepartment)
  ) {
    errors.push('EMPTY_OTHER_DEPARTMENT');
  }
  if (params.otherDepartment && params.otherDepartment.length > length) {
    errors.push('OVER_OTHER_DEPARTMENT');
  }
  return errors;
}

export const handleProfileDepartmentUpdate = (
  departmentId,
  otherDepartment,
  onFetchingDepartmentUpdate,
  onFetchedDepartmentUpdate,
) => async (dispatch) => {
  dispatch(actions.requestHandleProfileDepartmentUpdate());
  const body = { departmentId, otherDepartment };
  const errors = validateProfileDepartmentUpdate(body);
  if (errors.length > 0) {
    dispatch(actions.failureHandleProfileDepartmentUpdate(true, errors));
  } else {
    await onFetchingDepartmentUpdate();
    const payload = body.otherDepartment ? body : { departmentId };
    const op = await option.json('PATCH', payload);
    await fetchData(baseURL() + 'users/profile/department', op)
      .then((response) => isSuccess(response))
      .then(() => dispatch(actions.successHandleProfileDepartmentUpdate()))
      .catch((error) =>
        dispatch(actions.failureHandleProfileDepartmentUpdate(error.message)),
      );
    await onFetchedDepartmentUpdate();
  }
  return;
};

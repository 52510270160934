import { firebase } from '../firebase';

/**
 * ユーザーIDトークンの取得
 * @returns {Promise<string>}
 */
export const getUserIdToken = () => {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        resolve(user.getIdToken());
      }
      reject();
    });
  });
};

/* 特定の質問取得 */
export const GET_THREAD = 'GET_THREAD';

/* 質問(グループ)一覧取得 */
export const REQUEST_GET_GROUP_THREADS = 'REQUEST_GET_GROUP_THREADS';
export const GET_GROUP_THREADS = 'GET_GROUP_THREADS';
export const REQUEST_GET_GROUP_THREADS_WITH_CURSOR =
  'REQUEST_GET_GROUP_THREADS_WITH_CURSOR';
export const GET_GROUP_THREADS_WITH_CURSOR = 'GET_GROUP_THREADS_WITH_CURSOR';

/* 保存一覧取得 */
export const REQUEST_GET_LIKED_THREADS = 'REQUEST_GET_LIKED_THREADS';
export const GET_LIKED_THREADS = 'GET_LIKED_THREADS';
export const REQUEST_GET_LIKED_THREADS_WITH_CURSOR =
  'REQUEST_GET_LIKED_THREADS_WITH_CURSOR';
export const GET_LIKED_THREADS_WITH_CURSOR = 'GET_LIKED_THREADS_WITH_CURSOR';

/* 未回答一覧取得 */
export const REQUEST_GET_UNSOLVED_THREADS = 'REQUEST_GET_UNSOLVED_THREADS';
export const GET_UNSOLVED_THREADS = 'GET_UNSOLVED_THREADS';
export const REQUEST_GET_UNSOLVED_THREADS_WITH_CURSOR =
  'REQUEST_GET_UNSOLVED_THREADS_WITH_CURSOR';
export const GET_UNSOLVED_THREADS_WITH_CURSOR =
  'GET_UNSOLVED_THREADS_WITH_CURSOR';

/* 共有した症例一覧取得 */
export const REQUEST_GET_KNOWLEDGE_THREADS = 'REQUEST_GET_KNOWLEDGE_THREADS';
export const GET_KNOWLEDGE_THREADS = 'GET_KNOWLEDGE_THREADS';
export const REQUEST_GET_KNOWLEDGE_THREADS_WITH_CURSOR =
  'REQUEST_GET_KNOWLEDGE_THREADS_WITH_CURSOR';
export const GET_KNOWLEDGE_THREADS_WITH_CURSOR =
  'GET_KNOWLEDGE_THREADS_WITH_CURSOR';

/* 診療科目絞り込み一覧取得 */
export const REQUEST_GET_FILTERED_THREADS = 'REQUEST_GET_FILTERED_THREADS';
export const REQUEST_GET_FILTERED_THREADS_WITH_CURSOR =
  'REQUEST_GET_FILTERED_THREADS_WITH_CURSOR';
export const GET_FILTERED_THREADS = 'GET_FILTERED_THREADS';
export const GET_FILTERED_THREADS_WITH_CURSOR =
  'GET_FILTERED_THREADS_WITH_CURSOR';

/* 検索絞り込み一覧取得 */
export const REQUEST_GET_SEARCHED_THREADS = 'REQUEST_GET_SEARCHED_THREADS';
export const GET_SEARCHED_THREADS = 'GET_SEARCHED_THREADS';
export const REQUEST_GET_SEARCHED_THREADS_WITH_CURSOR =
  'REQUEST_GET_SEARCHED_THREADS_WITH_CURSOR';
export const GET_SEARCHED_THREADS_WITH_CURSOR =
  'GET_SEARCHED_THREADS_WITH_CURSOR';

/* 特定ユーザーの聞いた質問一覧取得 */
export const REQUEST_GET_ASKED_THREADS = 'REQUEST_GET_ASKED_THREADS';
export const GET_ASKED_THREADS = 'GET_ASKED_THREADS';
export const REQUEST_GET_ASKED_THREADS_WITH_CURSOR =
  'REQUEST_GET_ASKED_THREADS_WITH_CURSOR';
export const GET_ASKED_THREADS_WITH_CURSOR = 'GET_ASKED_THREADS_WITH_CURSOR';

/* 特定ユーザーの答えた質問一覧取得 */
export const REQUEST_GET_ANSWERED_THREADS = 'REQUEST_GET_ANSWERED_THREADS';
export const GET_ANSWERED_THREADS = 'GET_ANSWERED_THREADS';
export const REQUEST_GET_ANSWERED_THREADS_WITH_CURSOR =
  'REQUEST_GET_ANSWERED_THREADS_WITH_CURSOR';
export const GET_ANSWERED_THREADS_WITH_CURSOR =
  'GET_ANSWERED_THREADS_WITH_CURSOR';

/* 特定ユーザーの共有した症例一覧取得 */
export const REQUEST_GET_SHARED_THREADS = 'REQUEST_GET_SHARED_THREADS';
export const GET_SHARED_THREADS = 'GET_SHARED_THREADS';
export const REQUEST_GET_SHARED_THREADS_WITH_CURSOR =
  'REQUEST_GET_SHARED_THREADS_WITH_CURSOR';
export const GET_SHARED_THREADS_WITH_CURSOR = 'GET_SHARED_THREADS_WITH_CURSOR';

/* 質問の保存を設定する(旧:興味あり) */
export const FAST_LIKE_THREAD = 'FAST_LIKE_THREAD';
export const LIKE_THREAD = 'LIKE_THREAD';

/* 質問の保存を解除する(旧:興味あり) */
export const FAST_UNLIKE_THREAD = 'FAST_UNLIKE_THREAD';
export const UNLIKE_THREAD = 'UNLIKE_THREAD';

/* 回答のいいねを設定する */
export const FAST_LIKE_MESSAGE = 'FAST_LIKE_MESSAGE';

/* 回答のいいねを解除する */
export const FAST_UNLIKE_MESSAGE = 'FAST_UNLIKE_MESSAGE';

/* 全ての一覧をリセット */
export const RESET_ALL_THREADS = 'RESET_ALL_THREADS';

/* 質問一覧に表示する回答数 */
export const ANSWER_COUNT_OF_QUESTION_LIST_MAX = 1;

/* 質問一覧更新フラグのリセット */
export const RESET_SHOULD_THREADS_UPDATE = 'RESET_SHOULD_THREADS_UPDATE';

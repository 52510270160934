import React, { Component } from 'react';
import styles from './profileHospitalUpdateModal.module.css';
import Modal from '../Modal';
import PrimaryButton from '../../Form/PrimaryButton';
import Fieldset from '../../Form/Fieldset';
import { HOSPITAL_MAX_FETCH_NUM } from '../../../constants/hospitals';
import { sendAnalyticsEvent } from '../../../utils/analytics';

class ProfileHospitalUpdateModal extends Component {
  state = {
    shouldDisplayHospitalNameList: false,
    hospitalName: '',
    hospitalId: null,
    popupWasShown: false,
  };

  get isDisabled() {
    const { isHandlingUserAction } = this.props;
    const { hospitalId } = this.state;

    return !hospitalId || isHandlingUserAction;
  }

  componentDidMount() {
    this.props.getHospitals(this.state.hospitalName);
  }

  componentDidUpdate(prevProps, prevState) {
    const { user } = this.props;
    const { hospitalName, hospitalId } = this.state;

    if (user && prevProps.user !== user) {
      if (user.hospitalId) {
        this.setState({ hospitalName: user.hospitalName });
        this.setState({ hospitalId: user.hospitalId });
      } else {
        this.setState({ hospitalName: '' });
        this.setState({ hospitalId: null });
      }
    }

    if (prevState.hospitalName !== hospitalName && hospitalId === null) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.props.getHospitals(hospitalName);
      }, 300);
    }
  }

  onChangeHospital = (value) => {
    this.setState({
      hospitalName: value,
      hospitalId: null,
    });
  };

  onClickHospitalNameListItem = (hospitalId, hospitalName) => {
    this.setState({
      hospitalName: hospitalName,
      hospitalId: hospitalId,
      shouldDisplayHospitalNameList: false,
    });
  };

  onClickHospital = () => {
    if (!this.state.shouldDisplayHospitalNameList) {
      this.setState({
        shouldDisplayHospitalNameList: true,
      });
    }
  };

  fetchMoreHospitalList = async (e) => {
    this.props.getHospitals(this.state.hospitalName, HOSPITAL_MAX_FETCH_NUM);
    this.setState({
      shouldDisplayHospitalNameList: true,
    });
  };

  onProceedSubmit = async () => {
    const { onModalFormSubmit, discloseId, getUser } = this.props;
    const { hospitalId, hospitalName } = this.state;

    // send analytics event before firing the api
    sendAnalyticsEvent('profile_hospital_update_induction', 'click', 'update');
    onModalFormSubmit(hospitalId, hospitalName, () => {
      // Once Form Submitted, get latest user data
      getUser(discloseId);
    });
  };

  onProceedCancel = () => {
    sendAnalyticsEvent('profile_hospital_update_induction', 'click', 'close');

    const { handleModalClose } = this.props;
    handleModalClose();
  };

  render() {
    const { profileHospitalUpdateModal, isFetchingNecessity } = this.props;
    const { hospitals, totalHospitalNum } = this.props.hospitals;
    const {
      shouldDisplayHospitalNameList,
      hospitalName,
      popupWasShown,
    } = this.state;

    if (!profileHospitalUpdateModal.show || isFetchingNecessity) {
      return null;
    } else if (!popupWasShown) {
      this.setState({ popupWasShown: true });
      sendAnalyticsEvent('profile_hospital_update_induction', 'show', '');
    }

    return (
      <Modal close={this.onProceedCancel} confirm={true}>
        <div className={styles.modal}>
          <div className={styles.modalHeading}>
            <div className={styles.closeBtn}>
              <img
                src={'/images/btn_close.svg'}
                onClick={this.onProceedCancel}
                alt="閉じる"
              />
            </div>
            <h1 className={styles.heading}>所属医療機関登録のお願い</h1>
          </div>
          <div className={styles.modalBody}>
            <div className={styles.content}>
              <p>
                当アプリは高度な医療情報を提供することを目的としたアプリであり、薬機法及びアプリストア規約の観点から、所属医療機関の登録をお願いしております。
              </p>
              <Fieldset legend="所属医療機関" direction="column">
                <input
                  type="text"
                  className={styles.inputText}
                  list="hospitals"
                  value={hospitalName}
                  placeholder="例）アンター病院"
                  onInput={(e) => this.onChangeHospital(e.target.value)}
                  onClick={() => this.onClickHospital()}
                />
                {shouldDisplayHospitalNameList && hospitals.length > 0 ? (
                  <ul className={styles.hospitalList}>
                    {hospitals.map((item, index) => (
                      <li
                        key={index}
                        value={item.hospitalId}
                        className={styles.button}
                        onClick={() =>
                          this.onClickHospitalNameListItem(
                            item.hospitalId,
                            item.hospitalName,
                          )
                        }
                      >
                        {item.hospitalName}
                      </li>
                    ))}
                    {hospitals.length < totalHospitalNum &&
                      hospitals.length < HOSPITAL_MAX_FETCH_NUM && (
                        <li
                          onClick={(e) => this.fetchMoreHospitalList(e)}
                          className={styles.moreButton}
                        >
                          もっとみる
                        </li>
                      )}
                  </ul>
                ) : (
                  <div className={styles.link}>
                    <a
                      href="https://note.com/antaa/n/n3ae2566cdbb4"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ※所属医療機関が見つからない場合はこちら
                    </a>
                  </div>
                )}
              </Fieldset>
            </div>
            <div>
              <div className={styles.actions}>
                <PrimaryButton
                  style={{
                    minWidth: '200px',
                  }}
                  value="登録する"
                  disabled={this.isDisabled}
                  theme={this.isDisabled ? 'disabled' : 'submit'}
                  onClick={this.onProceedSubmit}
                />
              </div>
              <div className={styles.link}>
                <a
                  href="https://corp.antaa.jp/privacypolicy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  プライバシーポリシー
                </a>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ProfileHospitalUpdateModal;

import * as c from '../constants/ui';
import { RESET_ALL_STORE } from '../constants/user';

const initialState = {
  postModal: false, // 質問モーダルの開閉
  detailModal: {
    questionId: null, // 質問詳細モーダルで表示する質問ID
    highlightAnswerId: undefined, // 質問詳細モーダル内で強調表示する回答のID
    highlightReplyId: undefined, // 質問詳細モーダル内で強調表示する返信のID
  },
  editPostModal: {
    questionId: null, // 質問編集モーダルで編集対象となる質問ID
  },
  departmentModal: false, // 診療科目選択モーダルの開閉
  editDepartmentModal: false, // 診療科目の編集モーダルの開閉
  messageForm: false, // メッセージフォームの開閉
  replyForm: null, // リプライフォームの開閉
  notifyModal: false, // お知らせモーダルの開閉
  managerNotifyModal: false, // 運営からのお知らせモーダルの開閉
  menuModal: false, // レスポンシブ対応時の診療科選択モーダルの開閉
  manageGroupMenuModal: false, // レスポンシブ対応時のグループ管理モーダルの開閉(値:groupId/型:string)
  shouldShowDraftList: false, // 下書き一覧の開閉
  confirmModal: {
    // 確認モーダルオブジェクト
    open: false,
    message: null,
    type: 'confirm',
    onOK: undefined,
    onCancel: undefined,
    okButtonTitle: 'OK',
    cancelButtonTitle: 'キャンセル',
  },
};

export default function ui(state = initialState, action) {
  switch (action.type) {
    // 質問モーダルを開く
    case c.OPEN_POST_MODAL:
      return {
        ...state,
        postModal: true,
      };

    // 質問モーダルを閉じる
    case c.CLOSE_POST_MODAL:
      return {
        ...state,
        postModal: false,
      };

    // 質問詳細モーダルを開く
    case c.OPEN_DETAIL_MODAL:
      return {
        ...state,
        detailModal: {
          questionId: action.payload.questionId,
          highlightAnswerId: action.payload.highlightAnswerId,
          highlightReplyId: action.payload.highlightReplyId,
        },
      };

    // 質問詳細モーダルを閉じる
    case c.CLOSE_DETAIL_MODAL:
      return {
        ...state,
        detailModal: {
          questionId: null,
          highlightAnswerId: undefined,
          highlightReplyId: undefined,
        },
      };

    // 質問編集モーダルを開く
    case c.OPEN_EDIT_POST_MODAL:
      return {
        ...state,
        editPostModal: {
          questionId: action.payload.threadId,
        },
      };

    // 質問編集モーダルを閉じる
    case c.CLOSE_EDIT_POST_MODAL:
      return {
        ...state,
        editPostModal: {
          questionId: null,
        },
      };

    // 診療科目選択モーダルを開く
    case c.OPEN_DEPARTMENT_MODAL:
      return {
        ...state,
        departmentModal: true,
      };

    // 診療科目選択モーダルを閉じる
    case c.CLOSE_DEPARTMENT_MODAL:
      return {
        ...state,
        departmentModal: false,
      };

    // 診療科目の編集モーダルを開く
    case c.OPEN_EDIT_DEPARTMENT_MODAL:
      return {
        ...state,
        editDepartmentModal: true,
      };

    // 診療科目の編集モーダルを閉じる
    case c.CLOSE_EDIT_DEPARTMENT_MODAL:
      return {
        ...state,
        editDepartmentModal: false,
      };

    // メッセージフォームを開く
    case c.OPEN_MESSAGE_FORM:
      return {
        ...state,
        messageForm: true,
      };

    // メッセージフォームを閉じる
    case c.CLOSE_MESSAGE_FORM:
      return {
        ...state,
        messageForm: false,
      };

    // リプライフォームを開く
    case c.OPEN_REPLY_FORM:
      return {
        ...state,
        replyForm: action.payload.messageId,
      };

    // リプライフォームを閉じる
    case c.CLOSE_REPLY_FORM:
      return {
        ...state,
        replyForm: null,
      };

    // お知らせモーダルを開く
    case c.OPEN_NOTIFY_MODAL:
      return {
        ...state,
        notifyModal: true,
      };

    // お知らせモーダルを閉じる
    case c.CLOSE_NOTIFY_MODAL:
      return {
        ...state,
        notifyModal: false,
      };

    // 運営からのお知らせモーダルを開く
    case c.OPEN_MANAGER_NOTIFY_MODAL:
      return {
        ...state,
        managerNotifyModal: true,
      };

    // 運営からのお知らせモーダルを閉じる
    case c.CLOSE_MANAGER_NOTIFY_MODAL:
      return {
        ...state,
        managerNotifyModal: false,
      };

    // レスポンシブ対応時の診療科選択モーダルを開く
    case c.OPEN_MENU_MODAL:
      return {
        ...state,
        menuModal: true,
      };

    // レスポンシブ対応時の診療科選択モーダルを閉じる
    case c.CLOSE_MENU_MODAL:
      return {
        ...state,
        menuModal: false,
      };

    // レスポンシブ対応時のグループ管理モーダルを開く
    case c.OPEN_MANAGE_GROUP_MENU_MODAL:
      return {
        ...state,
        manageGroupMenuModal: action.payload.groupId,
      };

    // レスポンシブ対応時のグループ管理モーダルを閉じる
    case c.CLOSE_MANAGE_GROUP_MENU_MODAL:
      return {
        ...state,
        manageGroupMenuModal: false,
      };

    // 確認モーダルを開く
    case c.OPEN_CONFIRM_MODAL:
      return {
        ...state,
        confirmModal: {
          open: true,
          message: action.payload.message,
          type: action.payload.type,
          onOK: action.payload.onOK,
          onCancel: action.payload.onCancel,
          okButtonTitle: action.payload.okButtonTitle,
          cancelButtonTitle: action.payload.cancelButtonTitle,
          styles: action.payload.styles,
        },
      };

    // 確認モーダルを閉じる
    case c.CLOSE_CONFIRM_MODAL:
      return {
        ...state,
        confirmModal: initialState.confirmModal,
      };

    // 下書き一覧を開く
    case c.SHOW_DRAFT_LIST:
      return {
        ...state,
        shouldShowDraftList: true,
      };

    // 下書き一覧を閉じる
    case c.CLOSE_DRAFT_LIST:
      return {
        ...state,
        shouldShowDraftList: false,
      };

    // プラポリ同意モーダルを開く
    case c.OPEN_POLICY_MODAL:
      return {
        ...state,
        privacyPolicyModal: true,
      };

    // プラポリ同意モーダルを閉じる
    case c.CLOSE_POLICY_MODAL:
      return {
        ...state,
        privacyPolicyModal: false,
      };

    // 利用規約同意モーダルを開く
    case c.OPEN_TERMS_MODAL:
      return {
        ...state,
        termsOfServiceModal: true,
      };

    // 利用規約同意モーダルを閉じる
    case c.CLOSE_TERMS_MODAL:
      return {
        ...state,
        termsOfServiceModal: false,
      };

    // すべてリセットする
    case RESET_ALL_STORE:
      return initialState;

    default:
      return state;
  }
}

import { baseURL, isSuccess, option, fetchData } from '../utils/fetch';
import {
  successGetHospitals,
  failureGetHospitals,
  clearHospitals,
} from '../actions/hospitals';

/**
 * 所属医療機関一覧の取得
 *
 * 引数に指定した keyword の所属医療機関情報を取得する。
 * 取得した後は、所属医療機関データ取得成功のアクションを送信する。
 * @param {string} query - クエリ(text:エンコード前の検索文字列)
 * @param {string} limit - 最大取得件数
 * @param {string} offset - 開始件数
 */
export const getHospitals = (query = '', limit = 10, offset = null) => async (
  dispatch,
) => {
  const op = await option.json();

  const uri = baseURL() + `hospitals?keyword=${query}&limit=${limit}`;
  return fetchData(uri, op)
    .then((response) => isSuccess(response))
    .then((json) => {
      return {
        hospitals: json.hospitals,
        totalHospitalNum: json.totalHospitalNum,
      };
    })
    .then((items) => dispatch(successGetHospitals(items)))
    .catch((error) => dispatch(failureGetHospitals(error.message)));
};

/**
 * 所属医療機関一覧を削除する
 */
export const deleteHospitals = () => async (dispatch) => {
  dispatch(clearHospitals());
  return;
};

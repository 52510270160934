import * as c from '../constants/register';

/**
 * 登録ステータスをStoreに保存する
 *
 * @param {number} status - 登録ステータス
 */
export function setStatus(status) {
  return {
    type: c.SET_STATUS,
    payload: {
      status,
    },
  };
}

/**
 * 登録フォームの入力項目をStoreに保存する
 *
 * @param {string} key - 保存したいプロパティ名
 * @param {string} value - 保存したい値
 * @return {object} Actionオブジェクト
 */
export function inputRegisterForm(key, value) {
  return {
    type: c.INPUT_REGISTER_FORM,
    payload: {
      key,
      value,
    },
  };
}

/**
 * 登録要求の登録リクエストをReducerに通知する
 *
 * @return {object} Actionオブジェクト
 */
export function requestPostRegisterRequestInfo() {
  return {
    type: c.REQUEST_POST_REGISTER_REQUEST_INFO,
  };
}

/**
 * 登録要求情報登録のバリデーションエラーをreducerに通知する
 * @param {array} errors - エラーコード配列
 */
export function validateErrorPostRegisterRequestInfo(errors) {
  return {
    type: c.VALIDATE_POST_REGISTER_REQUEST_INFO,
    payload: {
      errors,
    },
  };
}

/**
 * 登録要求情報の登録成功をReducerに通知する
 */
export function successPostRegisterRequest() {
  return {
    type: c.POST_REGISTER_REQUEST_INFO,
  };
}

/**
 * 登録要求情報の登録失敗をReducerに通知する
 * @param {string} message - エラーメッセージ文字列
 */
export function failurePostRegisterRequestInfo(message) {
  return {
    type: c.POST_REGISTER_REQUEST_INFO,
    payload: {
      message,
    },
    error: true,
  };
}

/**
 * 1枚目のライセンス画像の登録リクエストをReducerに通知する
 *
 * @return {object} Actionオブジェクト
 */
export function requestUploadFirstLicensePhoto() {
  return {
    type: c.REQUEST_UPLOAD_FIRST_LICENSE_PHOTO,
  };
}

/**
 * 1枚目のライセンス画像の登録成功をReducerに通知する
 */
export function successUploadFirstLicensePhoto() {
  return {
    type: c.UPLOAD_FIRST_LICENSE_PHOTO,
  };
}

/**
 * 1枚目のライセンス画像の登録失敗をReducerに通知する
 * @param {string} message - エラーメッセージ文字列
 */
export function failureUploadFirstLicensePhoto(message) {
  return {
    type: c.UPLOAD_FIRST_LICENSE_PHOTO,
    payload: {
      message,
    },
    error: true,
  };
}

/**
 * 2枚目のライセンス画像の登録リクエストをReducerに通知する
 *
 * @return {object} Actionオブジェクト
 */
export function requestUploadSecondLicensePhoto() {
  return {
    type: c.REQUEST_UPLOAD_SECOND_LICENSE_PHOTO,
  };
}

/**
 * 2枚目のライセンス画像の登録成功をReducerに通知する
 */
export function successUploadSecondLicensePhoto() {
  return {
    type: c.UPLOAD_SECOND_LICENSE_PHOTO,
  };
}

/**
 * 2枚目のライセンス画像の登録失敗をReducerに通知する
 * @param {string} message - エラーメッセージ文字列
 */
export function failureUploadSecondLicensePhoto(message) {
  return {
    type: c.UPLOAD_SECOND_LICENSE_PHOTO,
    payload: {
      message,
    },
    error: true,
  };
}

/**
 * 登録要求内容の取得に成功
 *
 * @param {object} requestInfo - 登録要求内容オブジェクト
 * @return {object} Actionオブジェクト
 */
export function successGetRegisterRequest(requestInfo) {
  return {
    type: c.GET_REGISTER_REQUEST_INFO,
    payload: {
      requestInfo,
    },
  };
}

/**
 * 登録要求内容の取得に失敗
 *
 * @param {string} message - エラーメッセージ文字列
 * @return {object} Actionオブジェクト
 */
export function failureGetRegisterRequest(message) {
  return {
    type: c.GET_REGISTER_REQUEST_INFO,
    payload: {
      message,
    },
    error: true,
  };
}

/**
 * 登録要求の編集項目をStoreに保存する
 *
 * @param {string} key - 保存したいプロパティ名
 * @param {string} value - 保存したい値
 * @return {object} Actionオブジェクト
 */
export function inputEditRegisterInfo(key, value) {
  return {
    type: c.INPUT_EDIT_REGISTER_REQUEST_INFO,
    payload: {
      key,
      value,
    },
  };
}

/**
 * 登録要求の更新リクエストをReducerに通知する
 * @return {object} Actionオブジェクト
 */
export function requestEditRegisterRequestInfo() {
  return {
    type: c.REQUEST_EDIT_REGISTER_REQUEST_INFO,
  };
}

/**
 * 登録要求更新のバリデーションエラーをreducerに通知する
 * @param {array} errors - エラーコード配列
 */
export function validateErrorEditRegisterRequestInfo(errors) {
  return {
    type: c.VALIDATE_EDIT_REGISTER_REQUEST_INFO,
    payload: {
      errors,
    },
  };
}

/**
 * 登録要求の更新成功をReducerに通知する
 * @returns {{type: *}}
 */
export function successEditRegisterRequestInfo() {
  return {
    type: c.EDIT_REGISTER_REQUEST_INFO,
  };
}

/**
 * 登録要求の更新失敗をReducerに通知する
 * @param {string} message - エラーメッセージ文字列
 */
export function failureEditRegisterRequestInfo(message) {
  return {
    type: c.EDIT_REGISTER_REQUEST_INFO,
    payload: {
      message,
    },
    error: true,
  };
}

import * as c from '../constants/pr';

const initialState = {
  allAnnounces: null,
  managerAnnounces: null,
  isUnread: null,
  isUnreadManager: null,
  error: null,
};

export default function pr(state = initialState, action) {
  switch (action.type) {
    // お知らせを取得する
    case c.GET_ALL_ANNOUNCES:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
        };
      }
      return {
        ...state,
        allAnnounces: {
          m_announces: action.payload.managerAnnounces,
          u_announces: action.payload.userAnnounces,
        },
        error: null,
      };
    // 運営からのお知らせを取得する
    case c.GET_MANAGER_ANNOUNCES:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
        };
      }
      return {
        ...state,
        managerAnnounces: action.payload.managerAnnounces,
        error: null,
      };
    // 未読ステータスを取得する
    case c.GET_UNREAD:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
        };
      }
      return {
        ...state,
        isUnread: action.payload.isUnread,
        isUnreadManager: action.payload.isUnreadManager,
      };
    default:
      return state;
  }
}

import { connect } from 'react-redux';
import Footer from './Footer';

function mapStateToProps({ register }) {
  return {
    status: register.status,
  };
}

export default connect(mapStateToProps, null)(Footer);

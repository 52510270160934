import * as c from '../constants/streamContentsList';

const initialState = {
  featuredContents: null, // featuredコンテンツの一覧オブジェクト
  todayContents: null, // 本日配信コンテンツの一覧オブジェクト
  afterTomorrowContents: null, // 今後の配信コンテンツの一覧オブジェクト
  allContents: null, // 全ストリーミングコンテンツの一覧オブジェクト
  requestGetAllContents: false, // 全ストリーミングコンテンツ取得中フラグ
  error: null, // API用
};

export default function streamContentsList(state = initialState, action) {
  let allContents;

  switch (action.type) {
    // コンテンツ情報を取得する
    case c.GET_FEATURED_CONTENTS:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
        };
      }
      return {
        ...state,
        featuredContents: action.payload.featuredContents[0],
        error: null,
      };

    case c.GET_TODAY_CONTENTS:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
        };
      }
      return {
        ...state,
        todayContents: action.payload.todayContents[0],
        error: null,
      };

    case c.GET_AFTER_TOMORROW_CONTENTS:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
        };
      }
      return {
        ...state,
        afterTomorrowContents: action.payload.afterTomorrowContents[0],
        error: null,
      };

    case c.GET_ALL_CONTENTS:
      if (action.error) {
        return {
          ...state,
          requestGetAllContents: false,
          error: action.payload.message,
        };
      }

      if (action.payload.offset > 0 && state.allContents) {
        allContents = { ...state.allContents };
        allContents.contents = state.allContents.contents.concat(
          action.payload.allContents[0].contents,
        );
      } else {
        allContents = { ...action.payload.allContents[0] };
      }

      return {
        ...state,
        allContents,
        requestGetAllContents: false,
        error: null,
      };

    case c.REQUEST_GET_ALL_CONTENTS:
      return {
        ...state,
        requestGetAllContents: true,
      };

    default:
      return state;
  }
}
